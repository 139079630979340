import styled from "styled-components";

interface FarmGaleriesWrapperProps {
    galeryCount: number;
}

export const FarmGaleriesWrapper = styled.div<FarmGaleriesWrapperProps>`
  padding-top: 23px;
  padding-bottom: 20px;
  
  .image-gallery-wrapper-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.backgroundOverlay};
    z-index: 1000;
    

    @media (min-width:1201px) {
      display: flex;
    }
  }

  .slick-list {
    max-height:500px;

    @media (min-height:870px) {
      max-height:665px;
    }
  }

  .gallery-img-container{
    display: block !important;
    width: 100% !important;
  }

  .gallery-img-container img{
    display: block;
    width: 100%;
  }

   .image-gallery-wrapper {
    position: relative;
    width: 100%;
    max-width: 1000px;
    border-radius: 8px;
    padding: 20px;

    .close-image-btn {
      position: absolute;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.lightGrey};
      background-color: ${(props) => props.theme.white};
      top: 20px;
      right: -40px;
    }
  }


  .mobile-farm-galeries {
    display: none;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat( ${({ galeryCount }) => Math.ceil(galeryCount / 3)},  25px);
    gap: 10px;

    @media (max-width:1200px) {
      display: grid;
    }

    @media (max-width:800px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat( ${({ galeryCount }) => Math.ceil(galeryCount * 8 / 2)},  25px);
    }

    .grid-item {
        position: relative;
    }

    .grid-item:nth-child(odd) {
        grid-row: span 5;

        @media (max-width:800px) {
            grid-row: span 6;
        }
    }

    .grid-item:nth-child(even) {
        grid-row: span 6;

        @media (max-width:800px) {
            grid-row: span 7;
        }
    }

    .grid-item:nth-child(3n + 3) {
        grid-row: span 8;

        @media (max-width:800px) {
            grid-row: span 10;
        }
    }

    .grid-item:nth-child(2n + 4) {
        grid-row: span 10;

        @media (max-width:800px) {
            grid-row: span 9;
        }
    }

    .grid-item .carusel-banner-image,
    .grid-item .ant-image  {
        display: block;
        width: 100% !important; 
        height: 100% !important;
        object-fit: cover !important;
    }
  }

`