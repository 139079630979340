import { StyledCarousel } from '../style'
import { FallbackImage } from '../../UploadImage/fallBack/FallBackImage'
import React from 'react'
import { BannerProps, ImageProps } from '../../../types/BannerData'

interface BannersGalleryProps {
  bannerImages?: ImageProps[]
  banner?: BannerProps
}

export const BannersGallery = ({
  bannerImages,
  banner,
}: BannersGalleryProps) => {
  const showDots = bannerImages && bannerImages?.length > 1

  return (
    <StyledCarousel dots={showDots}>
      {bannerImages && bannerImages.length > 0 ? (
        bannerImages.map((image, index) => (
          <div className={'banner-image-wrapper'} key={`image-${index}`}>
            <a
              href={
                banner?.images && banner?.images?.length > 1
                  ? image.url_to
                  : banner?.url
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <FallbackImage
                src={image.url_original}
                preview={false}
                type={banner?.type}
              />
            </a>
          </div>
        ))
      ) : (
        <FallbackImage src={''} preview={false} type={banner?.type} />
      )}
    </StyledCarousel>
  )
}
