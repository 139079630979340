import React from 'react'
import { Form, DatePicker as AntdDatePicker } from 'antd'
import { Dayjs } from 'dayjs'

export interface DatePickerProps {
  size?: 'large' | 'middle' | 'small' | undefined
  placeholder?: string
  onChange?: (date: Dayjs, dateString: string | string[]) => void
  disabled?: boolean
  value?: Dayjs
  picker?: any
  format?: string
  name?: string
  label?: string
  disabledDate?: (date: Dayjs) => boolean
  initialValue?: Dayjs
  className?: string
}

export const DatePicker = ({
  className,
  placeholder = '',
  onChange,
  disabled,
  value,
  size = 'large',
  picker,
  format = 'DD.MM.YYYY',
  name,
  label,
  disabledDate,
  initialValue,
}: DatePickerProps) => {
  return (
    <Form.Item name={name} label={label} initialValue={initialValue}>
      <AntdDatePicker
        className={className}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        value={value}
        size={size}
        format={format || 'DD.MM.YYYY'}
        picker={picker}
        disabledDate={disabledDate}
      />
    </Form.Item>
  )
}
