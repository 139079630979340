import styled from 'styled-components'
import { Form } from 'antd'

export const StyledProductsTopFilter = styled(Form)`
  font-family: ${(props) => props.theme.fontText};
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  align-items: center;

  .icon {
    height: 24px;
  }

  .icon .ant-select-selection-item{
    font-size:14px;
    font-weight: 400;
    line-height: 24px;
  }

  .icon .ant-form-item {
    padding-bottom:0;
  }

  .br-r {
    border-right: 1px solid ${(props) => props.theme.borderBottom};
    padding-right: 10px;
    padding-left: 10px;
  }

  .sz-sm {
    font-size: 14px;
    padding-left: 5px;
  }
  .d-f-r {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .show-in-map {
    padding-left: 10px;
    text-decoration: underline;
  }
`
