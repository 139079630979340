import styled from 'styled-components'

export const StyledCategoryTab = styled('div')`
  max-width: 600px;
  padding: 5px 10px 5px 40px;
  font-family: ${(props) => props.theme.fontText};

  .sub-cat {
    margin-left: 50px;
  }

  .category-wrap {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.categoryBorder};
    border-radius: 4px;

    .action,
    .handle {
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .action {
      font-size: 13px;
      background-color: ${(props) => props.theme.categoryActionBackground};
      border: 2px solid ${(props) => props.theme.categoryActionBackground};
    }
    .handle {
      background-color: ${(props) => props.theme.categoryHandlerBackground};
      font-size: 10px;
      border: 2px solid ${(props) => props.theme.categoryHandlerBackground};
    }

    .inside-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      align-items: center;
      background-color: ${(props) => props.theme.categoryContentBackground};

      .open-name {
        display: flex;
        font-weight: bold;
        font-size: 13px;

        .open-close {
          margin-left: 10px;
          cursor: pointer;
        }

        .name {
          margin-left: 10px;
        }
      }

      .vat {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 100%;
        background-color: ${(props) => props.theme.categoryVatBackground};
        color: ${(props) => props.theme.white};
        font-size: 14px;
      }
    }
    .open-name {
      display: flex;
    }
  }
`
