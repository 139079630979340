import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle<any>`
    thead .ant-table-cell {
        border-bottom: 3px solid #0000001a !important;
    }

    tbody {
        tr:nth-child(2n) td {
            background-color: ${({ theme }) => theme.tableSecondRowColor};
        }
    }

    .ant-btn-link {
        text-decoration: underline;
    }


    //steps {
    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: ${({ theme }) => theme.colorPrimary};
    !important;
        border-color: ${({ theme }) => theme.colorPrimary};
    !important;

        svg {
            color: ${({ theme }) => theme.white};
        !important;
        }
    }

  .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent; !important;
    border-color: ${({ theme }) => theme.black}; !important;
  }
  
  .ant-table-content {
      overflow: auto;
  }
    .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
        background-color: transparent;
    !important;
        border-color: ${({ theme }) => theme.black};
    !important;
    }
    
    .ant-drawer-footer {
        background-color: ${({ theme }) => theme.filterBackground};
    }

    #cart-drawer{
        .ant-drawer-body{
            padding-top: 0;
        }
    }

    #delivery-drawer{
        .ant-drawer-body{
            padding: 0;
        }
        .ant-drawer-header{
            .ant-drawer-header-title{
                flex-direction: row-reverse;
            }
        }
    }
`
