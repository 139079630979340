import React from 'react'
import { ProductData } from 'types/ProductData'

interface PayloadType {
  refetch: Function
  products?: ProductData[]
  productsDiscounted?: ProductData[]
  product?: ProductData
  productsCounted?: number
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH'
  payload: PayloadType
  isLoading: boolean
}

type RefetchAction = {
  type: 'REFETCH'
}

type Action = PayloadAction | RefetchAction

type Dispatch = (action: Action) => void

type State = {
  products?: ProductData[]
  refetch: Function
  productsDiscounted?: ProductData[]
  product?: ProductData
  productsCounted?: number
}

type ProductProviderProps = { children: React.ReactNode }

const ProductStateContext = React.createContext<State>(undefined!)
const ProductDispatchContext = React.createContext<Dispatch>(undefined!)

function productReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload

      return {
        ...state,
        ...payload,
      }
    }
    case 'REFETCH': {
      state.refetch()
      return {
        ...state,
      }
    }
  }
}

function ProductProvider({ children }: ProductProviderProps) {
  const initialState = {
    products: [],
    refetch: () => null,
    productsDiscounted: [],
  }

  const [state, dispatch] = React.useReducer(productReducer, initialState)

  return (
    <ProductStateContext.Provider value={state}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductStateContext.Provider>
  )
}

const useProductState = () => React.useContext(ProductStateContext)
const useProductDispatch = () => React.useContext(ProductDispatchContext)

export { ProductProvider, useProductDispatch, useProductState }
