declare global {
  interface Window {
    runConfig: {
      frontendUrl: string
      backendUrl: string
      nodeEnv: string
    }
  }
}

export const routes = {
  api: {
    frontendUrl: window?.runConfig?.frontendUrl,
    baseUrl: window?.runConfig?.backendUrl,
  },
}

export const options = {
  filters: {
    pageSizeOptions: [20, 40],
  },
  images: {
    defaultProductImage: '/defaults/Rectangle_149.png',
    defaultImageNotFound: '/defaults/image-not-found.jpeg',
    defaultFarmImage: '/defaults/farm_default.png',
    defaultCategoryImage: '/defaults/Rectangle_142.png',
    defaultBannerOneImage: '/banners/banner_1.png',
    defaultBannerTwoImage: '/banners/banner_2.png',
    defaultBannerThreeImage: '/banners/banner_3.png',
    defaultBannerFourImage: '/banners/banner_4.png',
    maxImages: 3,
    maxImageSize: 1440,
    maxSizeInMB: 22,
    allowedFormats: ['image/png', 'image/jpeg', 'image/bmp'],
  },
  market: {
    VAT_RATE_21: 0.21,
    VAT_RATE_05: 0.05,
  },
}
