import styled from 'styled-components'

export const StyledSelectDelivery = styled.div``

export const StyledSelfDelivery = styled.div`
  .delivery {
    font-family: ${(props) => props.theme.fontText};
    .content {
      p {
        font-weight: 500;
        margin: 0;
        padding: 0 0 15px 0;
      }
      .open-hours-table {
        border-spacing: 0;
        border: 1px solid ${(props) => props.theme.lightGrey};
        width: 100%;

        td {
          padding: 0;
        }

        td.td-day {
          width: 100px;
        }

        tr {
          height: 44px;
        }

        tr.light-gray-background {
          background-color: ${(props) => props.theme.lightGrey};
        }
        tr.white-background {
          background-color: ${(props) => props.theme.white};
        }
      }
    }
  }
`

export const StyledDelivery = styled.div`
  .ant-space {
    width: 100%;

    .ant-space-gap-row-small {
      row-gap: 20px;
    }

    .ant-space-item {
      .ant-form-item-label {
        padding-bottom: 4px;
      }

      .ant-select {
      }

      .ant-select .ant-select-single {
        height: 45px;
      }

      .ant-select .ant-select-selector {
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.borderBottom};
        height: 45px;
      }
      .ant-select-arrow {
        display: none;
      }
    }
  }
`
