import MapComponent from '../../../ui/Map/Map'
import { useFarmState } from '../../context/FarmContext'
import { Button, Icon, Spinner } from '../../../ui'
import { StyledMap } from './style'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FilterOptions } from '../../../types/FilterOptions'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { Form } from '../../../ui/Form/Form'
import { FarmData } from '../../../types/FarmData'
import { FarmCard } from '../../../ui/Card/FarmCard'
import { useIntl } from 'react-intl'
import { Pagination } from 'antd'
import { Card } from '../../../ui/Card'
import { ProductData } from '../../../types/ProductData'
import { options } from '../../../config/config'

const MapPage = () => {
  const location = useLocation()
  const { filters, dataType, page, page_size, total } = location.state || {}
  const [currentFilters, setCurrentFilters] = useState(filters || {})
  const [data, setData] = useState([])
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [coordinates, setCoordinates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(page || 1)
  const [totalData, setTotalData] = useState(total || 0)
  const [pageSize, setPageSize] = useState(page_size || 20)
  const intl = useIntl()
  const navigate = useNavigate()

  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/${dataType}`,
      method: 'GET',
      data: {
        filter: { ...currentFilters },
        page: page,
        page_size: page_size,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setCoordinates(response?.farmsCoordinates || [])
      setFilterOptions(response.filters || {})
      setData(response[dataType].data)
      setTotalData(response[dataType].total)
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
    },
  })

  useEffect(() => {
    setIsLoading(true)
    appendData({
      filter: { ...currentFilters },
      page: currentPage,
      page_size: pageSize,
    })
  }, [currentFilters, currentPage, pageSize])

  const handleFilterChange = (newFilter: any) => {
    setCurrentFilters((prevFilters: any) => ({
      ...prevFilters,
      ...newFilter,
    }))
    setCurrentPage(1)
  }

  const handleOnClickBackButton = () => {
    navigate(-1)
  }

  const handleFilterClose = () => {
    setIsFilterOpen(false)
  }

  const handleOpenFilter = () => {
    setIsFilterOpen(true)
  }

  const renderCards = () => {
    switch (dataType) {
      case 'products':
        return data.map((product: ProductData, index: number) => (
          <Card
            product={product}
            imageUrl={product.image ? product.image : ''}
            key={index}
          />
        ))
      case 'farms':
        return data.map((farm: FarmData, index: number) => (
          <FarmCard farm={farm} />
        ))
    }
  }

  return (
    <Spinner spinning={isLoading}>
      <StyledMap>
        <div className="wrapper">
          {isFilterOpen && (
            <Form
              setFilter={handleFilterChange}
              filterOptions={filterOptions}
              filter={currentFilters}
              mapView
              onCloseFilter={handleFilterClose}
            />
          )}
          <div className="map-wrap">
            <MapComponent data={coordinates} dataType={dataType} />
            {!isFilterOpen && (
              <div className={'on-map-btn-wrapper'}>
                <Button
                  label={intl.formatMessage({ id: 'general.change_filter' })}
                  icon={<Icon name={'search-icon-black'} />}
                  type={'primary'}
                  onClick={handleOpenFilter}
                  className={'on-map-btn'}
                />
              </div>
            )}
          </div>
        </div>

        <div className={'farms-list-wrapper'}>
          <div className={'back-to-list-btn-wrapper'}>
            <div className={'back-to-list-btn'}>
              <Button
                onClick={handleOnClickBackButton}
                label={intl.formatMessage({ id: 'general.back' })}
              />
            </div>
          </div>
          <div className={'farms-list'}>{renderCards()}</div>
          <Pagination
            total={totalData}
            showSizeChanger={false}
            current={currentPage}
            defaultPageSize={20}
            pageSize={pageSize}
            onChange={(page, pageSize) => {
              setPageSize(pageSize)
              setCurrentPage(page)
            }}
          />
        </div>
      </StyledMap>
    </Spinner>
  )
}

export default MapPage
