import styled from 'styled-components'
import { Form } from 'antd'

export const StyledLoginForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 545px;
    background-color: ${(props) => props.theme.white};
    font-family: ${(props) => props.theme.fontText};
    border-radius: 6px;
    margin-right: 200px;
    .ant-form-item {
        margin-bottom: 5px;

        .ant-form-item-control-input-content {
            input {
                height: 55px;
            }
        }
        
        .ant-input-suffix {
            height: 55px;

        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        padding: 75px;
        gap: 10px;
        width: 502px;
        
        .email , .pw {
            font-size: 14px;
        }
        
        .pw-wrap {
            display: flex;
            gap: 15px
        }
            
        .fulname {
            display: flex;
            gap: 15px
        }
        
        .cstm-btn {
            border-radius: 5px;
            height: 55px;
        }

        .google {
            border-color: ${(props) => props.theme.black};
            background-color: transparent;
            color: ${(props) => props.theme.black};
        }

        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 20px;
        }
      
        .no-acc-reg {
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }

}
`
