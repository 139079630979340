import styled from 'styled-components'

export const StyledMap = styled('div')`
  font-family: ${(props) => props.theme.fontText};

  .map-wrap {
    height: 900px;
    .on-map-btn-wrapper {
      position: relative;
      .on-map-btn {
        position: absolute;
        left: 30px;
        bottom: 30px;
      }
    }
  }

  .wrapper {
    height: 900px;

    .form-container {
      display: flex;
      justify-content: center;
      height: 900px;
      overflow-y: scroll;
      width: 438px;
      z-index: 1;
      margin-left: 0;
    }

    .form-container::-webkit-scrollbar {
      display: none;
    }
  }

  .farms-list-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;

    .ant-pagination {
      padding: 50px 0 60px 0;
    }

    .back-to-list-btn-wrapper {
      position: relative;
      width: 0;
      height: 0;
      padding-bottom: 5px;

      .back-to-list-btn {
        position: absolute;
        right: -700px;
        top: -31px;
        height: 4px;
      }
    }

    .farms-list {
      display: flex;
      min-height: 1050px;
      width: 1440px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .ant-card {
        background-color: ${(props) => props.theme.white};
        max-height: 240px;

        .qmarks {
          gap: 5px;
        }

        .rect-angle-wrap {
          display: none;
        }
        .farm-name {
          overflow: hidden;
          max-height: 70px;
        }
      }
    }
  }

  .form-container {
    position: absolute;
    z-index: 1000;
    margin-left: 40px;
    background-color: ${(props) => props.theme.white};
  }

  #map-popup {
    font-family: ${(props) => props.theme.fontText};
    position: relative;
    right: 90px;
  }
`
