import DefaultLayout from '../../components/DefaultLayout'
import { Form } from '../../../ui/Form/Form'
import { DefaultThirdHeader } from '../../components/DefaultLayout/DefaultThirdHeader'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { Button, Icon, Spinner } from '../../../ui'
import { Pagination } from 'antd'
import { Card } from '../../../ui/Card'
import { ProductData } from '../../../types/ProductData'
import { useCallback, useEffect, useState } from 'react'
import { StyledAllProducts } from './style'
import { ProductsTopFilter } from '../../../ui/Form/filters/ProductsTopFilter'
import { options } from '../../../config/config'
import MobileProductCategoriesCarousel from 'public/components/MobileLayout/MobileProductCategoriesCarousel/MobileProductCategoriesCarousel'
import {
  useBannerState,
  useCategoryState,
  useProductState,
} from 'public/context'
import DiscountItems from 'public/components/DefaultLayout/DiscountItems/DiscountItems'
import Banner from 'public/components/DefaultLayout/Banner'
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer'
import { StyledMobileFilterFooter } from 'public/components/MobileLayout/MobileMenu/MobileFilterFooter/style'
import MobileFilter from 'public/components/MobileLayout/MobileFilter/MobileFilter'
import { Filter } from 'types/Filter'
import { FilterOptions } from 'types/FilterOptions'
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter'
import { useFilterDispatch } from 'public/context/FilterContext'
import { useNavigate } from 'react-router-dom'

const DEFAULT_IMAGE = options.images.defaultProductImage

export const AllProducts = () => {
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = localStorage.getItem('productsFilter')
    return savedFilter ? JSON.parse(savedFilter) : {}
  })
  const [total, setTotal] = useState<number>(0)
  const [products, setProducts] = useState<ProductData[]>([])
  const { popularCategory, activeCategory } = useCategoryState()
  const { productsDiscounted } = useProductState()
  const { banners } = useBannerState()
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const savedPage = localStorage.getItem('savedPage')
    return savedPage ? JSON.parse(savedPage) : 1
  })
  const banner_3_data = banners.data.find(
    (banner) => banner.type === 'banner_3'
  )
  const banner_4_data = banners.data.find(
    (banner) => banner.type === 'banner_4'
  )
  const [pageSize, setPageSize] = useState<number>(() => {
    const savedPageSize = localStorage.getItem('savedPageSize')
    return savedPageSize ? JSON.parse(savedPageSize) : 20
  })
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const navigation = useNavigate()

  const APP_URL = window.runConfig.backendUrl

  const dispatchFilter = useFilterDispatch()

  const openCloseDrawer = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
    addClassToHeader()
  }, [isFilterOpen])

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: true, openCloseDrawer },
    })
  }, [dispatchFilter, openCloseDrawer])

  const addClassToHeader = () => {
    const header = document.getElementById('mobile-header-container')

    if (header) {
      if (!isFilterOpen) {
        header.classList.add('filterOpen')
      } else {
        header.classList.remove('filterOpen')
      }
    }
  }

  const handleSubmitFilter = () => {
    localStorage.setItem('productsFilter', JSON.stringify(filter))
    openCloseDrawer()
    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
    })
  }

  const { isLoading, appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/products',
      data: {
        page: currentPage,
        page_size: pageSize,
        ...filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setTotal(response.products.total)
      setProducts(response.products.data)
      setFilterOptions(response.filters)
    },
  })

  useEffect(() => {
    if (isFilterOpen) return

    localStorage.setItem('productsFilter', JSON.stringify(filter))
    localStorage.setItem('savedPage', currentPage.toString())
    localStorage.setItem('savedPageSize', pageSize.toString())

    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
    })
  }, [filter, currentPage, pageSize])

  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter)
    setCurrentPage(1)
  }

  const handleShowMap = () => {
    navigation('/map', {
      state: {
        filters: filter,
        dataType: 'products',
        page: currentPage,
        page_size: pageSize,
        total: total,
      },
    })
  }

  const intl = useIntl()

  const pageSizeOptions = options.filters.pageSizeOptions

  const breadcrumbs = [
    {
      route: '/',
      name: intl.formatMessage({ id: 'general.start' }),
    },
    {
      route: 'all',
      name: intl.formatMessage({ id: 'general.products' }),
    },
  ]

  const drawer = () => {
    return (
      <MobileMenuDrawer
        title={intl.formatMessage({ id: 'product.filter' })}
        onClose={openCloseDrawer}
        open={isFilterOpen}
        className="mobile-filter"
        footer={
          <StyledMobileFilterFooter>
            <Button
              label={intl.formatMessage({ id: 'general.select' })}
              icon={<Icon name={'mobile-filter-submit-arrow-icon'} />}
              onClick={handleSubmitFilter}
            />
          </StyledMobileFilterFooter>
        }
      >
        <MobileFilter
          setFilter={setFilter}
          filter={filter}
          filterOptions={filterOptions}
        />
      </MobileMenuDrawer>
    )
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledAllProducts>
          <DefaultThirdHeader
            label={intl.formatMessage({ id: 'form.products_page_title' })}
            filters={
              <ProductsTopFilter
                total={total}
                per_page={pageSize}
                setFilter={(newFilter) =>
                  handleFilterChange({ ...filter, ...newFilter })
                }
                page_size_options={pageSizeOptions}
                setPageSize={setPageSize}
                handleShowMap={handleShowMap}
                unit={'products'}
              />
            }
            breadcrumb={breadcrumbs}
          />
          <SearchAndFilter rootClassName="laptop-search-section-box" />
          <div className={'products-filters-container'}>
            <Form
              setFilter={handleFilterChange}
              filterOptions={filterOptions}
              filter={filter}
            />
            <Spinner spinning={isLoading}>
              <div className={'products-wrapper'}>
                {activeCategory ? (
                  <p className={'category_name'}>{activeCategory.name}</p>
                ) : (
                  ''
                )}
                <div className={'products'}>
                  {products.map((product: ProductData, index) => {
                    return (
                      <Card
                        key={`crd-${index}`}
                        product={product}
                        imageUrl={product.image}
                        hoverable
                      />
                    )
                  })}
                </div>

                <div className={'pagination'}>
                  <Pagination
                    total={total}
                    showSizeChanger={false}
                    current={currentPage}
                    defaultPageSize={20}
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPageSize(pageSize)
                      setCurrentPage(page)
                    }}
                  />
                </div>
              </div>
            </Spinner>
          </div>
          <MobileProductCategoriesCarousel popularCategory={popularCategory} />
          <div className={'product-slider'}>
            <DiscountItems products={productsDiscounted} slidesPerRow={1} />
          </div>
          {banner_3_data && <Banner banners={[banner_3_data]} />}
          {banner_4_data && <Banner banners={[banner_4_data]} />}
        </StyledAllProducts>
        {drawer()}
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}
