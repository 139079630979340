import styled from 'styled-components'

export const StyledTopCategoriesPage = styled('div')`
  display: flex;
  .levels {
    display: flex;
    gap: 10px;
    padding-top: 30px;
    flex-wrap: wrap;
  }
`
