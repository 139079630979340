import styled from 'styled-components'
import { Modal } from 'ui'
export const StyledAdressFinder = styled('div')`
  .form-control {
    width: 95%;
    padding: 1% 2%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: ${(props) => props.theme.white};
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.375rem;
    box-shadow: ${(props) => props.theme.adressBoxShadow};
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .row-select {
    color: ${(props) => props.theme.adressBlue};
  }
  .btn {
    color: ${(props) => props.theme.adressBlue};
    background-color: ${(props) => props.theme.white};
    width: 75px;
    height: 30px;
    border-width: 1px;
    border-radius: 5px;
  }
  .vraa-amk-address .data-grid button.btn-sm {
    border-color: ${(props) => props.theme.adressGray};
  }
  .vraa-amk-address .address-finder > div {
    margin-top: 0 !important;
  }
`
export const StyledModal = styled(Modal)``
