import React from 'react'
import { Dropdown as AntdDropdown } from 'antd'
import { MenuProps } from 'antd/es/menu'

interface DropdownProps {
  menu?: MenuProps
  trigger?: ('click' | 'hover' | 'contextMenu')[]
  onOpenChange?: (
    open: boolean,
    info: {
      source: 'trigger' | 'menu'
    }
  ) => void

  destroyPopupOnHide?: boolean

  children?: React.ReactNode
}

export const Dropdown = ({
  children,
  menu,
  destroyPopupOnHide,
  onOpenChange,
  trigger,
}: DropdownProps) => {
  return (
    <AntdDropdown
      menu={menu}
      destroyPopupOnHide={destroyPopupOnHide}
      onOpenChange={onOpenChange}
      trigger={trigger}
    >
      {children}
    </AntdDropdown>
  )
}
