import { StyledProfileForm } from './style'
import { Input } from '../../Input'
import { Checkbox } from 'ui'
import { AdresSearch } from '../../AdressSearch'
import { useState } from 'react'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { SearchOutlined } from '@ant-design/icons'

interface ProfileFormValues {
  name?: string
  surname?: string
  reg_nr?: string
  pvn_nr?: string
  country?: string
  city?: string
  address?: string
  postal_code?: string
  email?: string
  phone?: string
  current_password?: string
  new_password?: string
  confirm_password?: string
  legal_entity?: boolean
}

export interface ProfileProps {
  sendFormData?: (data: ProfileFormValues) => void
}

export const ProfileForm = ({ sendFormData }: ProfileProps) => {
  const intl = useIntl()
  const [islegalEntity, seIsLegalEntity] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (values: any) => {
    if (sendFormData) {
      sendFormData(values)
    }
  }

  useQueryApiClient({
    request: {
      url: `api/v2/profile`,
    },
    onSuccess: (response) => {
      form.setFieldsValue(response)
    },
  })

  const [form] = StyledProfileForm.useForm()

  return (
    <StyledProfileForm form={form} onFinish={handleSubmit}>
      <AdresSearch isOpen={isOpen} setIsOpen={setIsOpen} form={form} />
      <div className={'inputs'}>
        <Checkbox
          label={intl.formatMessage({ id: 'profile.legal_entity' })}
          onChange={(e) => seIsLegalEntity(e.target.checked)}
          checked={islegalEntity}
          name={'legal_entity'}
        />
        <div className={'collum'}>
          <div className="half-collum">
            {intl.formatMessage({
              id: islegalEntity ? 'profile.company_name' : 'register.name',
            })}
            <Input size={'large'} name={'name'} validations={'required'} />
          </div>
          {islegalEntity ? (
            <div className="half-collum">
              {intl.formatMessage({ id: 'farms.reg_nr' })}
              <Input size={'large'} name={'reg_nr'} validations={'required'} />
            </div>
          ) : (
            <div className="half-collum">
              {intl.formatMessage({ id: 'register.surname' })}
              <Input size={'large'} name={'surname'} validations={'required'} />
            </div>
          )}
        </div>
        <div className={'collum'}>
          {islegalEntity ? (
            <div className="half-collum">
              {intl.formatMessage({ id: 'profile.reg_nr' })}
              <Input
                size={'large'}
                name={'pvn_nr'}
                validations={'required'}
                maxLength={13}
              />
            </div>
          ) : (
            <div className="half-collum">
              {intl.formatMessage({ id: 'profile.country' })}
              <Input size={'large'} name={'country'} validations={'required'} />
            </div>
          )}
          <div className="half-collum">
            {intl.formatMessage({ id: 'profile.cityAndCounty' })}
            <Input size={'large'} name={'city'} disabled />
          </div>
        </div>
        <div className={'collum'}>
          <div className="half-collum">
            {intl.formatMessage({ id: 'profile.adress' })}
            <span onClick={() => setIsOpen(true)}>
              <Input
                size={'large'}
                name={'address'}
                addonAfter={<SearchOutlined />}
                validations={'required'}
              />
            </span>
          </div>
          <div className="half-collum">
            {intl.formatMessage({ id: 'profile.postal_number' })}
            <Input size={'large'} name={'postal_code'} disabled maxLength={7} />
          </div>
        </div>
        <div className={'collum'}>
          <div className="half-collum">
            {intl.formatMessage({ id: 'profile.email' })}
            <Input size={'large'} name={'email'} validations={'email'} />
          </div>
          <div className="half-collum">
            {intl.formatMessage({ id: 'profile.phone' })}
            <Input
              size={'large'}
              name={'phone'}
              maxLength={8}
              validations={'phoneNumber'}
            />
          </div>
        </div>
        <div>{intl.formatMessage({ id: 'profile.password_change' })}</div>
        <div>
          <div>
            {intl.formatMessage({ id: 'profile.current_password' })}
            <Input
              size={'large'}
              name={'current_password'}
              password
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('new_password') ||
                      getFieldValue('confirm_password')
                    ) {
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'validation.required',
                          })
                        )
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            />
          </div>
          <div>
            {intl.formatMessage({ id: 'profile.new_password' })}
            <Input
              size={'large'}
              name={'new_password'}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      (getFieldValue('current_password') || value) &&
                      getFieldValue('current_password')
                    ) {
                      return Promise.reject(
                        new Error(
                          intl.formatMessage({
                            id: 'validation.enter_new_password',
                          })
                        )
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
              password
            />
          </div>
          <div>
            {intl.formatMessage({ id: 'profile.confirm_password' })}
            <Input
              size={'large'}
              name={'confirm_password'}
              password
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('new_password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        intl.formatMessage({
                          id: 'validation.enter_same_password',
                        })
                      )
                    )
                  },
                }),
              ]}
            />
          </div>
          <Button
            className={'cstm-btn'}
            label={intl.formatMessage({ id: 'general.submit' })}
            size={'large'}
            htmlType={'submit'}
          />
        </div>
      </div>
    </StyledProfileForm>
  )
}
