import styled from 'styled-components'

export const StyledFarmView = styled.div`
  background-color: ${(props) => props.theme.white};

  .farm-details {
    padding: 15px 200px;
  }

  .working-time-container {
    padding: 20px;
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.lightGrey};

    p {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .working-time {
    display: flex;
    flex-direction: column;
    width: 400px;

    @media (max-width: 414px) {
      width: 300px;
    }
  }

  .day-time-row {
    display: flex;
    max-width: 320px;

  }

  .day-name{
    display: block;
    width: 120px;
  }

  .day-name,
  .day-time {
    padding: 15px;
    font-size: 17px;
    font-weight: 400;
  }

  .day-time-row:nth-child(even) {
    background-color: ${(props) => props.theme.lightGrey};
  }

  .content-section {
    padding: 40px 0;
  }

  .desktop-products-container {
    .popular-products {
      padding: 0 200px;

      @media (max-width: 1200px) {
        padding: 0 50px;
      }
    }
  }

  .desktop-products-container .title,
  .grid-view {
    padding: 0 !important;
  }

  .menu-products,
  .mobile-menu-container {
    display: none;  
    @media (max-width: 414px) {
      display: block;

      .custom-menu {
        display: flex;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .custom-menu-item {
        padding: 10px;
        cursor: pointer;
        color: ${(props) => props.theme.grey};
        font-size: 16px;
      }

      .custom-menu-item.active {
        color: ${(props) => props.theme.black};
        font-weight: bold;
      }

      .content-section {
        padding: 10px;
      }

      .custom-menu::-webkit-scrollbar {
        height: 4px;
      }
      ul {
        background-color: ${(props) => props.theme.white};
      }
    }
  }

  @media (max-width: 1400px) {
    .farm-details {
      padding: 15px 50px;
    }
  }
  @media (max-width: 845px) {
    .farm-details {
      padding: 20px;
    }
  }
  @media (max-width: 414px) {
    padding: 0;
    background-color: ${(props) => props.theme.inputGrey};

    .content-section {
      padding: 0 !important;

      .contact_name {
        padding: 20px;
      }
    }

    .mobile-description {
      padding: 20px;
    }

    .farm-details {
      display: none;
    }

    .desktop-products-container {
      display: none;
    }

    .mobile-farm-info {
      margin: 10px 20px;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: ${(props) => props.theme.white};
      .mobile-title {
        font-size: 18px;
      }

      .mobile-info-div {
        .info-column {
          display: flex;
          gap: 8px;
          align-items: center;
          border-bottom: 1px solid ${(props) => props.theme.lightGrey};
          color: ${(props) => props.theme.black};
          font-weight: 400;
          padding: 12px 0;
        }

        .info-column a {
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          max-width: 100%;
          color: ${(props) => props.theme.black};
          text-decoration: underline;
        }

        .bookmark {
          display: flex;
          flex-direction: column;
          .bookmark-2 {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 12px 0;
          }
        }
        .mobile-nums {
          display: flex;
          flex-direction: column;
          padding: 0;
          margin: 0;
        }
      }

      .mobile-buttons {
        display: flex;
        justify-content: center;

        a {
          width: 100%;
          padding: 5px;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .farm-info {
    display: flex;
    .farm-img-box .logos {
      display: none;

      @media (max-width: 414px) {
        display: block;
      }
      img {
        width: 61px;
        height: 41px;

        @media (max-width: 414px) {
          width: 36px;
          height: 23px;
          margin: 0 5px 20px;
        }
      }
    }
    h1 {
      font-size: 18px;
      margin: 15px 20px;
    }

    @media (max-width: 545px) {
      flex-direction: column;
    }

    .farm-img-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
      padding-top: 90px;
      width: 193px;
      height: 193px;
      border: 1px solid ${(props) => props.theme.lightGrey};
      @media (max-width: 545px) {
        display: flex;
        justify-content: center;
        width: 350px;

        @media (max-width: 414px) {
          width: 100%;
          padding-top: 0;
          background-color: ${(props) => props.theme.white};
        }
      }
      img {
        width: 181px;
        height: 74px;

        @media (max-width: 545px) {
          width: 300px;
        }
        @media (max-width: 414px) {
          width: 340px;
          height: 139px;
        }
      }
      .bottom-img img {
        width: 51px;
        height: 51px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.brand02};
      }
    }
    .farm-title h1 {
      margin: 0;
      font-size: 42px;

      @media (max-width: 1200px) {
        font-size: 20px;
      }
      @media (max-width: 414px) {
        font-size: 18px;
      }
    }
    .farm-contact {
      display: flex;
      flex-direction: column;
      gap: 70px;
      width: 100%;
      padding-left: 50px;

      @media (max-width: 845px) {
        padding: 0 20px;
      }
      @media (max-width: 545px) {
        padding: 0;
        margin-top: 50px;
      }

      .farm-title {
        display: flex;
        justify-content: space-between;

        .mobile-website-box {
          display: none;

          @media (max-width: 1200px) {
            display: block;
          }

          .mobile-website {
            display: flex;
            flex-direction: column;
            gap: 10px;
            a {
              text-decoration: underline;
              cursor: pointer;
              color: ${(props) => props.theme.black};
            }
            div {
              display: flex;
              gap: 20px;
            }
          }
        }

        @media (max-width: 1200px) {
          flex-direction: column;
          gap: 30px;
        }
        @media (max-width: 545px) {
          gap: 10px;
        }
        @media (max-width: 414px) {
          padding: 0 20px;
        }
      }

      .logos {
        display: flex;
        gap: 20px;

        img {
          width: 61px;
          height: 41px;

          @media (max-width: 414px) {
            width: 36px;
            height: 23px;
          }
        }
      }
    }
  }
  .menu-container {
    padding-top: 80px;

    ul li span {
      font-weight: 500;
      color: ${(props) => props.theme.grey};
    }

    @media (max-width: 845px) {
      padding-top: 30px;
    }
    @media (max-width: 414px) {
      padding-top: 20px;
      ul {
        width: 100vw;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    @media (max-width: 414px) {
      order: -1;
      padding: 0 20px;
    }

    .content-section {
      padding: 40px 0;

      .title,
      .grid-view {
        padding: 0;
      }
    }
  }
  .farm-datas {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;

    @media (max-width: 1200px) {
      display: none;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px 25px;
      width: 100%;
      border-left: 1px solid ${(props) => props.theme.borderBottom};
      height: 100%;
      margin: 0;

      li {
        display: flex;
        gap: 5px;
        list-style: none;
        font-weight: 400;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 16px;
          height: 16px;
        }
        a {
          color: ${(props) => props.theme.black};

          button {
            padding: 0 30px;
          }
        }
      }
    }

    ul:nth-child(1) {
      border: none;
      padding: 10px 50px 10px 0;
    }
  }
  .mobile-farm-datas-div {
    display: none;

    @media (max-width: 1200px) {
      display: block;
    }
    @media (max-width: 845px) {
      display: none;
    }
  }
  .mobile-farm-datas {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 10px 50px;
      width: 100%;
      border-left: 1px solid ${(props) => props.theme.borderBottom};
      height: 100%;
      margin: 0;

      li {
        display: flex;
        gap: 5px;
        align-items: center;
        list-style: none;
        font-weight: 400;
        a {
          color: ${(props) => props.theme.black};
        }
      }
    }

    @media (max-width: 1200px) {
      ul:nth-child(4) {
        display: none;
      }
    }

    ul:nth-child(1) {
      border: none;
      padding: 10px 50px 10px 0;
    }
    ul:nth-child(4) {
      padding: 10px 0 10px 50px;
    }
  }
  .farm-description {
    margin-top: 50px;
  }

  .ant-menu-item-selected {
    &::after {
      border-bottom-color: ${(props) => props.theme.black} !important;
    }

    .ant-menu-title-content {
      color: ${(props) => props.theme.black};
    }
  }

  .ant-menu-item:hover {
    &::after {
      border-bottom-color: ${(props) => props.theme.black} !important;
    }

    .ant-menu-title-content {
      color: ${(props) => props.theme.black};
    }
  }
`
