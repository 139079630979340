import { StyledBannerPreview } from './style'
import { BannerProps, ImageProps } from '../../../types/BannerData'
import { Preview } from './Preview'
import { Button, FormInstance, Image, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { UploadFile } from 'antd/es/upload/interface'
import { BeforeUpload } from '../../../utils/imageHelpers'
import { options } from '../../../config/config'
import { getBase64 } from '../../../utils/productCardHelpers'
import { Requirements } from '../Requirements'
import { ImagePlaceholder } from './ImagePlaceholder'
import { FallbackImage } from '../fallBack/FallBackImage'

interface BannerImagesProps {
  images: ImageProps[]
  form: FormInstance
  type?: 'static' | 'slider'
  banner?: BannerProps
  imagesData?: any
  setRemovedImageIds?: any
  setImagesData?: any
}

export const BannerImages = ({
  images,
  type,
  form,
  banner,
  imagesData,
  setImagesData,
  setRemovedImageIds,
}: BannerImagesProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewImage, setPreviewImage] = useState('')
  const [previewOpen, setPreviewOpen] = useState(false)
  const intl = useIntl()

  const memoizedImages = useMemo(() => images, [images])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange = (newFileList: UploadFile[]) => {
    const maxFiles = options.images.maxImages

    if (newFileList.length > maxFiles) {
      message.error(
        intl.formatMessage({
          id: 'validation.max_images',
        })
      )
      return
    }

    let updatedFileList = newFileList.map((file: any) => {
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj)
      }
      return file
    })

    if (type === 'static') {
      updatedFileList = updatedFileList.slice(-1)

      if (fileList.length > 0 && setRemovedImageIds) {
        const existingImage = fileList[0]
        if (existingImage.uid) {
          setRemovedImageIds((prev: any) => [...prev, existingImage.uid])
        }
      }
    }

    setFileList(updatedFileList)

    if (setImagesData) {
      setImagesData((prevImagesData: any[]) => {
        const newImagesData = newFileList.map((file) => {
          const existingImage = prevImagesData.find(
            (img: any) => img.uid === file.uid
          )
          return {
            uid: file.uid,
            id: existingImage ? existingImage.id : undefined,
            name: file.name,
            status: file.status,
            url: file.url || '',
            thumbUrl: file.thumbUrl || '',
            url_to: existingImage ? existingImage.url_to : '',
            originFileObj: file.originFileObj || file,
          }
        })

        return newImagesData
      })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ images: fileList })
  }, [fileList, form])

  useEffect(() => {
    if (type === 'static' && images && images.length > 0) {
      const initialFileList: any = images.map((image, index) => ({
        uid: image.id || index.toString(),
        id: image.id,
        name: image.image_name || `Image ${index + 1}`,
        status: 'done',
        url: image.url_original,
        thumbUrl: image.url_thumbnail,
        originFileObj: null,
      }))
      setFileList(initialFileList)

      if (setImagesData) {
        setImagesData(initialFileList)
      }
    }
  }, [type, images])

  return (
    <StyledBannerPreview>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
      <Requirements />
      {type === 'static' ? (
        <div>
          <Preview images={images} type={banner?.type} />
          <Upload
            name={'images'}
            beforeUpload={BeforeUpload}
            fileList={fileList}
            onChange={({ fileList: newFileList }) => handleChange(newFileList)}
            onPreview={handlePreview}
            multiple={false}
          >
            <Button icon={<UploadOutlined />}>
              {intl.formatMessage({ id: 'general.upload' })}
            </Button>
          </Upload>
        </div>
      ) : (
        <div className={'wrapper-banner'}>
          <ImagePlaceholder
            images={memoizedImages}
            type={banner?.type}
            form={form}
            handleChange={handleChange}
            imagesData={imagesData}
            setImagesData={setImagesData}
            setRemovedImageIds={setRemovedImageIds}
          />
        </div>
      )}
    </StyledBannerPreview>
  )
}
