import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useQueryApiClient from '../utils/useQueryApiClient'

interface AuthProps {
  name?: string
  surname?: string
  authenticated: boolean
  farmId?: number
  isAdmin?: boolean
}

interface AuthContextProps {
  user: AuthProps | null
  isFetchingUser: boolean
  login: (formData: any) => Promise<void>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<AuthProps | null>(null)
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(true)
  const navigate = useNavigate()
  const comesFrom = localStorage.getItem('/comesFrom')

  const { refetch: fetchUser } = useQueryApiClient({
    request: {
      url: `/api/v2/user`,
      method: 'GET',
    },
    onSuccess: (data: AuthProps) => {
      setUser(data)
      setIsFetchingUser(false)
    },
    onError: () => {
      setUser({ authenticated: false })
      setIsFetchingUser(false)
    },
    enabled: true,
  })

  const { appendData } = useQueryApiClient({
    request: {
      url: `/api/v2/login`,
      method: 'POST',
    },
    onSuccess: (response) => {
      setUser({ authenticated: response.authenticated } as AuthProps)
      if (comesFrom) {
        navigate('/' + comesFrom)
        localStorage.removeItem('/comesFrom')
      } else {
        navigate('/')
      }
    },
    onError: (error) => {
      console.error('Login error:', error)
    },
    enabled: true,
  })

  const { appendData: logoutRequest } = useQueryApiClient({
    request: {
      url: `/api/v2/logout`,
      method: 'POST',
    },
    onFinally: () => {
      setUser({ authenticated: false })
      navigate('/login')
    },
    enabled: true,
  })

  const login = async (formData: any) => {
    try {
      await fetch(`${window.runConfig.backendUrl}/sanctum/csrf-cookie`, {
        credentials: 'include',
      })

      appendData(formData)
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const logout = async () => {
    try {
      logoutRequest()
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  return (
    <AuthContext.Provider value={{ user, isFetchingUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
