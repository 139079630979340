import React from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, CustomGrid, Divider, Input, Label } from 'ui'
import { Form } from 'antd'

const InformationTab = () => {
  const intl = useIntl()

  return (
    <Form layout="vertical">
      <Input label={intl.formatMessage({ id: 'product.name' })} name="name" />

      <Divider />
      <Label
        label={intl.formatMessage({ id: 'product.product_available_bulk' })}
        title
      />
      <CustomGrid>
        <Input
          label={intl.formatMessage({ id: 'product.minimal_amount' })}
          name="minimal_amount"
        />
        <Input
          label={intl.formatMessage({ id: 'product.wholesale_price' })}
          name="wholesale_price"
        />
      </CustomGrid>
      <Divider marginTop={6} />

      <Label
        label={intl.formatMessage({ id: 'product.more_information' })}
        title
      />
      <CustomGrid>
        <Checkbox
          name="f"
          breakNewLine
          options={[
            {
              label: intl.formatMessage({ id: 'product.integrated_breeder' }),
              value: 'integrated_breeder',
            },
            {
              label: intl.formatMessage({
                id: 'product.product_natural_meadows',
              }),
              value: 'product_natural_meadows',
            },
            {
              label: intl.formatMessage({ id: 'product.biological' }),
              value: 'biological',
            },
            {
              label: intl.formatMessage({ id: 'product.global' }),
              value: 'global',
            },
            {
              label: intl.formatMessage({ id: 'product.green_spoon' }),
              value: 'green_spoon',
            },
          ]}
        />
        <Checkbox
          name="f"
          breakNewLine
          options={[
            {
              label: intl.formatMessage({ id: 'product.integrated_breeder' }),
              value: 'integrated_breeder',
            },
            {
              label: intl.formatMessage({
                id: 'product.product_natural_meadows',
              }),
              value: 'product_natural_meadows',
            },
            {
              label: intl.formatMessage({ id: 'product.biological' }),
              value: 'biological',
            },
            {
              label: intl.formatMessage({ id: 'product.global' }),
              value: 'global',
            },
            {
              label: intl.formatMessage({ id: 'product.green_spoon' }),
              value: 'green_spoon',
            },
          ]}
        />
      </CustomGrid>
    </Form>
  )
}

export default InformationTab
