import styled from 'styled-components'

export const StyledRequirements = styled('div')`
  .reqs {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    gap: 5px;
    font-size: 14px;

    div {
      margin: 0;
      display: flex;
      gap: 10px;
      .formats {
        display: flex;
        gap: 5px;
      }
    }
  }
`
