import styled, { css } from 'styled-components'

const sharedStyles = css`
  margin: 0;
  display: flex;
  flex-direction: column;

  .txt-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    min-width: 2px;
    padding: 0;
    display: flex;
    align-items: center;

    .opt-wrapp {
      position: absolute;
    }

    .ant-form-item {
      margin-bottom: 0;
      padding-bottom: 8px;

      .ant-select-clear {
        padding-left: 18px;
        padding-top: 3px;
      }

      .ant-select-selection-item {
        padding-top: 4px;
        padding-right: 4px;
      }
    }
  }
`

export const StyledOrderBySelector = styled('div')`
  ${sharedStyles}
`

export const StyledPageSizeSelector = styled('div')`
  ${sharedStyles}
`

export const StyledTopCategorySelector = styled('div')`
  ${sharedStyles};

  border: 1px solid ${(props) => props.theme};
  padding: 15px;

  .ant-tooltip {
    display: none;
  }
  .btn {
    max-width: 200px;
    display: flex;
    font-size: 14px;
  }

  .category {
    display: flex;
    flex-direction: column;

    .ant-upload-list-item-container {
      max-width: 200px;
      overflow: hidden;
    }

    .ant-form-item {
      min-width: 200px;
      max-width: 200px;

      .ant-select-dropdown {
        position: absolute;
        top: 20px;
      }
    }
  }
`

export const StyledCategorySelector = styled('div')`
  ${sharedStyles};

  .displayFlex {
    display: flex;
    gap: 10px;
  }
  .admin {
    display: flex;
    justify-content: space-between;
  }
`
