import { useIntl } from 'react-intl'
import { CSSProperties } from 'react'
import { LocationsFilterOptions } from 'types/FilterOptions'
import { Select, SelectOption } from 'ui'

interface Props {
  locations: LocationsFilterOptions[] | undefined
  isLoading?: boolean
}

export const LocationSelector = ({ locations }: Props) => {
  const intl = useIntl()

  const style: CSSProperties = {
    minWidth: 245,
    minHeight: 45,
    marginBottom: 5,
    maxWidth: 245,
  }

  return (
    <div>
      <div className={'form-title'}>
        {intl.formatMessage({ id: 'form.location_title' })}
      </div>
      <Select
        placeholder={intl.formatMessage({ id: 'form.select_location' })}
        className="drop-down"
        showSearch={false}
        name={'location'}
        size={'small'}
        allowClear
        style={style}
      >
        {locations?.map((location, index) => (
          <SelectOption key={`${index}-loc`} value={location.slug}>
            {location.name}
          </SelectOption>
        ))}
      </Select>
    </div>
  )
}
