import React, { ReactNode } from 'react'
import { StyledSpinner } from './styles'

export interface SpinnerProps {
  spinning: boolean
  wrapperClassName?: string
  delay?: number
  children: React.ReactNode
  dontRender?: boolean
  fullScreen?: boolean
  indicator?: ReactNode
  percent?: number | 'auto'
  size?: 'small' | 'default' | 'large'
  tip?: ReactNode
}

export const Spinner = ({
  spinning,
  wrapperClassName,
  children,
  dontRender,
  delay,
  fullScreen,
  indicator,
  percent,
  size,
  tip,
}: SpinnerProps) => {
  return (
    <>
      <StyledSpinner
        delay={delay}
        fullscreen={fullScreen}
        percent={percent}
        size={size}
        tip={tip}
        spinning={spinning}
        wrapperClassName={wrapperClassName}
      >
        {dontRender && spinning ? (
          <div className="full-spinner"></div>
        ) : (
          children
        )}
      </StyledSpinner>
    </>
  )
}
