import React from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, Input, Tabs, Table, Dropdown, Steps } from '../../../ui'
import { DownOutlined } from '@ant-design/icons'
import Filter from '../../components/Filter'
import { StyledButtonList } from 'styles/buttonList'
import { MenuProps } from 'antd/es/menu'

const TestPage = () => {
  const intl = useIntl()

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e)
  }

  const dropdownItems: MenuProps['items'] = [
    {
      label: '1st menu item',
      key: '1',
    },
    {
      label: '2nd menu item',
      key: '2',
    },
    {
      label: '3rd menu item',
      key: '3',
      danger: true,
    },
    {
      label: '4rd menu item',
      key: '4',
      danger: true,
      disabled: true,
    },
  ]

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'deals.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.order_nr' }),
      dataIndex: 'order_nr',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.customer' }),
      dataIndex: 'customer',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.delivery_type' }),
      dataIndex: 'delivery_type',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.status' }),
      dataIndex: 'status',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.price' }),
      dataIndex: 'price',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'deals.bill' }),
      dataIndex: 'bill',
      render: (value: string) => (
        <Button
          type="link"
          label={intl.formatMessage({ id: 'general.download' })}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'deals.actions' }),
      dataIndex: 'actions',
      render: (value: string) => (
        <Dropdown menu={menuProps}>
          <Button
            label={intl.formatMessage({ id: 'general.actions' })}
            icon={<DownOutlined />}
            iconPosition="end"
            size="small"
          />
        </Dropdown>
      ),
    },
  ]

  const items = [
    {
      key: 'active_transactions',
      label: intl.formatMessage({ id: 'deals.active_transactions' }),
      children: (
        <>
          <Filter onFinish={() => {}}>
            <Input
              label={intl.formatMessage({ id: 'general.year' })}
              name="year"
            />
            <Input
              label={intl.formatMessage({ id: 'general.month' })}
              name="month"
            />
            <Input
              label={intl.formatMessage({ id: 'general.status' })}
              name="status"
            />
            <Input
              label={intl.formatMessage({ id: 'general.order_nr' })}
              name="order_nr"
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
            <Button
              label={intl.formatMessage({ id: 'general.advanced_filter' })}
            />
          </Filter>
          <Table
            url="/api/v1/test"
            columns={columns}
            dataSource={[
              {
                id: '1',
                name: 'Active Transaction 1',
                amount: 10,
              },
              {
                id: '2',
                name: 'Active Transaction 2',
                amount: 20,
              },
              {
                id: '3',
                name: 'Active Transaction 3',
                amount: 30,
              },
            ]}
          />
        </>
      ),
    },
    {
      key: 'old_transactions',
      label: intl.formatMessage({ id: 'deals.old_transactions' }),
      children: (
        <>
          <Steps
            items={[
              {
                status: 'finish',
                title: 'Step 1',
              },
              {
                status: 'process',
                title: 'Step 2',
              },
              {
                status: 'wait',
                title: 'Step 3',
              },
              {
                status: 'wait',
                title: 'Step 4',
              },
            ]}
          />
          <Steps
            type="navigation"
            items={[
              {
                status: 'finish',
                title: 'Step 1',
              },
              {
                status: 'process',
                title: 'Step 2',
              },
              {
                status: 'wait',
                title: 'Step 3',
              },
              {
                status: 'wait',
                title: 'Step 4',
              },
            ]}
          />
        </>
      ),
    },
  ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.deals' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.active_orders' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                type="primary"
                label={intl.formatMessage({ id: 'deals.add_order' })}
              />
              <Button label={intl.formatMessage({ id: 'general.export' })} />
            </StyledButtonList>
          }
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TestPage
