import React from 'react'
import { FarmCoordinates, FarmData } from '../../types/FarmData'

interface PayloadType {
  refetch: Function
  farmsCoordinates?: FarmCoordinates[]
  farm?: FarmData
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH'
  payload: PayloadType
  isLoading: boolean
}

type RefetchAction = {
  type: 'REFETCH'
}

type Action = PayloadAction | RefetchAction

type Dispatch = (action: Action) => void

type State = {
  farmsCoordinates?: FarmCoordinates[]
  refetch: Function
  farm?: FarmData 
  isLoading: boolean
}

type FarmProviderProps = { children: React.ReactNode }

const FarmStateContext = React.createContext<State>(undefined!)
const FarmDispatchContext = React.createContext<Dispatch>(undefined!)

function FarmReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload

      return {
        ...state,
        ...payload,
      }
    }
    case 'REFETCH': {
      state.refetch()
      return {
        ...state,
      }
    }
  }
}

function FarmProvider({ children }: FarmProviderProps) {
  const initialState = {
    farmsCoordinates: [],
    refetch: () => null,
    isLoading: false,
  }

  const [state, dispatch] = React.useReducer(FarmReducer, initialState)

  return (
    <FarmStateContext.Provider value={state}>
      <FarmDispatchContext.Provider value={dispatch}>
        {children}
      </FarmDispatchContext.Provider>
    </FarmStateContext.Provider>
  )
}

const useFarmState = () => React.useContext(FarmStateContext)
const useFarmDispatch = () => React.useContext(FarmDispatchContext)

export { FarmProvider, useFarmDispatch, useFarmState }
