import useQueryApiClient from '../../../utils/useQueryApiClient'
import { Table } from 'ui'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { OrderLayout } from './style'

export const MyOrders = () => {
  const intl = useIntl()

  const columns = [
    {
      title: intl.formatMessage({ id: 'orders.order' }),
      dataIndex: 'number',
    },
    {
      title: intl.formatMessage({ id: 'general.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },

    {
      title: intl.formatMessage({ id: 'orders.statuss' }),
      dataIndex: 'status',
      render: (value: string) => intl.formatMessage({ id: `orders.${value}` }),
    },
    {
      title: intl.formatMessage({ id: 'orders.summary' }),
      dataIndex: 'total',
      render: (value: string, row: any) =>
        `€ ${value} ${intl.formatMessage({ id: 'general.for' })} ${row.product_count} ` +
        (row.product_count > '1'
          ? intl.formatMessage({ id: 'orders.for_units' })
          : intl.formatMessage({ id: 'orders.for_unit' })),
    },
    {
      title: intl.formatMessage({ id: 'orders.actions' }),
      render: () => (
        <>
          <span className="view-order">
            {intl.formatMessage({ id: 'orders.view' })}
          </span>
          <span className="order-download">
            {intl.formatMessage({ id: 'orders.download' })}
          </span>
        </>
      ),
    },
  ]

  return (
    <>
      <OrderLayout>
        <h1 className="title">
          {intl.formatMessage({ id: 'general.my_orders' })}
        </h1>
        <Table url="/api/v2/user/order" columns={columns} />
      </OrderLayout>
    </>
  )
}
