import styled from 'styled-components'

export const StyledSearchAndFilter = styled.div`
    .filter-open-btn {
        display: flex;
        height: auto;
        padding: 11px 14px !important;
        border-radius: 5px;
    }

    .filter-open-btn:hover {
        background: ${({ theme }) => theme.bgColorHover} !important;
        border-color: ${({ theme }) => theme.colorBorder} !important;
        color: ${({ theme }) => theme.white} !important;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    }

    .search-section {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        background-color: ${({ theme }) => theme.inputGrey};
    }

    @media only screen and (max-width: 1200px) {
        .search-section {
            padding: 0 20px;
        }
    }
   
    @media only screen and (max-width: 414px) {
        &.laptop-search-section-box {
            display: none;
        }

        .search-section-fixed{
            position: fixed !important;
            top: 0px !important;
            z-index: 1;
        }

    }
`
