import DefaultLayout from '../../components/DefaultLayout'
import { useParams } from 'react-router-dom'
import { useProductDispatch, useProductState } from '../../context'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { Spinner } from '../../../ui'
import ProductView from './ProductView'

const ProductPage = () => {
  const { id } = useParams<{ id: string }>()
  const dispatchProducts = useProductDispatch()

  const { refetch: refetchProduct, isLoading: isLoadingProduct } =
    useQueryApiClient({
      request: {
        url: `api/v2/products/${id}`,
      },
      onSuccess: (response) => {
        dispatchProducts({
          type: 'SAVE_PAYLOAD',
          payload: { product: { ...response }, refetch: refetchProduct },
          isLoading: isLoadingProduct,
        })
      },
    })

  const { product } = useProductState()

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <Spinner spinning={isLoadingProduct}>
          <ProductView product={product} />
        </Spinner>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default ProductPage
