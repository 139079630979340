import React from 'react'
import { Icon } from '../../../ui'

const svgPaths = [
  'arrow-down-white',
  'cluster-large',
  'cluster-small',
  'discount-tag',
  'heart-black',
  'heart-filled',
  'pin',
  'search-icon-black',
  'shop-icon',
  'shopping-cart-black',
  'shopping-cart-white',
  'user-profile-black',
  'zoom-in',
  'zoom-out',
  'open-image',
]

export const IconsPage = () => {
  if (window['runConfig'].nodeEnv !== 'development') {
    return null
  }

  return (
    <div
      className={'icons'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 50,
        maxHeight: 400,
        maxWidth: 'fit-content',
        flexWrap: 'wrap',
      }}
    >
      {svgPaths.map((svgPath: string, index: number) => (
        <div
          key={index}
          className="icon-wrapper"
          style={{
            width: 120,
            padding: 15,
            height: 120,
            backgroundColor: 'lightgray',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className={'wrap'} style={{ position: 'absolute' }}>
            <div
              className={'label'}
              style={{ position: 'relative', fontSize: 12, bottom: -35 }}
            >
              {svgPath}
            </div>
          </div>
          <Icon name={svgPath} />
        </div>
      ))}
    </div>
  )
}
