import React from 'react'
import { Select, SelectOption } from 'ui'
import { useIntl } from 'react-intl'
import { Validations } from 'interfaces'

interface YesNoSelectProps extends Validations {
  name?: (string | number)[] | string | number
  mode?: 'multiple' | 'tags'
  noStyle?: boolean
  label?: string
  disabled?: boolean
}

const YesNoSelect = ({
  name,
  mode,
  validations,
  noStyle,
  label,
  disabled,
}: YesNoSelectProps) => {
  const intl = useIntl()

  return (
    <Select
      mode={mode}
      label={label}
      name={name}
      validations={validations}
      noStyle={noStyle}
      disabled={disabled}
    >
      <SelectOption key={1} value={1}>
        {intl.formatMessage({ id: 'general.yes' })}
      </SelectOption>
      <SelectOption key={2} value={0}>
        {intl.formatMessage({ id: 'general.no' })}
      </SelectOption>
    </Select>
  )
}

export default YesNoSelect
