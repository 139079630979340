import React from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'

export interface TextAreaProps {
  placeholder?: string
  required?: boolean
  size?: 'large' | 'middle' | 'small'
  value?: string
  defaultValue?: string
  maxLength?: number
  allowClear?: boolean
  label?: string
  rules?: Rule[]
  name?: (string | number)[] | string | number
  className?: string
  validateTrigger?: string | string[]
  noStyle?: boolean
  initialValue?: string
  disabled?: boolean
}

const { TextArea: AntdTextArea } = Input

export const TextArea = ({
  disabled = false,
  placeholder,
  name,
  required,
  size = 'large',
  value,
  defaultValue,
  maxLength = 255,
  allowClear,
  label,
  rules,
  className,
  validateTrigger,
  noStyle,
  initialValue,
}: TextAreaProps) => {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
      validateTrigger={validateTrigger}
      noStyle={noStyle}
    >
      <AntdTextArea
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        size={size}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        allowClear={allowClear}
      />
    </Form.Item>
  )
}
