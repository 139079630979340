import { FarmData } from "types/FarmData";
import { FC } from "react";
import { Image } from "antd";
import { FarmPartnersWrapper } from "./style";

interface Props {
    farm: FarmData | undefined
}

const Partners: FC<Props> = ({ farm }) => {
    return (
        <FarmPartnersWrapper className="farm-partner">
            <div className="mobile-farm-partners">
            {
               farm?.partner_farms && farm.partner_farms.map(patner => (
                    <div className="partner-item" key={patner.id}>
                        <Image
                            className={'partner-image'}
                            src={"/farm-image.png"}
                            alt={`carousel-image-`}
                        />
                        <span  className="partner-name">{patner.name}</span>
                    </div>
                ))
            }
            </div>
        </FarmPartnersWrapper>
    );
}

export default Partners
