import styled from 'styled-components'

export const StyledPage = styled.div`
  font-family: ${(props) => props.theme.fontText} !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  .layout {
    display: flex;
    flex-direction: column;
  }

  .ant-layout-content {
    width: initial !important;
    min-height: 100vh;
  }
`
