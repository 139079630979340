import DefaultLayout from 'public/components/DefaultLayout'
import {
  useBannerState,
  useCategoryState,
  useProductState,
} from '../../context'
import Banner from '../../components/DefaultLayout/Banner'
import PopularProducts from '../../components/DefaultLayout/PopularProducts'
import DiscountItems from '../../components/DefaultLayout/DiscountItems/DiscountItems'
import PopularCategories from 'public/components/DefaultLayout/PopularCategories'
import MobileProductCategoriesCarousel from 'public/components/MobileLayout/MobileProductCategoriesCarousel/MobileProductCategoriesCarousel'
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer'
import { StyledMobileFilterFooter } from 'public/components/MobileLayout/MobileMenu/MobileFilterFooter/style'
import MobileFilter from 'public/components/MobileLayout/MobileFilter/MobileFilter'
import { useIntl } from 'react-intl'
import { Button, Icon } from 'ui'
import { useCallback, useEffect, useState } from 'react'
import { FilterOptions } from 'types/FilterOptions'
import { Filter } from 'types/Filter'
import useQueryApiClient from 'utils/useQueryApiClient'
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter'
import { useFilterDispatch } from 'public/context/FilterContext'
import { BannerProps } from '../../../types/BannerData'

const TestPage = () => {
  const intl = useIntl()
  const { banners } = useBannerState()
  const { products } = useProductState()
  const { popularCategory } = useCategoryState()
  const { productsDiscounted } = useProductState()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = localStorage.getItem('productsFilter')
    return savedFilter ? JSON.parse(savedFilter) : {}
  })

  const dispatchFilter = useFilterDispatch()

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/products',
      data: {
        page: 1,
        page_size: 1,
        ...filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setFilterOptions(response.filters)
    },
  })

  useEffect(() => {
    if (isFilterOpen) return

    appendData({
      filter: filter,
      page: 1,
      page_size: 1,
    })
  }, [filter])

  const openCloseDrawer = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen])

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: true, openCloseDrawer },
    })
  }, [dispatchFilter, openCloseDrawer])

  const handleSubmitFilter = () => {
    localStorage.setItem('productsFilter', JSON.stringify(filter))
  }

  const banner_1_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_1'
  )
  const banner_2_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_2'
  )
  const banner_3_data = banners.data.find(
    (banner: BannerProps) => banner.type === 'banner_3'
  )

  const drawer = () => {
    return (
      <MobileMenuDrawer
        title={intl.formatMessage({ id: 'product.filter' })}
        onClose={openCloseDrawer}
        open={isFilterOpen}
        className="mobile-filter"
        footer={
          <StyledMobileFilterFooter>
            <Button
              label={intl.formatMessage({ id: 'general.select' })}
              icon={<Icon name={'mobile-filter-submit-arrow-icon'} />}
              onClick={handleSubmitFilter}
              href={'/products/all'}
            />
          </StyledMobileFilterFooter>
        }
      >
        <MobileFilter
          setFilter={setFilter}
          filter={filter}
          filterOptions={filterOptions}
        />
      </MobileMenuDrawer>
    )
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        {banner_1_data && (
          <Banner banners={[banner_1_data]} className={banner_1_data.type} />
        )}
        <SearchAndFilter rootClassName="laptop-search-section-box" />
        <PopularProducts products={products} />
        <MobileProductCategoriesCarousel popularCategory={popularCategory} />
        <PopularCategories categories={popularCategory} />
        {banner_2_data && banner_3_data && (
          <Banner
            banners={[banner_2_data, banner_3_data]}
            className={'desktop-banner'}
          />
        )}
        <DiscountItems products={productsDiscounted} slidesPerRow={1} />
        {banner_2_data && banner_3_data && (
          <Banner
            banners={[banner_3_data, banner_2_data]}
            className={'mobile-banner'}
          />
        )}
        {drawer()}
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TestPage
