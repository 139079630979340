import { RcFile } from 'antd/es/upload/interface'
import { options } from '../config/config'
import { message, Upload } from 'antd'
import { useIntl } from 'react-intl'

export const BeforeUpload = async (file: RcFile) => {
  const intl = useIntl()
  const maxSizeInMB = options.images.maxSizeInMB
  const allowedFormats = options.images.allowedFormats

  if (file.size / 1024 / 1024 > maxSizeInMB) {
    message.error(
      intl.formatMessage({
        id: 'validation.max_file_size',
      })
    )
    return Upload.LIST_IGNORE
  }

  if (!allowedFormats.includes(file.type)) {
    message.error(
      intl.formatMessage({
        id: 'validation.allowed_formats',
      })
    )
    return Upload.LIST_IGNORE
  }
  return true
}
