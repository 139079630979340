import { Space } from 'antd'
import {
  FarmProduct,
  ParcelLocation,
  SelectedDelivery,
} from 'public/context/CartContext'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { FarmDeliveries } from 'types/FarmData'
import {
  Select,
  SelectOption,
  SelectOptionsValueString,
  SelectOptionsValueStringNumber,
  Spinner,
} from 'ui'
import useQueryApiClient from 'utils/useQueryApiClient'
import { StyledDelivery } from '../style'
import { FormInstance } from 'antd'

interface VenipakDeliveryProps {
  farmProduct: FarmProduct
  selectedDelivery: FarmDeliveries
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >
  selectedDeliveryData: SelectedDelivery[] | null
  deliveryForm: FormInstance<any>
}

const VenipakDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  farmProduct,
  selectedDeliveryData,
  deliveryForm,
}: VenipakDeliveryProps) => {
  const intl = useIntl()
  const [selectedCityAddress, setSelectedCityAddress] = useState<string | null>(
    null
  )

  const {
    isLoading: parishLoading,
    data: cityData,
    refetch,
  } = useQueryApiClient({
    request: { url: 'api/v2/delivery/venipakPickupGetCities' },
  })

  const { isLoading: parcelLoading, data: parcelData } = useQueryApiClient({
    request: {
      url: `api/v2/delivery/venipakPickupGetLocations/${selectedCityAddress}`,
    },
    enabled: !!selectedCityAddress,
  })

  const handleCityChange = (city: string) => {
    setSelectedCityAddress(city)
    handleRefetch()
  }

  const handleRefetch = () => {
    deliveryForm.setFieldValue(`venipakLocation.${farmProduct.farm_id}`, null)
    refetch()
  }

  const handleParcelChange = (parcelId: number) => {
    if (!selectedCityAddress) return

    const selected = parcelData?.find(
      (location: ParcelLocation) => location.id === parcelId
    )

    if (selected && selectedDelivery) {
      setSelectedDeliveryData([
        ...(selectedDeliveryData || []),
        {
          id: selected.id,
          selectedDeliveryId: selectedDelivery.id,
          city: selectedCityAddress,
          address: selected.address,
          firmName: intl.formatMessage({ id: 'process_purchase.venipack' }),
          name: selected.name,
          price: selectedDelivery.price,
        },
      ])
    }
  }

  const optionsCities: SelectOptionsValueString[] = cityData?.map(
    (city: string) => ({
      label: city,
      value: city,
    })
  )

  const optionsParcels: SelectOptionsValueStringNumber[] = parcelData?.map(
    (location: ParcelLocation) => ({
      label: location.name,
      value: location.id,
    })
  )

  return (
    <StyledDelivery>
      <Spinner spinning={parishLoading}>
        <Space direction="vertical">
          {cityData && (
            <Select
              // className={'delivery-select'}
              validations="required"
              initialValue={farmProduct.selectedDelivery?.city}
              name={`venipakCity.${farmProduct.farm_id}`}
              size="middle"
              onChange={handleCityChange}
              children={
                optionsCities &&
                optionsCities.map((options, index) => (
                  <SelectOption value={options.value} key={`${index} - opt`}>
                    {options.label}
                  </SelectOption>
                ))
              }
            />
          )}

          <Spinner spinning={parcelLoading}>
            {(parcelData?.length > 0 || farmProduct.selectedDelivery) && (
              <Select
                validations={'required'}
                size="middle"
                name={`venipakLocation.${farmProduct.farm_id}`}
                style={{ width: '100%' }}
                value={farmProduct.selectedDelivery?.name}
                initialValue={farmProduct.selectedDelivery?.name}
                onChange={handleParcelChange}
                children={
                  optionsParcels &&
                  optionsParcels.map((options, index) => (
                    <SelectOption value={options.value} key={`${index} - opt`}>
                      {options.label}
                    </SelectOption>
                  ))
                }
              />
            )}
          </Spinner>
        </Space>
      </Spinner>
    </StyledDelivery>
  )
}

export default VenipakDelivery
