export interface IConfig {
    defaults: IDefaults;
    basemaps: IBasemap[];
    geoLocation?: ILocation;
}

export interface IDefaults {
    center: number[];
    featureLayerId: string;
    zoomToLevel: number;
    debugCoordinatesOnMap?: boolean; // true, to log out coordinates on map
}
export interface IBasemap {
    title: string;
    id: string;
    layers: IBasemapLayer[];
}
export interface IBasemapLayer {
    type: string;
    version?: string;
    spatialReference?: number;
    layers?: string[];
    imagetype?: string;
    url?: string;
    copyright?: string;
    visible: boolean;
}
export interface IService {
    title: string;
    id: string;
    url: string;
}
export interface IServiceUrl {
    url: string;
}
export interface IFilterOptions {
    title: string;
    value: number | string | boolean | number[];
}
export interface ILocation {
    geolocationOptions?: {
        maximumAge: number;
        timeout: number;
        enableHighAccuracy: boolean;
    };
}
export interface IExtent {
    xmax: number;
    xmin: number;
    ymax: number;
    ymin: number;
    spatialReference: {
        wkid: number;
    };
}

export const config: IConfig = {
    defaults: {
        // center: [24.041519, 56.934571],
        center: [2711797, 7748950],
        featureLayerId: "clusterLayer",
        zoomToLevel: 11,
        debugCoordinatesOnMap: false // set true, to display coordinates on map
    },
    basemaps: [
        {
            title: "Open Street Map",
            id: "osm",
            layers: [
                {
                    type: "OSM",
                    copyright: "© OpenStreetMap veidotāji",
                    visible: true
                }
            ]
        }
    ],
    geoLocation: {
        geolocationOptions: {
            maximumAge: 0,
            timeout: 15000,
            enableHighAccuracy: true
        }
    }
};
