import styled from 'styled-components'

export const StyledSecondHeader = styled.div`
  width: 100%;
  height: ${(props) => props.theme.mainHeaderHeight};
  background-color: ${(props) => props.theme.white};
  position: relative;
  z-index: 15;
  display: flex;
  flex-grow: 1;
  min-width: 0;

  .content-wrapper {
    position: relative;
    z-index: 20;
    background-color: ${(props) => props.theme.white};
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.backgroundOverlay};
    z-index: 0;
  }

  .content {
    height: ${(props) => props.theme.mainHeaderHeight};
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0 200px;
    min-width: 0;

    .block {
      display: flex;
      gap: 10px;
      flex-grow: 1;
      justify-content: center;
      min-width: 0;
      align-items: center;

      .second-header-btn-wrapper {
        display: flex;
        justify-content: center;

        .second-header-cart-total {
          background-color: ${(props) => props.theme.brand02};
          position: absolute;
          top: 7px;
          border-radius: 10px 10px 10px 0;
          padding: 3px 14px 3px 14px;
          font-size: 12px;
          color: ${(props) => props.theme.white};
          font-family: ${(props) => props.theme.fontText};
          font-weight: 400;
        }
      }

      .menu-pos {
        width: 0;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .content {
      padding: 0 0;
      .block {
        gap: 5px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`
