import React, { useRef, useState } from 'react'
import { StyledGroupedFarmCart } from './style'
import { CartCard } from 'ui/Card/CartCard'
import { useIntl } from 'react-intl'
import { Button, Drawer, Icon } from 'ui'
import { FarmProduct, useCartDispatch } from 'public/context/CartContext'
import DeliveryForm from '../DeliveryForm/DeliveryForm'
import { Form } from 'antd'
import useQueryApiClient from 'utils/useQueryApiClient'
import { StyledDeliveryForm } from '../DeliveryForm/style'
import { StyledDeliveryDrawerFooter } from 'ui/Drawer/Drawers/style'
interface GroupedFarmCartProps {
  farmProducts: FarmProduct[]
  action?: 'view'
}

const GroupedFarmCart = ({ farmProducts, action }: GroupedFarmCartProps) => {
  const intl = useIntl()
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState<boolean>(false)
  const dispatchCart = useCartDispatch()
  const productIds: number[] = []

  const [deliveryForm] = Form.useForm()

  const deliverySubmitCallbacks = useRef<(() => void)[]>([])

  useQueryApiClient({
    request: {
      url: `api/v2/products/cart/refetch`,
      data: {
        productIds: productIds,
      },
    },
    onSuccess: (response) => {
      dispatchCart({
        type: 'REFETCH_CART_PRODUCTS',
        aggregatedProducts: response,
      })
    },
  })

  farmProducts.forEach((farmProduct) => {
    farmProduct.products.forEach((cartProduct) => {
      if (cartProduct.cartProduct?.id !== undefined) {
        productIds.push(cartProduct.cartProduct.id)
      }
    })
  })

  const noCloseDeliveryDrawer = () => {
    deliverySubmitCallbacks.current.forEach((callback) => callback())
    setIsCartDrawerOpen(false)
  }

  const registerSubmitCallback = (submitCallback: () => void) => {
    deliverySubmitCallbacks.current.push(submitCallback)
  }

  const closeForm = () => {
    noCloseDeliveryDrawer()
  }

  return (
    <StyledGroupedFarmCart>
      <div className="container">
        <div className="cart-products-container">
          {farmProducts.map((farmGroup) => (
            <div className="cart-products-farm-wrapper" key={farmGroup.farm_id}>
              <div className="cart-products-header">
                <div className="cart-cell-1">
                  {intl.formatMessage({ id: 'cart.products' })}
                </div>
                <div className="cart-cell-2">
                  {intl.formatMessage({ id: 'delivery.price' })}
                </div>
                <div className="cart-cell-3">
                  {intl.formatMessage({ id: 'cart.amount' })}
                </div>
                <div className="cart-cell-4">
                  {intl.formatMessage({ id: 'cart.totlal' })}
                </div>
              </div>
              <div className="cart-products-content-wrapper">
                {farmGroup.products.map((product) => (
                  <div
                    className="cart-product show-sum-card"
                    key={product.cartProduct?.id}
                  >
                    <h3 className="title">{product.cartProduct?.farm_name}</h3>
                    <CartCard product={product} action={action} />
                  </div>
                ))}
                <div className="cart-delivery-container">
                  {farmGroup.selectedDelivery ? (
                    <div className="cart-delivery-info">
                      {farmGroup.selectedDelivery.price ? (
                        <>
                          <div>
                            {`${farmGroup.selectedDelivery.firmName} - ${farmGroup.selectedDelivery.price.toFixed(2)} €`}
                          </div>
                          <div>{farmGroup.selectedDelivery.name}</div>
                        </>
                      ) : (
                        <>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_first_part',
                            })}
                          </div>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_second_part',
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="cart-delivery-info" />
                  )}
                  {!action && (
                    <div
                      className={
                        farmGroup.selectedDelivery
                          ? 'cart-delivery-button choosen'
                          : 'cart-delivery-button'
                      }
                    >
                      <Button
                        icon={
                          farmGroup.selectedDelivery ? (
                            <Icon name={'car-delivery-black'} />
                          ) : (
                            <Icon name={'car-delivery'} />
                          )
                        }
                        type={farmGroup.selectedDelivery ? 'text' : 'primary'}
                        label={
                          farmGroup.selectedDelivery
                            ? intl.formatMessage({
                                id: 'cart_summary.change_delivery',
                              })
                            : intl.formatMessage({
                                id: 'general.choose_delivery',
                              })
                        }
                        onClick={() => setIsCartDrawerOpen(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Drawer
        id={'delivery-drawer'}
        width={512}
        open={isCartDrawerOpen}
        onClose={noCloseDeliveryDrawer}
        title={intl.formatMessage({ id: 'general.choose_delivery' })}
        footer={
          <StyledDeliveryDrawerFooter>
            <Button
              className={'delivery-drawer-btn'}
              label={intl.formatMessage({ id: 'general.choose_delivery' })}
              type="primary"
              onClick={closeForm}
            />
          </StyledDeliveryDrawerFooter>
        }
      >
        <StyledDeliveryForm>
          <Form form={deliveryForm}>
            {farmProducts.map((farmProduct, index) => (
              <DeliveryForm
                deliveryForm={deliveryForm}
                farmProduct={farmProduct}
                key={index}
                registerSubmitCallback={registerSubmitCallback}
              />
            ))}
          </Form>
        </StyledDeliveryForm>
      </Drawer>
    </StyledGroupedFarmCart>
  )
}

export default GroupedFarmCart
