import styled from 'styled-components'

export const StyledPage = styled.div`
  border-radius: 8px;
  margin: 0 auto;

  .flex-end {
    display: flex;
    flex-grow: 1;
    justify-content: end;
  }
`
