import React, { CSSProperties, MouseEventHandler } from 'react'
import { StyledLabel } from './style'

export interface LabelProps {
  className?: string
  label: string | React.ReactNode
  extraBold?: boolean
  regular?: boolean
  marginClassName?: string
  center?: boolean
  end?: boolean
  onClick?: MouseEventHandler
  italic?: boolean
  styles?: CSSProperties
  color?: string
  bold?: boolean
  themeColor?: boolean
  clickable?: boolean
  title?: boolean
}

export const Label = ({
  className,
  label,
  extraBold,
  regular,
  marginClassName,
  center,
  end,
  onClick,
  italic,
  styles,
  color,
  bold,
  themeColor,
  clickable,
  title,
}: LabelProps) => {
  let completeClassName = ''

  if (className) {
    completeClassName += className
  }

  if (bold) {
    completeClassName += ' regular-bold'
  }

  if (extraBold) {
    completeClassName += ' extra-bold'
  }

  if (themeColor) {
    completeClassName += ' theme-color'
  }

  if (marginClassName) {
    completeClassName += ' ' + marginClassName
  }

  if (center) {
    completeClassName += ' center'
  }

  if (end) {
    completeClassName += ' end'
  }

  if (italic) {
    completeClassName += ' italic'
  }

  if (color) {
    completeClassName += ' ' + color
  }

  if (clickable) {
    completeClassName += ' clickable'
  }

  if (title) {
    completeClassName += ' title'
    if (!color) {
      completeClassName += ' black'
    }
  }

  return (
    <StyledLabel className={completeClassName} onClick={onClick} style={styles}>
      {label}
    </StyledLabel>
  )
}
