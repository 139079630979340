// #384654
// 928D01 second button

export const token = {
  brand01: '#384654',
  black: '#2A1A1A',
  brand02: '#928D01',
  discountTagBg: '#C9DA00',
  white: '#ffffff',
  almostWhite: '#FCFCFC',
  black2: '#000000',
  bgColorHover: '#384654',
  topPriceBgColor: '#D6353B',
  grey: '#838383',
  lightGrey: '#E9E9E9',
  silverGray: '#E0E0E0',
  mediumGray: '#D9D9D9',
  cloudGray: '#F1F4F7',
  containerBgColor: '#F8F8F8',
  borderBottom: '#E1E1E1',
  lightGrayBorder: '#EEEEEE',
  darkRed: '#9D0909',
  brightGrey: '#F4F4F4',
  mediumGrey: '#CBCBCB',
  menuBorderBottom: '#CCCCCC',
  backgroundOverlay: '#38465480',
  filterSelectBg: 'rgba(34, 197, 94, 1)',
  inputGrey: 'rgba(244, 244, 244);',
  cartWarningBackground: `rgba(131, 148, 161, 1)`,
  greyBackground: `rgba(245, 245, 245, 1)`,
  backgroundColorFarmButton: '#6D757D',
  mainBgColor: `rgba(255, 255, 255, 1)`,
  lightborder: `rgba(245, 245, 245, 1)`,
  summaryBackground: `rgba(248, 248, 248, 1)`,

  checkboxBorderColor: '#B0BABF',

  contentWidth: '1437px',
  mainFooterFontSize: '14px',

  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2);',

  profileBoxShadow: '0 2px 8px rgba(0, 0, 0, 0.1);',
  profileFontFamily: 'Arial, sans-serif;',
  profileBlack: '#333',
  profileName: '#666',
  profileHover: 'color: #1890ff',

  adressBlue: '#384654',
  adressGray: '#606b76',
  adressBoxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.075)',

  mainHeaderHeight: '81px',
  secondHeaderHeight: '81px',

  mainFooterHeight: '140px',

  //icons
  iconWidthSM: '14px',
  iconHeightSM: '14px',
  iconWidthLG: '70px',
  iconWidthMED: '24px',
  iconHeightMED: '24px',
  iconWidthCard: '33px',
  iconHeightCard: '33px',
  iconHeightFilter: '79px',
  iconWidthFilter: '79px',
  iconHeightAvarage: '19px',
  iconWidthAvarage: '19px',

  // SeedToken
  borderRadius: 30,
  colorPrimary: '#928D01',
  fontSize: 16,

  // MapToken
  colorBgBlur: 'transparent',
  colorBgContainer: 'transparent',
  colorBorder: '#DADADA',
  colorBorderSecondary: '#f0f0f0',
  colorText: '#2A1A1A',
  colorSplit: 'rgba(5, 5, 5, 0.06)',
  controlOutline: 'rgba(5, 145, 255, 0.1)',
}

export const adminToken = {
  brand01: '#384654',
  brand02: '#928D01',
  white: '#ffffff',
  blue: 'blue',
  fontText: "'Albert Sans', sans-serif",
  black: '#000000',
  contentWidth: '1437px',
  border: '#DADADA',
  filterBackground: '#F5F6F7',
  siderColor: '#C4C7CB',
  stepInactiveColor: '#888E98',
  stepArrowColor: '#292D32',
  actionCollor: '#93a2dd',

  inactiveColor: '#7C7C7C',
  tableSecondRowColor: '#F9FAFB',
  borderCheckbox: '#D5DAE1',
  activeCheckboxBackground: '#22C55E',
  activeCheckboxBorder: '#16A34A',
  disableCheckboxBackground: '#D5DAE1',
  disableCheckboxBorder: '#8896AB',
  inputAddonBg: '#EDEDED',

  categoryBorder: '#ccc',
  categoryVatBackground: 'rgb(145, 154, 173)',
  categoryContentBackground: '#fafafa',
  categoryHandlerBackground: '#d6d6d6',
  categoryActionBackground: '#5d78ff',

  // SeedToken
  borderRadius: 0,
  colorPrimary: '#4C4F54',
  fontSize: 16,
  fontFamily: 'Roboto',
  colorLink: '#4C4F54',

  // MapToken
  colorBorder: '#d9d9d9',
  colorBorderSecondary: '#f0f0f0',
  colorText: '#4C4F54',
  colorSplit: 'rgba(5, 5, 5, 0.06)',
  controlOutline: 'rgba(5, 145, 255, 0.1)',
}
