import React, { useState } from "react";

interface ICollapsibleProps {
    title: string;
    defaultCollapsed: boolean;
}

export const Collapsible = (props: React.PropsWithChildren<ICollapsibleProps>): JSX.Element => {
    const [collapse, setCollapse] = useState(props.defaultCollapsed);

    const symbol = collapse ? String.fromCharCode(9660) : String.fromCharCode(9650);
    return (
        <div className="collapsible">
            <div className="collapsible-title" title={props.title} onClick={() => setCollapse(!collapse)}>
                {props.title}
                <span>{symbol}</span>
            </div>
            <div className="collapsible-body" style={collapse ? { display: "none" } : { display: "block" }}>
                {props.children}
            </div>
        </div>
    );
};
