import { FarmData } from "types/FarmData";
import { FC, useState } from "react";
import { CloseOutlined } from '@ant-design/icons'
import { Carousel } from "ui";
import { FarmGaleriesWrapper } from "./style";
import { Image } from "antd";

interface Props {
    farm: FarmData | undefined
}

const Galeries: FC<Props> = ({ farm }) => {
    const [isOpenImageGallery, setIsOpenImageGallery] = useState(true)

    const handleCloseClick = () => {
        setIsOpenImageGallery(false)
    }

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <FarmGaleriesWrapper className="farm-gallery" galeryCount={farm?.images ? farm?.images.length : 0}>
            <div className="mobile-farm-galeries">
                {
                    farm?.images.map((galery,idx) => <div className="grid-item" key={idx}>
                        <Image
                            className={'carusel-banner-image'}
                            src={'/' + 'farm-image.png'}
                            alt={`carousel-image-`}
                        />
                    </div>)
                }
            </div>
            {isOpenImageGallery && (
                <div
                    className={'image-gallery-wrapper-wrapper'}
                    onClick={handleCloseClick}
                >
                    <div className={'image-gallery-wrapper'} onClick={stopPropagation}>
                        <Carousel productImages={farm?.images} arrows />
                        <div className={'close-image-btn'} onClick={handleCloseClick}>
                            <CloseOutlined />
                        </div>
                    </div>
                </div>
            )}

        </FarmGaleriesWrapper>
    );
}

export default Galeries
