import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { BannerProvider } from './public/context'
import { CategoryProvider, ProductProvider } from './public/context'
import { FarmProvider } from './public/context/FarmContext'
import { FilterProvider } from 'public/context/FilterContext'

import { CartProvider } from 'public/context/CartContext'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <FarmProvider>
    <ProductProvider>
      <CategoryProvider>
        <CartProvider>
          <BannerProvider>
            <FilterProvider>
              <App />
            </FilterProvider>
          </BannerProvider>
        </CartProvider>
      </CategoryProvider>
    </ProductProvider>
  </FarmProvider>
)
