export const print = (
  title: string,
  columnIndexToHide: number | null
): void => {
  const divContents = document.getElementsByClassName('ant-table-container')[0]
  if (!divContents) return

  const cloneDiv = divContents.cloneNode(true) as Element

  const allTh = cloneDiv.querySelectorAll('th')
  const allTr = cloneDiv.querySelectorAll('tr')

  if (columnIndexToHide) {
    const thToRemove = allTh[columnIndexToHide]
    if (thToRemove && thToRemove.parentNode) {
      thToRemove.parentNode.removeChild(thToRemove)
    }

    allTr.forEach((tr) => {
      const tds = tr.querySelectorAll('td')
      const tdToRemove = tds[columnIndexToHide]
      if (tdToRemove && tdToRemove.parentNode) {
        tdToRemove.parentNode.removeChild(tdToRemove)
      }
    })
  }

  const newTab = window.open('', '_blank')
  if (newTab) {
    newTab.document.open()
    newTab.document.write(`
        <html>
          <head>
            <title>${title}</title>
            <style>
              body { font-family: Arial, sans-serif; margin: 20px; }
            </style>
          </head>
          <body>
            ${cloneDiv.innerHTML}
          </body>
        </html>
      `)
    newTab.document.close()
    newTab.focus()
    newTab.print()
  }
}
