import { StyledCartCard } from './style'
import { useIntl } from 'react-intl'
import { ProductPrice, ProductUnityPrice } from 'utils/productCardHelpers'
import AmountSelector from 'ui/CartAmountSelector/CartAmountSelector'

import { CartProduct } from 'public/context/CartContext'
import { FallbackImage } from 'ui/UploadImage/fallBack/FallBackImage'

export interface CartCardProps {
  product: CartProduct
  action?: 'view'
}

export const CartCard = ({ product, action }: CartCardProps) => {
  const intl = useIntl()

  const productHasDiscount = !!(
    product.cartProduct?.storage_stock_discount_price &&
    product.cartProduct?.storage_stock_price &&
    product.cartProduct.storage_stock_discount_price <
      product.cartProduct.storage_stock_price
  )

  const storageStockPrice = Number(product?.cartProduct?.storage_stock_price)
  const storageStockDiscountPrice = Number(
    product?.cartProduct?.storage_stock_discount_price
  )

  return (
    <StyledCartCard className="cart-product">
      <div className={'cart-products-content'}>
        <div className={'cart-cell-1'}>
          <FallbackImage
            src={product.cartProduct?.image || ''}
            type={'ProductCard'}
          />
        </div>
        <div className={'cart-cell-2'}>
          <p className={'cart-product-farm-name'}>
            {product.cartProduct?.farm_name}
          </p>
          <p className={'cart-product-name'}>{product.cartProduct?.name} </p>
        </div>
        <div className={'cart-cell-3'}>
          <div className={'price-container'}>
            <ProductPrice
              product={product.cartProduct}
              productHasDiscount={productHasDiscount}
            />
            <ProductUnityPrice product={product.cartProduct} />
          </div>
        </div>
        <div className={'cart-cell-4'}>
          {action ? (
            <div className={'cart-product-amount-view'}>
              {'x' + product.amount}
            </div>
          ) : (
            <AmountSelector
              amountCart={product.amount}
              product={product.cartProduct}
              unitType={product.cartProduct?.storage_unit}
            />
          )}
        </div>
        <div className={'cart-cell-5'}>
          {storageStockPrice
            ? storageStockPrice * product.amount
            : storageStockDiscountPrice * product.amount}
          {' €'}
        </div>
      </div>

      <div className="mobile-cart-cells">
        <div className={'cart-cell-4'}>
          <AmountSelector
            amountCart={product.amount}
            product={product.cartProduct}
            unitType={product.cartProduct?.storage_unit}
          />
        </div>

        <div className={'cart-cell-5'}>
          <span className="label">
            {intl.formatMessage({ id: 'cart.product_total_price' })}:{' '}
          </span>
          <b>
            {storageStockPrice
              ? storageStockPrice * product.amount
              : storageStockDiscountPrice * product.amount}
            {' €'}
          </b>
        </div>
      </div>
    </StyledCartCard>
  )
}
