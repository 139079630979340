import { StyledTopCategoriesPage } from './style'
import UseQueryApiClient from '../../../utils/useQueryApiClient'
import { useState } from 'react'
import DefaultLayout from '../../components/DefaultLayout'
import { CategoryData } from '../../../types/CategoryData'
import TopCategorySelector from '../../../ui/Select/Selectors/TopCategorySelector'
import { Form, message } from 'antd'
import { useIntl } from 'react-intl'
import { ImageProps } from '../../../types/BannerData'

const TopCategories = () => {
  const [categories, setCategories] = useState<CategoryData[]>([])
  const [popular, setPopular] = useState<CategoryData[]>([])
  const [images, setImages] = useState<ImageProps[]>([])

  const intl = useIntl()

  const [form] = Form.useForm()

  const { appendData } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/update',
      method: 'POST',
    },
    onSuccess: () => {
      message.success(
        intl.formatMessage({ id: 'message.success_category_update' })
      )
    },
  })

  const { data: Images } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/popular/images',
    },
    onSuccess: (response) => {
      setImages(response)
    },
  })

  const { data } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/menu/desktop',
    },
    onSuccess: (response) => {
      setCategories(response)
    },
  })

  const { data: popularCategories } = UseQueryApiClient({
    request: {
      url: 'api/v2/categories/popular',
    },
    onSuccess: (response) => {
      setPopular(response)
    },
  })

  const handleCategoryChange = (position: number, categoryId: number) => {
    const updatedCategory = {
      top_category_position: position,
      category_id: categoryId,
    }
    appendData(updatedCategory)
  }

  const breadCrumbs = [
    {
      title: intl.formatMessage({ id: 'navigation.beginning' }),
    },
    {
      title: intl.formatMessage({ id: 'navigation.top_categories' }),
    },
  ]

  const orderedCategories = Array.from({ length: 6 }).map((_, index) => {
    const cat =
      popular.find((cat) => cat.top_category_position === index + 1) || null
    const img = images.find((img) => img.table_row_id === index + 1) || null

    return { cat: cat, img: img }
  })

  return (
    <DefaultLayout.PageLayout breadcrumbs={breadCrumbs}>
      <DefaultLayout.PageContent>
        <StyledTopCategoriesPage>
          <Form form={form}>
            <div className={'levels'}>
              {orderedCategories.map(({ cat, img }, index) => (
                <TopCategorySelector
                  key={index}
                  categories={categories}
                  name={`${index + 1}`}
                  label={`${index + 1}_category`}
                  initialValue={cat ? cat : undefined}
                  onCategoryChange={handleCategoryChange}
                  image={img ? img : undefined}
                />
              ))}
            </div>
          </Form>
        </StyledTopCategoriesPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TopCategories
