import { StyledCategoryTab } from './style'
import { MenuOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'
interface CategoryProps {
  name?: string
  vat?: number
  listeners: any
  handleRef: React.RefObject<HTMLDivElement>
  hasSubcategories?: boolean
  handleClick?: () => void
  handleOpen?: () => void
  handleAction?: () => void
  isOpen?: boolean
}

export const CategoryTab = ({
  name,
  vat,
  hasSubcategories,
  handleClick,
  handleOpen,
  handleAction,
  handleRef,
  listeners,
  isOpen = true,
}: CategoryProps) => {
  return (
    <StyledCategoryTab>
      <div className={'category-wrap'}>
        <div
          className={'handle'}
          onClick={handleClick}
          ref={handleRef}
          {...listeners}
        >
          <MenuOutlined />
        </div>
        <div className={'inside-wrap'}>
          <div className={'open-name'}>
            {hasSubcategories && (
              <div className={'open-close'} onClick={handleOpen}>
                {isOpen ? '-' : '+'}
              </div>
            )}
            <div className={'name'}>{name}</div>
          </div>
          <div className={'vat'}>{vat}</div>
        </div>
        <div className={'action'} onClick={handleAction}>
          <EditOutlined />
        </div>
      </div>
    </StyledCategoryTab>
  )
}
