import React from 'react'
import { Layout } from 'antd'
import { Label } from '../../../../ui'
import { useIntl } from 'react-intl'
import { StyledBlock, StyledSiderHeader, CustomMenu } from './style'
import { useLocation, useNavigate } from 'react-router-dom'

const { Sider: AntdSider } = Layout
const Sider = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  //todo urls should be in global config
  const menuItems = [
    {
      label: intl.formatMessage({ id: 'navigation.beginning' }),
      url: '/',
    },
    {
      label: intl.formatMessage({ id: 'navigation.transactions' }),
      url: '/admin/transactions',
    },
    {
      label: intl.formatMessage({ id: 'navigation.orders' }),
      url: '/admin/orders',
    },
    {
      label: intl.formatMessage({ id: 'navigation.contracts' }),
      url: '/admin/contracts',
    },
    {
      label: intl.formatMessage({ id: 'navigation.farms' }),
      url: '/admin/farms',
    },
    {
      label: intl.formatMessage({ id: 'navigation.farm_users' }),
      url: '/admin/farm/users',
    },
    {
      label: intl.formatMessage({ id: 'navigation.products' }),
      url: '/admin/products',
    },
  ]

  const menuItems2 = [
    {
      label: intl.formatMessage({ id: 'navigation.e_shop_email' }),
      url: '/admin/eshop-email',
    },
    {
      label: intl.formatMessage({ id: 'navigation.farm_email' }),
      url: '/admin/farm-email',
    },
    {
      label: intl.formatMessage({ id: 'navigation.qmark' }),
      url: '/admin/qmark',
    },
    {
      label: intl.formatMessage({ id: 'navigation.users' }),
      url: '/admin/users',
    },
    {
      label: intl.formatMessage({ id: 'navigation.roles' }),
      url: '/admin/permissions',
    },
    {
      label: intl.formatMessage({ id: 'navigation.banners' }),
      url: '/admin/banners',
    },
    {
      label: intl.formatMessage({ id: 'navigation.top_categories' }),
      url: '/admin/top-categories',
    },
    {
      label: intl.formatMessage({ id: 'navigation.category' }),
      url: '/admin/categories',
    },
  ]

  return (
    <AntdSider width={346}>
      <StyledSiderHeader>
        <img src="/logo_ng.png" width={69} height={66} alt="logo" />
        <Label
          color="white"
          label={intl.formatMessage({ id: 'general.project_name' })}
        />
      </StyledSiderHeader>
      <StyledBlock>
        <div className="role">todo org name</div>
        <CustomMenu>
          {menuItems.map((e, index) => (
            <div
              className={`custom-menu-item ${
                pathname.includes(e.url) && e.url !== '/' ? 'active' : ''
              }`}
              onClick={() => navigate(e.url)}
              key={index}
            >
              <div>Ik</div>
              <Label label={e.label} />
            </div>
          ))}
        </CustomMenu>
      </StyledBlock>
      <StyledBlock>
        <CustomMenu>
          {menuItems2.map((e, index) => (
            <div
              className={`custom-menu-item ${
                pathname.includes(e.url) ? 'active' : ''
              }`}
              onClick={() => navigate(e.url)}
              key={index + 100}
            >
              <div>Ik</div>
              <Label label={e.label} />
            </div>
          ))}
        </CustomMenu>
      </StyledBlock>
    </AntdSider>
  )
}

export default Sider
