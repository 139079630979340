import React, { useState } from 'react'
import { StyledFilter } from './style'
import { Form } from 'antd'
import { Button, Tag } from 'ui'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

interface FilterProps {
  children: React.ReactNode
  onFinish: (v: any) => void
  products?: boolean
  totalRecord?: number
}

enum FilterKeys {
  Pagination = 'pagination',
  Confirmed = 'confirmed',
}

const Filter = ({ children, onFinish, products, totalRecord }: FilterProps) => {
  const [filterProps, setFilterProps] = useState({})

  const intl = useIntl()
  const [form] = Form.useForm()

  const onFinishInner = (value: any) => {
    let parseValues = {}
    Object.entries(value).forEach((e: any) => {
      if (dayjs.isDayjs(e?.[1])) {
        parseValues = {
          ...parseValues,
          [e[0]]: e?.[1].format('DD.MM.YYYY'),
        }
        return
      }

      parseValues = {
        ...parseValues,
        [e[0]]: e[1],
      }
    })

    setFilterProps(parseValues)
    onFinish(parseValues)
  }

  const onClear = () => {
    onFinishInner({})
    form.resetFields()
  }

  const onClearInner = (key: string) => {
    form.setFieldValue(key, undefined)
    form.submit()
  }

  return (
    <StyledFilter>
      <div className="main-filter">
        <Form form={form} layout="vertical" onFinish={onFinishInner}>
          <div
            className="horizontal-form"
            style={products ? { justifyContent: 'space-between' } : {}}
          >
            {children}
          </div>
        </Form>
      </div>
      <div className="tag-list">
        {Object.entries(filterProps).map((e: any, index) => {
          if (e?.[1] === undefined || e?.[0] === FilterKeys.Pagination) {
            return <></>
          }

          if (dayjs.isDayjs(e?.[1])) {
            return (
              <Tag
                key={`tag-${index}`}
                closable
                bordered={false}
                onClose={() => onClearInner(e[0])}
              >
                {e?.[1].format('DD.MM.YYYY')}
              </Tag>
            )
          }

          if (e?.[0] === FilterKeys.Confirmed) {
            return (
              <Tag
                key={`tag-${index}`}
                closable
                bordered={false}
                onClose={() => onClearInner(e[0])}
              >
                {intl.formatMessage({ id: 'general.' + (e[1] ? 'yes' : 'no') })}
              </Tag>
            )
          }

          return (
            <Tag
              key={`tag-${index}`}
              closable
              bordered={false}
              onClose={() => onClearInner(e[0])}
            >
              {e?.[1]}
            </Tag>
          )
        })}
        {Object.entries(filterProps)
          .filter(([key]) => key !== FilterKeys.Pagination)
          .some(([, value]) => !!value) && (
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete_filter' })}
            onClick={onClear}
          />
        )}
        {Number.isInteger(totalRecord) && (
          <span className="record-count">
            {intl.formatMessage({ id: 'general.count_record' }) + totalRecord}
          </span>
        )}
      </div>
    </StyledFilter>
  )
}

export default Filter
