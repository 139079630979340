import styled from 'styled-components'

export const StyledMobileFilterFooter = styled.div`
    button {
        background-color: transparent;
        color: ${({ theme }) => theme.filterSelectBg};
        border: 1px solid ${({ theme }) => theme.filterSelectBg};
        height: 56px;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }
`