import React from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, CustomGrid, Input, TextArea } from 'ui'
import { Form } from 'antd'

const InformationTab = () => {
  const intl = useIntl()

  return (
    <Form layout="vertical">
      <Input label={intl.formatMessage({ id: 'product.name' })} name="name" />
      <CustomGrid inOneRow={3}>
        <Input
          label={intl.formatMessage({ id: 'product.group' })}
          name="group"
        />
        <Input
          label={intl.formatMessage({ id: 'product.category' })}
          name="category"
        />
        <Input label={intl.formatMessage({ id: 'product.type' })} name="type" />
      </CustomGrid>
      <CustomGrid>
        <Input
          label={intl.formatMessage({ id: 'product.measurement' })}
          name="measurement"
        />
        <Input
          label={intl.formatMessage({ id: 'product.measurement_step' })}
          name="measurement_step"
        />
        <Input
          label={intl.formatMessage({ id: 'product.product_amount' })}
          name="product_amount"
        />
        <Input
          label={intl.formatMessage({ id: 'product.measurement' })}
          name="measurement"
        />
        <Input
          label={intl.formatMessage({ id: 'product.minimal_amount' })}
          name="minimal_amount"
          addonAfter={intl.formatMessage({ id: 'product.available_for' })}
        />
        <Input
          label={intl.formatMessage({ id: 'product.maximum_amount' })}
          name="maximum_amount"
        />
        <Input
          label={intl.formatMessage({ id: 'product.price' })}
          name="price"
          addonAfter={intl.formatMessage({ id: 'product.$_amount' })}
        />
        <Input
          label={intl.formatMessage({ id: 'product.price_with_discount' })}
          name="price_with_discount"
          addonAfter={intl.formatMessage({ id: 'product.$_amount' })}
        />
      </CustomGrid>
      <Checkbox
        name="checked"
        options={[
          {
            label: intl.formatMessage({ id: 'product.recycled' }),
            value: 1,
            disabled: true,
          },
          {
            label: intl.formatMessage({ id: 'product.e_market' }),
            value: 2,
            disabled: true,
          },
          {
            label: intl.formatMessage({ id: 'product.visible' }),
            value: 3,
          },
        ]}
      />
      <TextArea
        label={intl.formatMessage({ id: 'product.item_description' })}
        name="item_description"
      />
      <Input
        label={intl.formatMessage({ id: 'product.realization_time' })}
        name="realization_time"
      />
    </Form>
  )
}

export default InformationTab
