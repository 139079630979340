import React from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'

const EShopEmailListPage = () => {
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: `/api/v2/listofemailsemarket`,
    },
  })

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.e_shop_email' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        {!!data.length &&
          data.map((item: any) => item.contact_email).join(', ')}
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default EShopEmailListPage
