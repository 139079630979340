import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../../../hooks/useAuth'

interface ProtectedRouteProps {
  children: React.ReactNode
  authenticated?: boolean
  requiresAdmin?: boolean
}

const ProtectedRoute = ({ children, requiresAdmin }: ProtectedRouteProps) => {
  const { user, isFetchingUser } = useAuth()

  if (isFetchingUser) {
    return null
  }

  if (!user?.authenticated) {
    return <Navigate to="/login" />
  }

  if (requiresAdmin && !user?.isAdmin) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

export default ProtectedRoute
