import { Fill, Icon, Style, Text } from "ol/style";
import { getAssets, IAssets } from "../novadugarsa";

import Pin from "../img/icons/pin.svg";
import ClusterSmall from "../img/icons/cluster-small.svg";
import ClusterLarge from "../img/icons/cluster-large.svg";
import ZoomIn from "../img/icons/zoom-in.svg";
import ZoomOut from "../img/icons/zoom-out.svg";

let assets: IAssets;

export const initializeAssets = () => {
    assets = getAssets();
    // this is just to import icons in asset folder, for developement use
    if (!assets) {
        assets = {
            clusterLarge: ClusterLarge,
            clusterSmall: ClusterSmall,
            pin: Pin,
            zoomIn: ZoomIn,
            zoomOut: ZoomOut
        };
    }
};

export const getSymbol = (): Style =>
    new Style({
        image: new Icon({ src: assets.pin })
    });

export const getClusterSymbol = (count: number): Style[] => {
    return [
        new Style({
            image: new Icon({
                src: count < 10 ? assets.clusterSmall : assets.clusterLarge,
                size: [36, 36]
            }),
            text: new Text({
                text: count.toString(),
                offsetX: -4,
                offsetY: -4,
                fill: new Fill({ color: "white" })
            })
        })
    ];
};
