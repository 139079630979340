import { FormInstance, Space } from 'antd'
import {
  FarmProduct,
  ParcelLocation,
  SelectedDelivery,
} from 'public/context/CartContext'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { FarmDeliveries } from 'types/FarmData'
import {
  Select,
  SelectOption,
  SelectOptionsValueStringNumber,
  Spinner,
} from 'ui'
import useQueryApiClient from 'utils/useQueryApiClient'
import { StyledDelivery } from '../style'

interface OmnivaDeliveryProps {
  farmProduct: FarmProduct
  selectedDelivery: FarmDeliveries
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >
  selectedDeliveryData: SelectedDelivery[] | null
  deliveryForm: FormInstance<any>
}

const OmnivaDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  farmProduct,
  selectedDeliveryData,
  deliveryForm,
}: OmnivaDeliveryProps) => {
  const intl = useIntl()
  const [selectedParishLocation, setSelectedParishLocation] = useState<
    string | null
  >(null)

  const {
    isLoading: parishLoading,
    data: parishData,
    refetch,
  } = useQueryApiClient({
    request: { url: 'api/v2/delivery/omnivaPickupGetCities' },
  })

  const { isLoading: parcelLoading, data: parcelData } = useQueryApiClient({
    request: {
      url: `api/v2/delivery/omnivaPickupGetLocations/${selectedParishLocation}`,
    },
    enabled: !!selectedParishLocation,
  })

  const handleParishChange = (parish: string) => {
    setSelectedParishLocation(parish)
    handleRefetch()
  }

  const handleRefetch = () => {
    deliveryForm.setFieldValue(`omnivaLocation.${farmProduct.farm_id}`, null)
    refetch()
  }

  const handleParcelChange = (parcelId: number) => {
    if (!selectedParishLocation) return

    const selected = parcelData?.find(
      (location: ParcelLocation) => location.id === parcelId
    )

    if (selected && selectedDelivery) {
      setSelectedDeliveryData([
        ...(selectedDeliveryData || []),
        {
          id: selected.id,
          selectedDeliveryId: selectedDelivery.id,
          city: selectedParishLocation,
          address: selected.address,
          firmName: intl.formatMessage({ id: 'process_purchase.omniva' }),
          name: selected.name,
          price: selectedDelivery.price,
        },
      ])
    }
  }

  const optionsParish: SelectOptionsValueStringNumber[] = parishData?.map(
    (parish: string) => ({
      label: parish,
      value: parish,
    })
  )

  const optionsParcel: SelectOptionsValueStringNumber[] = parcelData?.map(
    (location: ParcelLocation) => ({
      label: location.name,
      value: location.id,
    })
  )

  return (
    <StyledDelivery>
      <Spinner spinning={parishLoading}>
        <Space direction="vertical">
          {parishData && (
            <Select
              validations={'required'}
              initialValue={farmProduct.selectedDelivery?.city}
              name={`omnivaCity.${farmProduct.farm_id}`}
              size="middle"
              onChange={handleParishChange}
              children={
                optionsParish &&
                optionsParish.map((options, index) => {
                  return (
                    <SelectOption value={options.value} key={`${index} - opt`}>
                      {options.value}
                    </SelectOption>
                  )
                })
              }
            />
          )}

          <Spinner spinning={parcelLoading}>
            {(parcelData?.length > 0 || farmProduct.selectedDelivery) && (
              <Select
                validations={'required'}
                size="middle"
                name={`omnivaLocation.${farmProduct.farm_id}`}
                children={
                  optionsParcel &&
                  optionsParcel.map((options, index) => {
                    return (
                      <SelectOption
                        value={options.value}
                        key={`${index} - opt`}
                      >
                        {options.label}
                      </SelectOption>
                    )
                  })
                }
                value={parcelData?.name}
                onChange={handleParcelChange}
                initialValue={farmProduct.selectedDelivery?.name}
              />
            )}
          </Spinner>
        </Space>
      </Spinner>
    </StyledDelivery>
  )
}

export default OmnivaDelivery
