import { StyledMobileHeader } from './style'
import { useIntl } from 'react-intl'
import { useProductState } from 'public/context'
import { useLocation } from 'react-router-dom'
import MobileHeaderMap from './MobileHeaderMap/MobileHeaderMap'
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter'

const MobileHeader = () => {
  const intl = useIntl()
  const location = useLocation()
  const { productsCounted } = useProductState()
  const buyLatviaFoodTitle = intl.formatMessage({
    id: 'mobile_header.buy_latvia_food',
  })

  return (
    <StyledMobileHeader>
      <div id="mobile-header-container" className={'mobile-header-container'}>
        <div className="mobile-image-container">
          <a href={'/'}>
            <img src={'/mobile-header-image.png'} alt="logo" className="logo" />
          </a>
        </div>
        {location.pathname.includes('/farms') ? (
          <MobileHeaderMap />
        ) : (
          <div className={'buy-latvia-food'}>
            <div className={'title-section'}>
              <h3 dangerouslySetInnerHTML={{ __html: buyLatviaFoodTitle }}></h3>
            </div>

            <div className={'counter-section'}>
              <h3>
                <b>{productsCounted}</b>
              </h3>
              <h3>{intl.formatMessage({ id: 'general.products' })}</h3>
            </div>
          </div>
        )}

        <SearchAndFilter/>
      </div>
    </StyledMobileHeader>
  )
}

export default MobileHeader
