import styled from 'styled-components'

export const StyledFooter = styled.div`
  font-family: ${(props) => props.theme.fontText};
  min-height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 60px;
  background-color: ${(props) => props.theme.white};

  .footer-contacts {
    padding: 0 200px 0 200px;

    .footer-title {
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        padding: 0 0 24px 0;
        font-size: 24px;
        font-weight: 600;
      }
      .footer-icon-wrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }
    }

    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 50px 0 140px 0;
      text-wrap: nowrap;

      .footer-content-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 16px;
        gap: 10px;

        .footer-content {
          p {
            margin-top: 0;
          }
        }

        img {
          padding: 0 5px 0 0;
        }
      }
    }
  }

  .footer-image-wrapper {
    background-image: url('/footer-image.png');
    background-repeat: repeat-x;
    background-size: auto 85px;
    height: 85px;
    width: 100%;
  }

  .mobile-footer-image-wrapper {
    display: none;
  }

  .footer-copyright {
    text-align: left;
    padding: 54px 0 54px 240px;
    background-color: ${(props) => props.theme.brand01};
    color: ${(props) => props.theme.white};
  }

  @media only screen and (max-width: 1600px) {
    .footer-contacts {
      .footer-content-wrapper {
        flex-direction: column;
        text-wrap: wrap;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 30px;

    .footer-contacts {
      padding: 0 50px 0 50px;

      .footer-content-wrapper {
        margin: 30px 0 140px 0;
      }
    }

    .mobile-footer-image-wrapper {
      display: block;
      background-image: url('/mobile-footer-image.png');
      background-repeat: repeat-x;
      background-size: auto 41px;
      height: 41px;
      width: 100%;
    }

    .footer-copyright {
      text-align: center;
      padding: 0 0 0 0;
      background-color: transparent;
      color: ${(props) => props.theme.black};
    }

    .footer-image-wrapper {
      display: none;
    }
  }

  @media only screen and (max-width: 576px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 414px) {
    .footer-contacts {
      padding: 0 20px 0 20px;

      .footer-title {
        p {
          margin: 0;
          padding: 0 0 24px 0;
          font-size: 18px;
          font-weight: 600;
        }

        .footer-icon-wrapper {
          display: none;
        }
      }
    }
  }
`
