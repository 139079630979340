import { StyledBannerWrapper } from './style'
import { BannerProps } from '../../../../types/BannerData'
import { BannersGallery } from '../../../../ui/Carousel/BannersGallery/BannersGallery'

interface BannerReactProps {
  banners: BannerProps[] | undefined
  className?: string
}

const Banner = ({ banners, className }: BannerReactProps) => {
  return (
    <StyledBannerWrapper>
      {banners && banners.length > 0
        ? banners.map((singleBanner, index) => (
            <div
              key={index}
              className={
                className ? `banner-wrapper ${className}` : 'banner-wrapper'
              }
            >
              <BannersGallery
                bannerImages={singleBanner.images}
                banner={singleBanner}
              />
            </div>
          ))
        : null}
    </StyledBannerWrapper>
  )
}

export default Banner
