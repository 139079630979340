import React from 'react'
import { BannerProps, BannerResponse } from 'types/BannerData'

interface PayloadType {
  refetch: Function
  banners: BannerResponse
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH'
  payload: PayloadType
  isLoading: boolean
}

type RefetchAction = {
  type: 'REFETCH'
}

type Action = PayloadAction | RefetchAction

type Dispatch = (action: Action) => void

type State = {
  banners: BannerResponse
  refetch: Function
}

type BannerProviderProps = { children: React.ReactNode }

const BannerStateContext = React.createContext<State>(undefined!)
const BannerDispatchContext = React.createContext<Dispatch>(undefined!)

function bannerReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload

      return {
        ...state,
        ...payload,
      }
    }
    case 'REFETCH': {
      state.refetch()
      return {
        ...state,
      }
    }
  }
}

function BannerProvider({ children }: BannerProviderProps) {
  const initialState = {
    banners: { data: [] },
    refetch: () => null,
  }

  const [state, dispatch] = React.useReducer(bannerReducer, initialState)

  return (
    <BannerStateContext.Provider value={state}>
      <BannerDispatchContext.Provider value={dispatch}>
        {children}
      </BannerDispatchContext.Provider>
    </BannerStateContext.Provider>
  )
}

const useBannerState = () => React.useContext(BannerStateContext)
const useBannerDispatch = () => React.useContext(BannerDispatchContext)

export { BannerProvider, useBannerDispatch, useBannerState }
