import styled from 'styled-components'

export const StyledAllFarmsPage = styled('div')`
  .products-filters-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1482px;
    padding: 0 20px;
    margin: 0 auto;
    gap: 50px;

    .ant-spin-nested-loading{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .products-wrapper {
      width: 100%;

      .products {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);
        padding-top: 40px;
        justify-content: space-between;
        gap: 33px;
      }

      .pagination {
        display: flex;
        justify-content: center;
        padding: 50px 0;
      }
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    background-color: ${(props) => props.theme.brand01};
    border-color: ${(props) => props.theme.brand01};
  }
  
  .ant-pagination-item-active a,
  .ant-pagination-item-active:hover a{
    color: ${(props) => props.theme.white};
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      justify-content: center;
      .form-container {
        display: none;
      }

      .form-container {
        padding: 0 20px 0 20px;
      }
      .products-wrapper {
        padding: 0 20px 0 20px;
        .products {
          justify-content: center;
        }
      }
    }
  }
`
