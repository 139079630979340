import { Navigate, Route, Routes } from 'react-router-dom'
import TestPage2 from 'admin/pages/TestPage2'
import TestPage3 from 'admin/pages/TestPage3'
import TransactionListPage from 'admin/pages/Transactions/TransactionListPage'
import QualityMarkListPage from 'admin/pages/QualityMarks/QualidtyMarkListPage'
import EShopEmailListPage from '../../pages/EShopEmailListPage'
import FarmEmailListPage from '../../pages/FarmEmailListPage'
import ContractListPage from '../../pages/Contracts/ContractListPage'
import OrderListPage from '../../pages/Orders/OrderListPage'
import FarmListPage from '../../pages/Farms/FarmListPage'
import FarmUserListPage from '../../pages/Farms/FarmUserListPage'
import UserListPage from '../../pages/Users/UserListPage'
import PermisionListPage from '../../pages/Users/PermisionListPage'
import { Products } from '../../pages/Products'
import ProtectedRoute from '../../../public/components/DefaultLayout/ProtectedRoutes/ProtectedRoute'
import CategoryPage from '../../pages/Category'
import { BannersPage } from '../../pages/Banners'
import TopCategories from '../../pages/TopCategories'

const Router = () => {
  const renderRoutes = () => {
    return (
      <>
        <Route path="/form" element={<TestPage2 />} />
        <Route path="/b" element={<TestPage3 />} />
        <Route path="/transactions" element={<TransactionListPage />} />
        <Route path="/qmark" element={<QualityMarkListPage />} />
        <Route path="/eshop-email" element={<EShopEmailListPage />} />
        <Route path="/farm-email" element={<FarmEmailListPage />} />
        <Route path="/contracts" element={<ContractListPage />} />
        <Route path="/orders" element={<OrderListPage />} />
        <Route path="/farms" element={<FarmListPage />} />
        <Route path="/farm/users" element={<FarmUserListPage />} />
        <Route path="/users" element={<UserListPage />} />
        <Route path="/top-categories" element={<TopCategories />} />
        <Route path="/products" element={<Products />} />
        <Route path="/banners" element={<BannersPage />} />

        <Route
          path="/permissions"
          element={
            <ProtectedRoute children={<PermisionListPage />}></ProtectedRoute>
          }
        />
        <Route path="/categories" element={<CategoryPage />} />

        <Route path="/*" element={<TransactionListPage />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </>
    )
  }

  return <Routes>{renderRoutes()}</Routes>
}

export default Router
