import { StyledLoginForm } from './style'
import { Input } from '../../Input'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'
import { redirect, useNavigate } from 'react-router-dom'

export interface LoginProps {
  appendData?: (data: any) => void
}

export const LoginForm = ({ appendData }: LoginProps) => {
  const intl = useIntl()
  const APP_URL = window.runConfig.backendUrl

  const handleSubmit = (values: any) => {
    if (appendData) {
      appendData(values)
    }
  }

  const handleGoogleAuth = () => {
    window.location.href = `${APP_URL}/api/v2/login/google`
  }

  const [form] = StyledLoginForm.useForm()

  return (
    <StyledLoginForm form={form} onFinish={handleSubmit}>
      <div className={'inputs'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'login.title' })}
        </div>
        <div className={'email'}>
          {intl.formatMessage({ id: 'login.e_mail' })}
          <Input size={'large'} required name={'email'} validations={'email'} />
        </div>
        <div className={'pw'}>
          {intl.formatMessage({ id: 'login.password' })}
          <Input
            password
            size={'large'}
            required
            name={'password'}
            validations={'required'}
          />
        </div>

        <Button
          className={'cstm-btn'}
          label={intl.formatMessage({ id: 'login.login' })}
          size={'large'}
          htmlType={'submit'}
        />
        <Button
          label={intl.formatMessage({ id: 'login.use_google' })}
          className={'cstm-btn google'}
          icon={<img src={'/google.png'} />}
          onClick={handleGoogleAuth}
        />
        <div className={'no-acc-reg'}>
          {intl.formatMessage({ id: 'login.need_acc' })}
          <a href={'/register'}>
            {intl.formatMessage({ id: 'login.register' })}
          </a>
        </div>
      </div>
    </StyledLoginForm>
  )
}
