import { useEffect, useState } from 'react'

function useWindowScrollTop(maxScroll:number) {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY || document.documentElement.scrollTop
      if(maxScroll > scroll) {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [maxScroll])


  return scrollPosition
}

export default useWindowScrollTop
