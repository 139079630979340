import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, Checkbox, Table } from 'ui'
import useQueryApiClient from 'utils/useQueryApiClient'
import { Form } from 'antd'
import CreateNewModal from './CreateNewModal'

const QualityMarkListPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const intl = useIntl()
  const [form] = Form.useForm()

  const { data, refetch } = useQueryApiClient({
    request: {
      url: 'api/v2/roles',
    },
  })

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/permissions/:role_id/:permission_id',
      method: 'PATCH',
    },
  })

  const {} = useQueryApiClient({
    request: {
      url: 'api/v2/permissions/values',
    },
    onSuccess: (response) => {
      let parsed = {}
      response.forEach((e: any) => {
        parsed = {
          ...parsed,
          [e.role_id + '_' + e.permission_id]: true,
        }
      })

      form.setFieldsValue(parsed)
    },
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'permissions.permission_role' }),
      dataIndex: 'display_name',
    },
    ...data.map((e: any) => {
      return {
        title: e.display_name + ' (' + e.name + ')',
        dataIndex: 'id',
        render: (value: number) => (
          <Checkbox
            name={e.id + '_' + value}
            onChange={() =>
              appendData([], { role_id: e.id, permission_id: value })
            }
          />
        ),
      }
    }),
  ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.roles' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <div className="flex-end">
          <Button
            type="primary"
            label={intl.formatMessage({ id: 'general.create' })}
            onClick={() => setShowModal(true)}
          />
        </div>

        <Form form={form}>
          <Table
            url="/api/v2/permissions"
            columns={columns}
            disablePagination
          />
        </Form>
        <CreateNewModal
          showModal={showModal}
          onCloseModal={() => {
            setShowModal(false)
            refetch()
          }}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default QualityMarkListPage
