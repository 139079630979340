import styled from 'styled-components'

export const StyledCounter = styled.div`
  .counter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: ${(props) => props.theme.white};
    border-radius: 50px;
    border: 1px solid ${(props) => props.theme.mediumGrey};
    width: 188px;
    color: ${(props) => props.theme.black};
  }

  .counter-button {
    background: none;
    border: none;
    font-size: 18px;
    padding: 10px;
    cursor: pointer;
  }

  .counter-quantity {
    margin: 0 10px;
    font-size: 16px;
  }
`
