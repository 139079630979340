import { IAssets } from "../Map/interfaces";

export const demoAssets: IAssets = {
    clusterLarge: "assets/cluster-large.svg",
    clusterSmall: "assets/cluster-small.svg",
    pin: "assets/pin.svg",
    zoomIn: "assets/zoom-in.svg",
    zoomOut: "assets/zoom-out.svg"
};

export const webMercatorData = [
    { coordinates: [2766639.9251904557, 7791769.848745072], id: 34260 },
    { coordinates: [2787487.293710398, 7808174.115213437], id: 34261 },
    { coordinates: [2808528.0701589324, 7824124.2945144605], id: 34577 },
    { coordinates: [2825373.539783924, 7842196.887155311], id: 34578 },
    { coordinates: [2830361.6942212987, 7870258.510774103], id: 34897 },
    { coordinates: [2861116.310977122, 7887915.666529409], id: 34898 },
    { coordinates: [2894137.554939908, 7899123.490513969], id: 35217 },
    { coordinates: [2921652.9673427325, 7877569.803644715], id: 35537 },
    { coordinates: [3004512.0338960118, 7778677.120661982], id: 26257 },
    { coordinates: [2418086.4895289484, 7624681.254819259], id: 26577 },
    { coordinates: [2520057.535719579, 7667736.181870996], id: 26578 },
    { coordinates: [2743775.0884619, 7943334.415078824], id: 27857 },
    { coordinates: [2721231.13932379, 7894277.15414485], id: 28177 },
    { coordinates: [2955932.038161101, 7815214.525319861], id: 29137 },
    { coordinates: [2837686.3280555364, 7872446.002123275], id: 29457 },
    { coordinates: [3025735.120044952, 7717611.883293176], id: 29777 },
    { coordinates: [2703026.879612288, 7793554.675709828], id: 28497 },
    { coordinates: [2550813.575913364, 7823471.048432422], id: 30737 },
    { coordinates: [2945350.659129609, 7589154.194119844], id: 31697 },
    { coordinates: [2714955.1265802253, 7707625.865348622], id: 32017 },
    { coordinates: [2906680.130503858, 7595071.875295765], id: 32337 },
    { coordinates: [2637905.751077446, 7726702.833444106], id: 32657 },
    { coordinates: [2633827.1852781298, 7722243.354034518], id: 32673 },
    { coordinates: [2630286.769134587, 7721111.9773063315], id: 32674 },
    { coordinates: [2562288.7874350017, 7673347.347030363], id: 32675 },
    { coordinates: [2555144.9340207386, 7671760.675550387], id: 32676 },
    { coordinates: [2553676.465772288, 7673865.249309108], id: 32677 },
    { coordinates: [2552060.9264372406, 7674623.024436277], id: 32678 },
    { coordinates: [2579608.2061399342, 7759310.568164264], id: 33298 },
    { coordinates: [2952868.0710002333, 7540571.582777187], id: 33617 },
    { coordinates: [2706948.4930732204, 7764840.683587641], id: 34257 },
    { coordinates: [2732387.2990823886, 7779382.779389216], id: 34258 },
    { coordinates: [2745015.914109071, 7785737.151462434], id: 34259 },
    { coordinates: [3026653.708744664, 7535782.571394903], id: 121 },
    { coordinates: [3021888.2433467843, 7537086.361542303], id: 122 },
    { coordinates: [2957960.0235637003, 7584577.602800582], id: 123 },
    { coordinates: [3019886.2880351082, 7536065.675217085], id: 120 },
    { coordinates: [2948055.420669827, 7546605.578151992], id: 125 },
    { coordinates: [2911539.949792694, 7596475.079204083], id: 129 },
    { coordinates: [2917569.78310837, 7589502.328770343], id: 132 },
    { coordinates: [2929184.299080244, 7577189.958302563], id: 138 },
    { coordinates: [3008414.774528031, 7602029.350771986], id: 139 },
    { coordinates: [3006826.709484903, 7603468.431965834], id: 140 },
    { coordinates: [2972485.0640948312, 7603257.132268209], id: 141 },
    { coordinates: [2969304.9042390473, 7598895.219902605], id: 142 },
    { coordinates: [2918951.8855126887, 7638836.887762206], id: 144 },
    { coordinates: [2887135.794950218, 7642231.722838575], id: 146 },
    { coordinates: [2868706.2496555825, 7630558.182279394], id: 149 },
    { coordinates: [2915379.9211386824, 7685117.681407758], id: 152 },
    { coordinates: [2892923.1424339614, 7640773.627092463], id: 147 },
    { coordinates: [2823997.7337063304, 7927832.072131443], id: 153 },
    { coordinates: [2752966.552219659, 7743770.158326026], id: 154 },
    { coordinates: [2713838.224631022, 7867323.338201572], id: 156 },
    { coordinates: [2719367.998520196, 7856371.5743404105], id: 157 },
    { coordinates: [2784382.041648826, 7865954.683386335], id: 158 },
    { coordinates: [2808454.983210731, 7874000.8707819], id: 159 },
    { coordinates: [2774944.7231669156, 7900249.657706077], id: 160 },
    { coordinates: [2782468.076588223, 7940847.438312107], id: 162 },
    { coordinates: [2759238.417470146, 7930921.534442468], id: 163 },
    { coordinates: [2942229.4301051134, 7840619.040476276], id: 3 },
    { coordinates: [3023290.3241720335, 7864776.270004755], id: 8 },
    { coordinates: [3017879.5327323107, 7874473.608051937], id: 9 },
    { coordinates: [2981311.752941869, 7849430.159621222], id: 15 },
    { coordinates: [3005186.196017343, 7822768.8108173525], id: 20 },
    { coordinates: [2939632.3047389532, 7814691.057651131], id: 25 },
    { coordinates: [2941574.7206255724, 7813906.686471925], id: 35 },
    { coordinates: [3003422.4621513663, 7781134.531474295], id: 43 },
    { coordinates: [2414524.4717834177, 7760670.610091467], id: 49 },
    { coordinates: [2450041.6463736906, 7759140.4402731955], id: 51 },
    { coordinates: [2471734.7397056827, 7763389.526838032], id: 54 },
    { coordinates: [2370479.3884761757, 7712979.882511951], id: 56 },
    { coordinates: [2505057.8964968314, 7690733.384534126], id: 64 },
    { coordinates: [2465379.5446579237, 7732585.748630964], id: 68 },
    { coordinates: [2526564.061501228, 7706541.303018668], id: 71 },
    { coordinates: [2363916.5555969235, 7641492.857022207], id: 72 },
    { coordinates: [2356828.73910525, 7643493.23062889], id: 75 },
    { coordinates: [2380718.2908718637, 7624751.98678649], id: 76 },
    { coordinates: [2388095.517867256, 7620330.122492533], id: 78 },
    { coordinates: [2337366.044976406, 7611770.797189603], id: 81 },
    { coordinates: [2380296.039401416, 7614511.654833441], id: 89 },
    { coordinates: [2480761.847671915, 7779534.276089168], id: 97 },
    { coordinates: [2481478.95610209, 7701175.41091828], id: 102 },
    { coordinates: [2859948.315305236, 7661462.441950171], id: 106 },
    { coordinates: [2824684.002190564, 7619221.939373839], id: 107 },
    { coordinates: [2823370.096469537, 7629123.320110611], id: 108 },
    { coordinates: [2930984.9741414594, 7533262.556179803], id: 111 },
    { coordinates: [2928749.433471777, 7543882.821381535], id: 112 },
    { coordinates: [2932558.534103923, 7538586.345741116], id: 113 },
    { coordinates: [2977130.0810954077, 7497613.974073971], id: 114 },
    { coordinates: [2979393.1925690104, 7535771.579610087], id: 116 },
    { coordinates: [2992305.851815315, 7537156.4245391], id: 117 },
    { coordinates: [3032347.220488399, 7525232.219046209], id: 119 },
    { coordinates: [2537529.946297755, 7864803.228470964], id: 25055 },
    { coordinates: [2476730.595122726, 7804791.418850645], id: 25057 },
    { coordinates: [2999700.327491916, 7702585.213086489], id: 334 },
    { coordinates: [2992772.082981864, 7690203.808686981], id: 337 },
    { coordinates: [2990192.269530473, 7687260.98839072], id: 338 },
    { coordinates: [3011595.5060054944, 7605789.718262694], id: 340 },
    { coordinates: [2859065.3821091205, 7616137.200898221], id: 11841 },
    { coordinates: [3063500.1813370036, 7789402.905799694], id: 12164 },
    { coordinates: [2489155.568765944, 7760139.982216149], id: 12165 },
    { coordinates: [2801294.420876179, 7913791.769023557], id: 24983 },
    { coordinates: [2481000.33286393, 7887126.437724714], id: 24979 },
    { coordinates: [2478214.630879668, 7778869.226450851], id: 24982 },
    { coordinates: [2447404.083645399, 7826706.156062672], id: 24984 },
    { coordinates: [2915644.2182936724, 7722980.3581397645], id: 24985 },
    { coordinates: [2867673.1414944963, 7690975.263376726], id: 24986 },
    { coordinates: [2755554.405804641, 7942440.55493677], id: 168 },
    { coordinates: [2858023.4566156007, 7807115.008992375], id: 164 },
    { coordinates: [2736853.5372112677, 7709084.88785983], id: 181 },
    { coordinates: [2807565.466291001, 7639203.465954272], id: 190 },
    { coordinates: [3129360.3344131643, 7593982.365846704], id: 347 },
    { coordinates: [3118084.082979626, 7600387.883366774], id: 348 },
    { coordinates: [3037896.8882505936, 7719975.319150745], id: 350 },
    { coordinates: [2869132.2296130927, 7914951.6697886605], id: 352 },
    { coordinates: [2918376.3988533504, 7725057.044249912], id: 12481 },
    { coordinates: [2446754.9158527735, 7761991.005048905], id: 12801 },
    { coordinates: [2405174.488882049, 7632178.494149988], id: 12817 },
    { coordinates: [2564749.620672182, 7792493.277493651], id: 13461 },
    { coordinates: [2520034.726994419, 7733277.041247903], id: 13467 },
    { coordinates: [2862333.7182273003, 7889486.765259581], id: 24989 },
    { coordinates: [2434254.0296915937, 7873837.925145175], id: 24987 },
    { coordinates: [2436988.6664626575, 7876422.967804491], id: 24988 },
    { coordinates: [2771174.9798657238, 7818726.206228818], id: 24990 },
    { coordinates: [3002191.859563835, 7771280.838097154], id: 24991 },
    { coordinates: [2773742.100537112, 7685158.345335318], id: 219 },
    { coordinates: [2784455.8845140254, 7673341.365167302], id: 201 },
    { coordinates: [2846520.9051224766, 7700680.972480395], id: 204 },
    { coordinates: [2748459.117405079, 7636395.683641777], id: 220 },
    { coordinates: [2905729.76927967, 7905407.104859159], id: 355 },
    { coordinates: [2903336.147024712, 7897917.962633736], id: 360 },
    { coordinates: [2895206.7496034866, 7898344.799052515], id: 369 },
    { coordinates: [2915783.6057814173, 7894999.316874856], id: 371 },
    { coordinates: [2919918.191072055, 7882789.26197108], id: 372 },
    { coordinates: [2902652.948134696, 7899244.110321744], id: 374 },
    { coordinates: [2553450.1563504566, 7765336.289803852], id: 13469 },
    { coordinates: [2536468.9466586183, 7765515.518789348], id: 13468 },
    { coordinates: [2532483.6690340997, 7730482.205287305], id: 13470 },
    { coordinates: [2720825.5902244374, 7716268.52468809], id: 13471 },
    { coordinates: [3049826.6098946, 7644171.626069848], id: 24992 },
    { coordinates: [3076510.660679565, 7634664.40770454], id: 24993 },
    { coordinates: [3088703.9203985883, 7632793.808778544], id: 24994 },
    { coordinates: [3120786.1871299585, 7604131.915273672], id: 24995 },
    { coordinates: [2878438.137186692, 7936174.894313242], id: 24996 },
    { coordinates: [2891730.9838719876, 7897800.15002765], id: 24997 },
    { coordinates: [2881387.4931921395, 7888145.435194091], id: 24998 },
    { coordinates: [2830601.8199820896, 7687621.855218523], id: 225 },
    { coordinates: [2830065.665082262, 7686676.407142806], id: 224 },
    { coordinates: [2891552.448019016, 7849049.763537311], id: 380 },
    { coordinates: [2883945.5575512326, 7845302.998862252], id: 387 },
    { coordinates: [2883459.8386059594, 7844330.119101681], id: 388 },
    { coordinates: [2673688.929631059, 7692931.9188656835], id: 13778 },
    { coordinates: [2729706.2058365215, 7706658.880718596], id: 13472 },
    { coordinates: [2728586.9849631954, 7709243.865830115], id: 13777 },
    { coordinates: [2576559.345785744, 7682707.008205992], id: 13781 },
    { coordinates: [2538588.0343488585, 7670289.295976214], id: 13783 },
    { coordinates: [2860743.3067920515, 7887629.9642314175], id: 24999 },
    { coordinates: [2891980.320718114, 7892060.351145002], id: 25001 },
    { coordinates: [2912186.5814841883, 7878968.217488376], id: 25002 },
    { coordinates: [2890083.817428039, 7847119.829610593], id: 25003 },
    { coordinates: [2360620.5881763264, 7736797.0137652755], id: 25004 },
    { coordinates: [2583702.4757400923, 7634357.59005537], id: 25005 },
    { coordinates: [2789006.140956843, 7682281.844216723], id: 226 },
    { coordinates: [2787733.9979320555, 7683504.07133823], id: 227 },
    { coordinates: [2939157.1390616735, 7864935.460748711], id: 396 },
    { coordinates: [2784535.6718719997, 7944888.284082158], id: 397 },
    { coordinates: [2742735.223618437, 7785825.708177708], id: 399 },
    { coordinates: [2786622.004643381, 7658477.371097922], id: 407 },
    { coordinates: [2565011.839715128, 7669589.496647888], id: 13785 },
    { coordinates: [2575233.179788383, 7648205.819400164], id: 13788 },
    { coordinates: [2568528.881684049, 7655448.129245857], id: 13790 },
    { coordinates: [2575827.997372334, 7643260.805780111], id: 13794 },
    { coordinates: [2625708.6569200414, 7685970.171228066], id: 13795 },
    { coordinates: [2565225.546147277, 7783053.164520512], id: 25007 },
    { coordinates: [2599948.4520968385, 7769237.677968357], id: 25008 },
    { coordinates: [2585137.3934801384, 7795249.652377453], id: 25009 },
    { coordinates: [2575634.946469128, 7813052.024540487], id: 25010 },
    { coordinates: [2449446.3960311315, 7822162.861768969], id: 279 },
    { coordinates: [2812082.9736965457, 7655336.805484533], id: 228 },
    { coordinates: [2460974.4285377767, 7822710.868686839], id: 280 },
    { coordinates: [2458279.157012476, 7846590.030486793], id: 281 },
    { coordinates: [2384045.759844105, 7803053.701588827], id: 412 },
    { coordinates: [2854915.1167548182, 7638621.286063518], id: 416 },
    { coordinates: [2926321.0251570875, 7580691.583225808], id: 418 },
    { coordinates: [2836930.7339829216, 7682558.775344886], id: 419 },
    { coordinates: [2633908.1144613917, 7732801.198751246], id: 13797 },
    { coordinates: [2657773.083884968, 7688333.9895377075], id: 13799 },
    { coordinates: [2460779.5873955595, 7654435.36235117], id: 25012 },
    { coordinates: [2518914.9843708565, 7878929.711013962], id: 25013 },
    { coordinates: [3072842.4175328207, 7733695.929189747], id: 25014 },
    { coordinates: [2410748.753171509, 7694955.668243576], id: 25015 },
    { coordinates: [2893998.377324677, 7898915.485490674], id: 25016 },
    { coordinates: [2466133.123126544, 7833359.995450247], id: 284 },
    { coordinates: [2471469.515521746, 7821152.234794999], id: 283 },
    { coordinates: [2525870.002098769, 7848753.902723286], id: 286 },
    { coordinates: [2510270.5157653955, 7858681.712528778], id: 287 },
    { coordinates: [2761005.9926042357, 7730545.494096446], id: 420 },
    { coordinates: [2705179.1455388186, 7641978.285081319], id: 421 },
    { coordinates: [2719705.817313018, 7695072.124957222], id: 424 },
    { coordinates: [2384262.982056622, 7724985.552305104], id: 961 },
    { coordinates: [2601879.0000849427, 7656082.394059327], id: 13807 },
    { coordinates: [2554466.3686721893, 7722861.185743105], id: 13805 },
    { coordinates: [2577799.166087948, 7786788.299574468], id: 13806 },
    { coordinates: [2562522.801292303, 7702166.033253231], id: 25018 },
    { coordinates: [2726185.933041536, 7894318.036305291], id: 25019 },
    { coordinates: [2470575.0625920803, 7664535.30697088], id: 25017 },
    { coordinates: [2552046.576116698, 7710112.533336999], id: 25021 },
    { coordinates: [2480271.29729732, 7785054.885653955], id: 25022 },
    { coordinates: [2522026.8453410226, 7851532.611441484], id: 289 },
    { coordinates: [2502018.612881857, 7885730.06966858], id: 288 },
    { coordinates: [2515000.8867055485, 7888977.342493943], id: 290 },
    { coordinates: [2436180.0586305563, 7866403.989550617], id: 294 },
    { coordinates: [2376384.011029516, 7708877.866368404], id: 962 },
    { coordinates: [2898744.05757761, 7882173.29708856], id: 1602 },
    { coordinates: [2614684.1562835556, 7760503.3402902195], id: 2245 },
    { coordinates: [2662757.03756503, 7759242.756931386], id: 13810 },
    { coordinates: [2566365.6281252755, 7756440.103577084], id: 13809 },
    { coordinates: [2549425.191808523, 7663629.01256195], id: 13811 },
    { coordinates: [2561953.3998355274, 7678508.236668698], id: 25023 },
    { coordinates: [2806644.614668437, 7636315.728075026], id: 25024 },
    { coordinates: [2742778.3803028264, 7913140.4172648415], id: 25025 },
    { coordinates: [2489233.443465202, 7864331.324075327], id: 297 },
    { coordinates: [2446898.143933997, 7880035.54641648], id: 295 },
    { coordinates: [2458011.4348036763, 7885793.626504815], id: 296 },
    { coordinates: [2526574.872849426, 7847159.2316713985], id: 298 },
    { coordinates: [2957345.083151869, 7721740.85690657], id: 3521 },
    { coordinates: [2719024.307288489, 7888581.570109216], id: 5121 },
    { coordinates: [2772411.031669782, 7671800.504621157], id: 5765 },
    { coordinates: [2632976.2674819273, 7684266.724354999], id: 13815 },
    { coordinates: [2630271.141535986, 7642667.80068371], id: 13816 },
    { coordinates: [2606323.9565822915, 7633791.808172484], id: 13820 },
    { coordinates: [2778931.0952913165, 7836892.803001262], id: 25028 },
    { coordinates: [2727437.7475403543, 7949103.31614122], id: 25026 },
    { coordinates: [2800735.571693374, 7785936.509345451], id: 25027 },
    { coordinates: [2453645.246156825, 7817516.571650973], id: 25029 },
    { coordinates: [2782230.105167656, 7887950.635636836], id: 25030 },
    { coordinates: [2380107.8292736565, 7761315.8742288975], id: 302 },
    { coordinates: [2484069.648375236, 7849029.181317085], id: 299 },
    { coordinates: [2491021.543027529, 7834017.864049945], id: 301 },
    { coordinates: [2384005.730785677, 7811283.040991965], id: 303 },
    { coordinates: [2434527.643886382, 7819437.506271564], id: 6084 },
    { coordinates: [2411363.5239946246, 7850313.971693273], id: 6086 },
    { coordinates: [2754119.682187886, 7706680.035132823], id: 5766 },
    { coordinates: [2550441.2646504533, 7717773.01698163], id: 13826 },
    { coordinates: [2561560.8236671225, 7685670.09203159], id: 13825 },
    { coordinates: [2837803.187344677, 7642869.501755641], id: 14098 },
    { coordinates: [2469528.744477061, 7799157.99462255], id: 14417 },
    { coordinates: [2852437.8287754017, 7738190.608403197], id: 25033 },
    { coordinates: [2891490.170039913, 7848752.923813113], id: 25031 },
    { coordinates: [2974820.4473978244, 7829642.894214771], id: 25032 },
    { coordinates: [2568268.2130726655, 7674122.230627095], id: 25034 },
    { coordinates: [2860946.084545539, 7905739.000228303], id: 25035 },
    { coordinates: [2406246.8514188705, 7854425.788637543], id: 305 },
    { coordinates: [2423624.392944764, 7838282.731077801], id: 304 },
    { coordinates: [2423656.382755715, 7856849.3618675405], id: 306 },
    { coordinates: [2433455.8563235584, 7825712.1184323095], id: 307 },
    { coordinates: [2454495.7839565803, 7782033.225037056], id: 6090 },
    { coordinates: [2982143.976123411, 7502907.648281148], id: 7042 },
    { coordinates: [2909815.5891722227, 7664840.247762107], id: 7043 },
    { coordinates: [2987653.275948137, 7510693.206890134], id: 7044 },
    { coordinates: [2893369.6544352486, 7849484.43778629], id: 7698 },
    { coordinates: [2886969.2561193043, 7845504.629481525], id: 7716 },
    { coordinates: [2437452.699072214, 7867621.27266822], id: 14436 },
    { coordinates: [2863285.428261604, 7753407.609270617], id: 14437 },
    { coordinates: [2465183.8953443603, 7790153.945141075], id: 14433 },
    { coordinates: [2965135.095399411, 7575678.961410876], id: 16017 },
    { coordinates: [2336911.317091086, 7624202.849747784], id: 16018 },
    { coordinates: [2794750.403128073, 7660583.093032337], id: 25036 },
    { coordinates: [3018861.6168935983, 7575026.312094197], id: 25038 },
    { coordinates: [2873753.197325568, 7579291.507168895], id: 25039 },
    { coordinates: [3004492.130663429, 7584735.518473727], id: 25040 },
    { coordinates: [2510344.3853458725, 7771265.886361502], id: 310 },
    { coordinates: [2473061.8366799788, 7803327.331417442], id: 308 },
    { coordinates: [2520709.220047719, 7804061.19418205], id: 309 },
    { coordinates: [2501861.890949648, 7775712.208242833], id: 311 },
    { coordinates: [2885573.934487611, 7845921.304404055], id: 7717 },
    { coordinates: [2865468.228562071, 7889493.575442068], id: 7719 },
    { coordinates: [2356882.6634528437, 7735889.04456373], id: 8009 },
    { coordinates: [3059055.3231324516, 7732217.512215002], id: 8012 },
    { coordinates: [3078528.8760929606, 7631865.993899849], id: 8013 },
    { coordinates: [2563131.881845508, 7838506.800432212], id: 16337 },
    { coordinates: [2449652.360026674, 7759907.812810794], id: 16978 },
    { coordinates: [3008596.7976960763, 7603307.814006727], id: 20177 },
    { coordinates: [2853706.1047248244, 7930066.606577689], id: 20818 },
    { coordinates: [2694125.2895046733, 7732729.736824619], id: 20819 },
    { coordinates: [2717543.14363819, 7713796.652596361], id: 21137 },
    { coordinates: [3031131.2045659334, 7787273.097835442], id: 25042 },
    { coordinates: [3015114.201149981, 7817879.894198792], id: 25041 },
    { coordinates: [3036556.5286620436, 7608901.611173708], id: 25043 },
    { coordinates: [2858356.285640767, 7887879.102083459], id: 25044 },
    { coordinates: [2888947.9769619573, 7843166.865502213], id: 25045 },
    { coordinates: [2476668.538201265, 7804935.355925314], id: 313 },
    { coordinates: [2487188.443460825, 7785875.79919335], id: 312 },
    { coordinates: [2552360.5701436433, 7835931.078822455], id: 315 },
    { coordinates: [3081209.338080261, 7828749.578983023], id: 8321 },
    { coordinates: [2907089.8647209033, 7593595.494083028], id: 8641 },
    { coordinates: [2352227.892373255, 7595986.642739515], id: 9286 },
    { coordinates: [2493407.447172944, 7741101.826481704], id: 9290 },
    { coordinates: [2895899.4338449547, 7657844.203301054], id: 22417 },
    { coordinates: [2718693.4032782605, 7843470.1567419255], id: 22737 },
    { coordinates: [2839501.36133754, 7873363.719779291], id: 22738 },
    { coordinates: [2793344.2685564174, 7883234.704371405], id: 22753 },
    { coordinates: [2428810.1545731, 7785885.6272159945], id: 25048 },
    { coordinates: [2899482.336162122, 7905138.539939573], id: 25046 },
    { coordinates: [2811497.872459736, 7720990.626187836], id: 25047 },
    { coordinates: [3060242.627524371, 7729304.991221944], id: 25049 },
    { coordinates: [2589326.005581584, 7777911.863628084], id: 25050 },
    { coordinates: [2535353.91963471, 7845118.4774629185], id: 316 },
    { coordinates: [2461667.4671084406, 7780949.105962404], id: 317 },
    { coordinates: [2430772.9676766475, 7794973.875871962], id: 318 },
    { coordinates: [2544340.5559436297, 7854366.946024636], id: 319 },
    { coordinates: [2991416.2761308867, 7539031.987142236], id: 9291 },
    { coordinates: [2853153.9385768916, 7655498.716788094], id: 9922 },
    { coordinates: [3030298.6847182084, 7533421.019641186], id: 15700 },
    { coordinates: [2435704.6226781094, 7773489.669086809], id: 23058 },
    { coordinates: [2903618.368207124, 7896824.080126077], id: 23377 },
    { coordinates: [2701109.2782504032, 7787435.313740002], id: 22755 },
    { coordinates: [2916751.492732431, 7897163.336405938], id: 23378 },
    { coordinates: [2618184.5222169054, 7665464.456323599], id: 25051 },
    { coordinates: [2619336.662244504, 7643124.815631911], id: 25052 },
    { coordinates: [2465162.01498955, 7892400.1264212085], id: 321 },
    { coordinates: [3040889.6812312906, 7665492.531179285], id: 322 },
    { coordinates: [2917751.74497737, 7695081.706541814], id: 324 },
    { coordinates: [3022367.589693648, 7712182.78521472], id: 25000 },
    { coordinates: [2646143.521984903, 7754362.297916472], id: 44500 },
    { coordinates: [2974340.9024275877, 7591205.690192227], id: 24337 },
    { coordinates: [3005034.0631288467, 7880617.11560899], id: 23697 },
    { coordinates: [2716142.7820744803, 7713303.373753879], id: 24017 },
    { coordinates: [2503366.123815425, 7688700.1848868085], id: 24977 },
    { coordinates: [2458774.6906862436, 7696387.699852931], id: 24978 },
    { coordinates: [2605629.826570239, 7657543.150488247], id: 25053 },
    { coordinates: [2355412.3885922832, 7630986.21577348], id: 25054 },
    { coordinates: [2956688.0339336465, 7720676.455827948], id: 325 },
    { coordinates: [2970705.7562620444, 7735686.38790798], id: 326 },
    { coordinates: [2951228.7457518824, 7744401.4077924425], id: 327 },
    { coordinates: [3079177.2985733445, 7815800.570500514], id: 328 },
    { coordinates: [3073841.9754726524, 7817272.205985062], id: 329 },
    { coordinates: [2930188.732783109, 7856421.283587657], id: 46100 },
    { coordinates: [3070743.7008183417, 7774294.3585246885], id: 47700 },
    { coordinates: [2852740.288965091, 7651092.074074142], id: 10242 },
    { coordinates: [2999066.2035570415, 7667762.508752447], id: 10246 },
    { coordinates: [2886776.50897975, 7847401.448626651], id: 10563 },
    { coordinates: [2620869.3557414957, 7757111.444764764], id: 44499 },
    { coordinates: [2497127.9456206164, 7908332.914437305], id: 44826 },
    { coordinates: [2356958.5526700895, 7735877.9520822], id: 46418 },
    { coordinates: [2785642.9931261986, 7940835.587713944], id: 47057 },
    { coordinates: [2781436.820753137, 7678531.416198359], id: 47058 },
    { coordinates: [2860543.382740868, 7660779.437294219], id: 47059 },
    { coordinates: [2443337.217302212, 7743840.663592184], id: 47060 },
    { coordinates: [2580651.0148455943, 7690592.562559346], id: 47061 },
    { coordinates: [2938708.273340716, 7866913.726296094], id: 35857 },
    { coordinates: [2960308.42951563, 7852845.710216925], id: 36177 },
    { coordinates: [2596088.165626543, 7754518.584553304], id: 44501 },
    { coordinates: [2515991.2559475023, 7916521.996332639], id: 44827 },
    { coordinates: [3088759.612697923, 7632838.078359434], id: 47063 },
    { coordinates: [2957304.2420648425, 7721896.851370598], id: 47064 },
    { coordinates: [2888053.373635074, 7696234.455992071], id: 47065 },
    { coordinates: [2898173.560755479, 7886124.533690962], id: 47066 },
    { coordinates: [2970793.9040267295, 7859693.488064171], id: 47062 },
    { coordinates: [2800020.845661106, 7780760.821524303], id: 47067 },
    { coordinates: [2579301.0995175825, 7754253.677348307], id: 44502 },
    { coordinates: [2566225.8459832417, 7756048.89987213], id: 44503 },
    { coordinates: [2522566.453015072, 7876983.914389943], id: 44828 },
    { coordinates: [2476180.9135012752, 7803618.611966129], id: 47073 },
    { coordinates: [2914708.108203186, 7779412.20365197], id: 47074 },
    { coordinates: [2888108.6993652093, 7696270.397495733], id: 47377 },
    { coordinates: [3034266.418730007, 7786974.6375829065], id: 47697 },
    { coordinates: [2787925.413295901, 7682796.304551164], id: 47069 },
    { coordinates: [3060910.516256397, 7809193.172241206], id: 47698 },
    { coordinates: [2971658.6980698504, 7871229.157247663], id: 36178 },
    { coordinates: [2725257.135195517, 7690869.618036579], id: 36179 },
    { coordinates: [2536076.022876097, 7767088.322501654], id: 44504 },
    { coordinates: [2548357.792125654, 7853357.718625125], id: 44829 },
    { coordinates: [3080931.771830335, 7797933.833467802], id: 47699 },
    { coordinates: [3077324.797643777, 7749197.916522486], id: 47701 },
    { coordinates: [3060606.1686886353, 7728639.141733716], id: 47702 },
    { coordinates: [3080586.124848267, 7716759.994965965], id: 47703 },
    { coordinates: [3086177.92545348, 7687482.337434711], id: 47704 },
    { coordinates: [2683574.4344813023, 7749456.485117357], id: 36497 },
    { coordinates: [2512700.0953468904, 7768809.450894195], id: 44505 },
    { coordinates: [2574077.5436456376, 7829220.779109424], id: 44830 },
    { coordinates: [3086508.0990546774, 7668214.565734211], id: 47705 },
    { coordinates: [3096669.6761834743, 7646840.379039968], id: 47706 },
    { coordinates: [3109262.6935413615, 7639538.035815261], id: 47707 },
    { coordinates: [3130618.1133405203, 7635994.545131002], id: 47708 },
    { coordinates: [3128194.3541435436, 7599814.6052310765], id: 47709 },
    { coordinates: [3129399.053571986, 7593912.934409475], id: 47710 },
    { coordinates: [2481611.2332596676, 7774258.31851965], id: 44506 },
    { coordinates: [2446625.409666988, 7754168.716684716], id: 44507 },
    { coordinates: [2585904.126468814, 7793042.925048288], id: 44831 },
    { coordinates: [3115557.0315649095, 7611370.391329968], id: 47711 },
    { coordinates: [3084260.169002372, 7613020.503296608], id: 47712 },
    { coordinates: [3073294.9208234837, 7595870.318511173], id: 47713 },
    { coordinates: [3065865.6806651456, 7577311.446379591], id: 47714 },
    { coordinates: [3073412.4742799024, 7552407.430065253], id: 47715 },
    { coordinates: [3065088.3366118693, 7533374.194065056], id: 47716 },
    { coordinates: [3055274.7443349506, 7518125.621734835], id: 47717 },
    { coordinates: [2344894.0639647786, 7572577.877480195], id: 39697 },
    { coordinates: [2443014.2053851495, 7727713.055766104], id: 44508 },
    { coordinates: [2405027.431084838, 7702884.650653445], id: 44509 },
    { coordinates: [2614457.448304288, 7767240.019746761], id: 44832 },
    { coordinates: [3023592.660710782, 7537955.608443788], id: 47718 },
    { coordinates: [3002356.6866013664, 7531497.319326786], id: 47719 },
    { coordinates: [2987689.8984897425, 7510693.257037501], id: 47720 },
    { coordinates: [2967820.5938431774, 7503012.880299424], id: 47722 },
    { coordinates: [2946126.317518902, 7501737.183293169], id: 47723 },
    { coordinates: [2933382.5736014014, 7515400.375018437], id: 47724 },
    { coordinates: [2936231.1280367225, 7539208.717196442], id: 47725 },
    { coordinates: [2945827.2020862503, 7564020.969212674], id: 47726 },
    { coordinates: [2339636.6987481895, 7591101.7302441355], id: 39698 },
    { coordinates: [2419231.6866455716, 7679653.064593991], id: 44510 },
    { coordinates: [2402793.3601127765, 7647050.218770651], id: 44511 },
    { coordinates: [2683687.946313024, 7749570.608699131], id: 44833 },
    { coordinates: [2981495.1914875153, 7569049.052447285], id: 47727 },
    { coordinates: [2973868.0252386937, 7593318.377218316], id: 47728 },
    { coordinates: [2974980.663477435, 7617157.728209483], id: 47729 },
    { coordinates: [3005095.925351967, 7598551.5805697935], id: 47730 },
    { coordinates: [3022478.1298490656, 7587742.420792607], id: 47731 },
    { coordinates: [2335000.6901169075, 7634095.251706986], id: 40017 },
    { coordinates: [2397668.8787690415, 7618049.046598246], id: 44512 },
    { coordinates: [2405273.4470085725, 7631725.104373852], id: 44513 },
    { coordinates: [2683580.4117959747, 7776937.411176316], id: 44834 },
    { coordinates: [3034652.2521005203, 7629950.841315304], id: 47734 },
    { coordinates: [3049946.6594776493, 7596352.8405072065], id: 47732 },
    { coordinates: [3003364.016709025, 7640331.759527311], id: 47735 },
    { coordinates: [2981963.6238055318, 7642211.875095834], id: 47736 },
    { coordinates: [2996840.69464872, 7668822.638611646], id: 47737 },
    { coordinates: [2992792.7838630285, 7690200.946929982], id: 47738 },
    { coordinates: [3022379.055516791, 7712149.270965996], id: 47739 },
    { coordinates: [2337788.820321419, 7670382.519617096], id: 40018 },
    { coordinates: [2357572.2661259267, 7737342.930132056], id: 44817 },
    { coordinates: [2379862.6582525964, 7761553.021884818], id: 44818 },
    { coordinates: [2712006.566339516, 7901977.270160284], id: 45137 },
    { coordinates: [3019753.9192256383, 7760019.155279599], id: 47741 },
    { coordinates: [3003460.3081499287, 7781075.158404419], id: 47742 },
    { coordinates: [3075636.4950785134, 7686171.58289121], id: 48657 },
    { coordinates: [3000117.4917861484, 7708441.182550974], id: 48977 },
    { coordinates: [3015048.4444827936, 7729188.536978488], id: 47740 },
    { coordinates: [2427629.7574095507, 7773743.073660178], id: 49297 },
    { coordinates: [2716182.2268108656, 7713250.0287479265], id: 49618 },
    { coordinates: [2344328.889538078, 7708228.241774849], id: 40019 },
    { coordinates: [2602715.8016584893, 7658018.383205459], id: 40357 },
    { coordinates: [2605422.9557704823, 7659252.123472686], id: 40358 },
    { coordinates: [2383894.809600735, 7785110.936211188], id: 44819 },
    { coordinates: [2714308.153349432, 7861172.675640373], id: 45138 },
    { coordinates: [2885386.637872436, 7848762.472476287], id: 49619 },
    { coordinates: [2888827.7858808474, 7843234.483962502], id: 49620 },
    { coordinates: [2476738.2814154257, 7805017.057623503], id: 49621 },
    { coordinates: [2476615.6192123415, 7804892.683075255], id: 49622 },
    { coordinates: [3020596.1066896995, 7537405.398148873], id: 50257 },
    { coordinates: [2974309.689022608, 7591521.685830899], id: 50577 },
    { coordinates: [2578707.9059173735, 7760845.701155924], id: 51217 },
    { coordinates: [2600689.5529595953, 7657306.335200251], id: 40359 },
    { coordinates: [2862959.354771942, 7906693.295215955], id: 40360 },
    { coordinates: [2867628.913533445, 7900995.271739981], id: 40361 },
    { coordinates: [3040717.3814815884, 7666394.890419139], id: 40365 },
    { coordinates: [3060586.638594173, 7728624.795800098], id: 40366 },
    { coordinates: [2888206.2028293186, 7696283.045808416], id: 40367 },
    { coordinates: [2743450.4495581808, 7913679.220265362], id: 40368 },
    { coordinates: [2383831.086810568, 7803126.967919159], id: 44820 },
    { coordinates: [2717555.899600221, 7814908.066477126], id: 45139 },
    { coordinates: [2466457.213480158, 7830810.356687006], id: 40369 },
    { coordinates: [2339360.052385352, 7595818.662346054], id: 40370 },
    { coordinates: [2633713.8193927305, 7732723.249643375], id: 40372 },
    { coordinates: [2574481.5847697183, 7815878.512507142], id: 40375 },
    { coordinates: [2397035.4716467443, 7841813.941495171], id: 44821 },
    { coordinates: [2404998.042617572, 7851785.435847168], id: 44822 },
    { coordinates: [2703225.555080384, 7787421.574135141], id: 45140 },
    { coordinates: [2716379.8090693257, 7835567.391691621], id: 45457 },
    { coordinates: [2752837.759383825, 7743757.326233381], id: 40376 },
    { coordinates: [2460929.4876023703, 7771383.662260177], id: 40977 },
    { coordinates: [2932001.8855540063, 7612190.344579426], id: 41297 },
    { coordinates: [2424120.83829463, 7757113.959452778], id: 41617 },
    { coordinates: [2759147.470193013, 7932598.871503698], id: 41937 },
    { coordinates: [2914598.777505911, 7625887.636271813], id: 42577 },
    { coordinates: [2456032.006399353, 7762009.585307588], id: 42897 },
    { coordinates: [2418458.795509765, 7877806.961049748], id: 44823 },
    { coordinates: [2646234.837700297, 7754263.480694292], id: 45777 },
    { coordinates: [2655287.88934204, 7756271.336169766], id: 45778 },
    { coordinates: [2638493.5869429084, 7725441.669705114], id: 46097 },
    { coordinates: [2537816.8461990566, 7865107.145843316], id: 46098 },
    { coordinates: [2606794.368330012, 7655708.123741754], id: 43857 },
    { coordinates: [3014102.20348184, 7817646.425160081], id: 44178 },
    { coordinates: [3060168.9309359845, 7731686.776763356], id: 43217 },
    { coordinates: [2446296.7004423747, 7883566.148557613], id: 44824 },
    { coordinates: [2625665.3438936733, 7686032.93971245], id: 46099 },
    { coordinates: [2932525.506184235, 7538536.045551713], id: 46101 },
    { coordinates: [2747904.655085888, 7704290.513991561], id: 46102 },
    { coordinates: [2505142.0633612582, 7691032.821720749], id: 46103 },
    { coordinates: [3060281.7134996997, 7729304.979426766], id: 46104 },
    { coordinates: [2718999.889192598, 7843341.3936390625], id: 46105 },
    { coordinates: [2655153.2200838304, 7756320.726715182], id: 44498 },
    { coordinates: [2474103.401506265, 7897113.33158097], id: 44825 }
];

export const wgs84data = [
    { coordinates: [25.770026697148015, 56.36069844958856], id: 149 },
    { coordinates: [25.987570746315026, 56.41150001113826], id: 147 },
    { coordinates: [26.189303421740433, 56.63123912979483], id: 152 },
    { coordinates: [25.368403265072867, 57.81154160767845], id: 153 },
    { coordinates: [24.378823558138343, 57.5208201961745], id: 156 },
    { coordinates: [24.730319305287424, 56.919927646422074], id: 154 },
    { coordinates: [24.428498362162042, 57.467951806678485], id: 157 },
    { coordinates: [25.228780361798336, 57.55301766417868], id: 159 },
    { coordinates: [25.012529448410582, 57.51421735680268], id: 158 },
    { coordinates: [24.927752574076543, 57.67930762329122], id: 160 },
    { coordinates: [24.99533600773848, 57.87377128836405], id: 162 },
    { coordinates: [24.78666042943193, 57.82632282169908], id: 163 },
    { coordinates: [26.43049666449693, 57.39177392132719], id: 3 },
    { coordinates: [27.158679065344003, 57.50853134684934], id: 8 },
    { coordinates: [27.110073098849146, 57.5552960125166], id: 9 },
    { coordinates: [26.781579143928436, 57.43440326758257], id: 15 },
    { coordinates: [26.996046915073833, 57.305260002996455], id: 20 },
    { coordinates: [26.980203024184572, 57.102679685607434], id: 43 },
    { coordinates: [26.407166290384964, 57.266042884844644], id: 25 },
    { coordinates: [26.42461530917563, 57.26223257283208], id: 35 },
    { coordinates: [21.690042368836586, 57.0027002964868], id: 49 },
    { coordinates: [22.009098576668418, 56.99521361499184], id: 51 },
    { coordinates: [22.503317960274018, 56.65897591081332], id: 64 },
    { coordinates: [22.20397094966802, 57.01599945941318], id: 54 },
    { coordinates: [21.294378653584452, 56.76865428555505], id: 56 },
    { coordinates: [22.146881261218393, 56.86504907131215], id: 68 },
    { coordinates: [21.171752783903862, 56.42501322184404], id: 75 },
    { coordinates: [22.696511127536475, 56.73694405941659], id: 71 },
    { coordinates: [21.23542372275891, 56.41507419950816], id: 72 },
    { coordinates: [21.386356278730997, 56.33179394209288], id: 76 },
    { coordinates: [21.452627036374796, 56.30976624259651], id: 78 },
    { coordinates: [20.99691642784302, 56.26709149333856], id: 81 },
    { coordinates: [21.38256312923455, 56.280761939288595], id: 89 },
    { coordinates: [25.691352834305697, 56.514178351431454], id: 106 },
    { coordinates: [22.285062840242652, 57.094871110975454], id: 97 },
    { coordinates: [22.29150473487462, 56.710496398994785], id: 102 },
    { coordinates: [25.374568119756834, 56.30424379096984], id: 107 },
    { coordinates: [26.30940379444087, 55.92692801403098], id: 112 },
    { coordinates: [25.362765103845927, 56.35355743024097], id: 108 },
    { coordinates: [26.329485997959335, 55.8734413761183], id: 111 },
    { coordinates: [26.34362152760693, 55.90026269378818], id: 113 },
    { coordinates: [26.76434442286399, 55.88608417648889], id: 116 },
    { coordinates: [26.74401454659995, 55.69336535249585], id: 114 },
    { coordinates: [26.880340814459814, 55.89306055366704], id: 117 },
    { coordinates: [27.240038549220774, 55.83294936409652], id: 119 },
    { coordinates: [27.146083958995078, 55.89270763026699], id: 122 },
    { coordinates: [27.128100088449052, 55.8875658348993], id: 120 },
    { coordinates: [27.18889286302366, 55.88613955429555], id: 121 },
    { coordinates: [26.571806989818118, 56.13119639577375], id: 123 },
    { coordinates: [26.154808372233607, 56.19071201393371], id: 129 },
    { coordinates: [26.48283242819111, 55.9406287068168], id: 125 },
    { coordinates: [26.20897528651526, 56.15584289861645], id: 132 },
    { coordinates: [26.313310258667105, 56.09419421937816], id: 138 },
    { coordinates: [27.02504972929514, 56.21846498955953], id: 139 },
    { coordinates: [26.673719786889883, 56.20280717690354], id: 142 },
    { coordinates: [27.010783898290963, 56.22565235711728], id: 140 },
    { coordinates: [26.702287648933822, 56.22459712333257], id: 141 },
    { coordinates: [26.221390923655434, 56.40187378226953], id: 144 },
    { coordinates: [25.935582119323456, 56.41874561812014], id: 146 },
    { coordinates: [27.676480999646277, 57.18455399944145], id: 47699 },
    { coordinates: [27.644078999234182, 56.94653099933226], id: 47701 },
    { coordinates: [27.493893000034916, 56.845664998704216], id: 47702 },
    { coordinates: [27.673375999977267, 56.78725899946144], id: 47703 },
    { coordinates: [27.72360799947138, 56.64292099889494], id: 47704 },
    { coordinates: [27.726573999395065, 56.547628999242754], id: 47705 },
    { coordinates: [27.817856999850644, 56.44163799902603], id: 47706 },
    { coordinates: [27.93098199950837, 56.40535899894204], id: 47707 },
    { coordinates: [28.122820999552093, 56.387741999154755], id: 47708 },
    { coordinates: [28.1010480002354, 56.2074009992584], id: 47709 },
    { coordinates: [28.1118699993288, 56.17790299939033], id: 47710 },
    { coordinates: [27.987525000008045, 56.265093998945424], id: 47711 },
    { coordinates: [27.70638050015889, 56.27332519933947], id: 47712 },
    { coordinates: [27.6078779998263, 56.18768899939519], id: 47713 },
    { coordinates: [27.54113999999, 56.09480299922462], id: 47714 },
    { coordinates: [27.60893400049232, 55.96980699943448], id: 47715 },
    { coordinates: [27.53415699954923, 55.874003999441456], id: 47716 },
    { coordinates: [27.446000000204496, 55.79707999911417], id: 47717 },
    { coordinates: [27.161395000681058, 55.89708599934277], id: 47718 },
    { coordinates: [26.970628999524514, 55.86454399874353], id: 47719 },
    { coordinates: [26.838875000228374, 55.75953099945356], id: 47720 },
    { coordinates: [26.660385999740008, 55.720690998778274], id: 47722 },
    { coordinates: [26.46550299973992, 55.71423599935353], id: 47723 },
    { coordinates: [26.351024000359956, 55.783315999345454], id: 47724 },
    { coordinates: [26.37661300022891, 55.90339699888653], id: 47725 },
    { coordinates: [26.46281600009125, 56.028146999202384], id: 47726 },
    { coordinates: [26.995235999889687, 56.20108999924677], id: 47730 },
    { coordinates: [27.15138299960403, 56.14703699942265], id: 47731 },
    { coordinates: [26.783227000420943, 56.053377998673234], id: 47727 },
    { coordinates: [26.71471100026257, 56.17492999896483], id: 47728 },
    { coordinates: [26.72470599961814, 56.293954999324036], id: 47729 },
    { coordinates: [27.398136999580498, 56.19010099911702], id: 47732 },
    { coordinates: [27.260745000496247, 56.35767599945803], id: 47734 },
    { coordinates: [26.97967799984315, 56.40930399898403], id: 47735 },
    { coordinates: [26.78743499952944, 56.418646999468756], id: 47736 },
    { coordinates: [26.921078000743087, 56.55063999891793], id: 47737 },
    { coordinates: [26.884714999467697, 56.65634699871112], id: 47738 },
    { coordinates: [27.15049299973457, 56.76456499897948], id: 47739 },
    { coordinates: [27.084641000396818, 56.848363998721936], id: 47740 },
    { coordinates: [27.126910999202178, 56.99951309931151], id: 47741 },
    { coordinates: [26.980543000574087, 57.10238999932839], id: 47742 },
    { coordinates: [27.628912719248238, 56.636446058674636], id: 48657 },
    { coordinates: [26.9505139702582, 56.74630381602436], id: 48977 },
    { coordinates: [21.807769152643655, 57.06659872362121], id: 49297 },
    { coordinates: [24.39988008797997, 56.76998417995326], id: 49618 },
    { coordinates: [25.919869173950485, 57.431174647295876], id: 49619 },
    { coordinates: [25.950781532459214, 57.40443295747012], id: 49620 },
    { coordinates: [22.248918529593933, 57.2190210506568], id: 49621 },
    { coordinates: [22.24781663627579, 57.21841612015561], id: 49622 },
    { coordinates: [27.13447649791278, 55.894314664928494], id: 50257 },
    { coordinates: [26.718678533537897, 56.165944494751606], id: 50577 },
    { coordinates: [23.164927251654213, 57.0035568707425], id: 51217 },
    { coordinates: [21.532936007569162, 57.397557917167255], id: 44821 },
    { coordinates: [21.60446499960897, 57.44578999873014], id: 44822 },
    { coordinates: [24.40165499960643, 57.36731099936517], id: 45457 },
    { coordinates: [24.28348832551186, 57.13334189180178], id: 45140 },
    { coordinates: [25.42557169505416, 57.53497640088353], id: 34897 },
    { coordinates: [24.72916233955828, 56.91986472933888], id: 40376 },
    { coordinates: [22.106905718536318, 57.055074005390345], id: 40977 },
    { coordinates: [26.338621068604198, 56.269184366709965], id: 41297 },
    { coordinates: [21.77624799592693, 56.98529630768728], id: 41617 },
    { coordinates: [24.785843436140954, 57.83434533010722], id: 41937 },
    { coordinates: [26.18228628909632, 56.33744917602844], id: 42577 },
    { coordinates: [22.06291089635273, 57.009250281996856], id: 42897 },
    { coordinates: [21.7253850001971, 57.571356999585156], id: 44823 },
    { coordinates: [23.77153200075718, 56.97134199951474], id: 45777 },
    { coordinates: [23.852856947334192, 56.98117185204803], id: 45778 },
    { coordinates: [23.70199116202154, 56.829952996192226], id: 46097 },
    { coordinates: [22.797596612366135, 57.510127958682006], id: 46098 },
    { coordinates: [25.701845117944103, 57.62002014460213], id: 34898 },
    { coordinates: [27.48996522647491, 56.86063458295294], id: 43217 },
    { coordinates: [23.41723223627543, 56.48564760513375], id: 43857 },
    { coordinates: [27.076140772860647, 57.280395905286916], id: 44178 },
    { coordinates: [21.975457154985396, 57.59908961644069], id: 44824 },
    { coordinates: [23.58675309402626, 56.63576111617836], id: 46099 },
    { coordinates: [26.34332483275635, 55.900009367542026], id: 46101 },
    { coordinates: [24.684847509668355, 56.72585252988732], id: 46102 },
    { coordinates: [22.504074044081328, 56.66045430218287], id: 46103 },
    { coordinates: [27.490978369482587, 56.84893601757204], id: 46104 },
    { coordinates: [24.42519157980996, 57.40495031911741], id: 46105 },
    { coordinates: [25.998479999468206, 57.67389833921403], id: 35217 },
    { coordinates: [26.245655154571295, 57.57021454579417], id: 35537 },
    { coordinates: [23.851647192804684, 56.98141362051031], id: 44498 },
    { coordinates: [22.22524900065175, 57.66424099872559], id: 44825 },
    { coordinates: [24.98607207895353, 56.598682602918174], id: 47058 },
    { coordinates: [21.172918918997674, 56.88121130757784], id: 46418 },
    { coordinates: [25.02385676825715, 57.8737146766165], id: 47057 },
    { coordinates: [25.696698416030795, 56.510793037638265], id: 47059 },
    { coordinates: [21.948871665606372, 56.92027333917359], id: 47060 },
    { coordinates: [23.182382496143514, 56.65828061976649], id: 47061 },
    { coordinates: [26.398865575104534, 57.51884421942619], id: 35857 },
    { coordinates: [26.592903079417475, 57.450914805185256], id: 36177 },
    { coordinates: [23.54366999943069, 56.98528399952227], id: 44499 },
    { coordinates: [22.432081999529807, 57.71810999896372], id: 44826 },
    { coordinates: [26.18326842346018, 57.094275381190045], id: 47074 },
    { coordinates: [25.044360097489417, 56.619767549510584], id: 47069 },
    { coordinates: [22.243911608432345, 57.21221874677915], id: 47073 },
    { coordinates: [25.944321868383195, 56.68630406191315], id: 47377 },
    { coordinates: [27.25727900035769, 57.13116299896516], id: 47697 },
    { coordinates: [27.496627000752962, 57.23932699898325], id: 47698 },
    { coordinates: [23.321056781041353, 56.972591054659674], id: 44501 },
    { coordinates: [22.60153399928712, 57.757377999493386], id: 44827 },
    { coordinates: [26.687095699563137, 57.48399605531408], id: 47062 },
    { coordinates: [27.746799690576378, 56.372042281515014], id: 47063 },
    { coordinates: [26.56591600438345, 56.81252646654586], id: 47064 },
    { coordinates: [25.94382486889334, 56.686126735084066], id: 47065 },
    { coordinates: [26.034736056577433, 57.61140240636166], id: 47066 },
    { coordinates: [25.153015215106393, 57.100856285116194], id: 47067 },
    { coordinates: [24.48140137701914, 56.65964853464221], id: 36179 },
    { coordinates: [26.694864276628646, 57.53965657577132], id: 36178 },
    { coordinates: [23.170256000429312, 56.97129399893575], id: 44502 },
    { coordinates: [23.052798999492953, 56.98008299906863], id: 44503 },
    { coordinates: [22.66059999950608, 57.5673919990846], id: 44828 },
    { coordinates: [22.914348221825463, 57.308667360018696], id: 30737 },
    { coordinates: [26.458535141876343, 56.15410112751154], id: 31697 },
    { coordinates: [24.10695930566955, 56.94779786160295], id: 36497 },
    { coordinates: [22.78195853038647, 57.034083915740794], id: 44504 },
    { coordinates: [22.89228754071553, 57.453389326373554], id: 44829 },
    { coordinates: [24.388856859056663, 56.74228744135427], id: 32017 },
    { coordinates: [26.11115187278141, 56.18369746011447], id: 32337 },
    { coordinates: [23.696710542596556, 56.83615099436468], id: 32657 },
    { coordinates: [23.660072162648426, 56.81423025093119], id: 32673 },
    { coordinates: [23.628268063309548, 56.80866688457078], id: 32674 },
    { coordinates: [23.017431800809376, 56.573037515342286], id: 32675 },
    { coordinates: [22.571969000586904, 57.042495999216975], id: 44505 },
    { coordinates: [23.123331999657108, 57.336553999586904], id: 44830 },
    { coordinates: [22.953257473713954, 56.56518492748342], id: 32676 },
    { coordinates: [22.940065998995685, 56.57560031001003], id: 32677 },
    { coordinates: [22.925553362227987, 56.57934978177584], id: 32678 },
    { coordinates: [21.06454177300699, 56.071075846400966], id: 39697 },
    { coordinates: [22.292693000798543, 57.06911499900677], id: 44506 },
    { coordinates: [21.978410000190408, 56.970877999495286], id: 44507 },
    { coordinates: [23.229572000746753, 57.16073599943525], id: 44831 },
    { coordinates: [23.17301478615644, 56.99604607633618], id: 33298 },
    { coordinates: [26.526065201680378, 55.91025959842758], id: 33617 },
    { coordinates: [24.316932046519806, 57.023095623257774], id: 34257 },
    { coordinates: [21.01731405772439, 56.16384393511026], id: 39698 },
    { coordinates: [21.945970000185874, 56.841114999188676], id: 44508 },
    { coordinates: [21.604729000702193, 56.718922998924405], id: 44509 },
    { coordinates: [23.486070854918655, 57.03482541859744], id: 44832 },
    { coordinates: [24.545452728997677, 57.094131785429035], id: 34258 },
    { coordinates: [20.975668083616483, 56.378296018735476], id: 40017 },
    { coordinates: [21.732327999399658, 56.60422899951328], id: 44510 },
    { coordinates: [21.584659999702083, 56.44267999891267], id: 44511 },
    { coordinates: [24.10797899980319, 56.9483569988004], id: 44833 },
    { coordinates: [24.65889750795498, 57.12512937139255], id: 34259 },
    { coordinates: [21.000714283384763, 56.558362979821624], id: 40018 },
    { coordinates: [21.606938999346465, 56.36650499920824], id: 44513 },
    { coordinates: [21.53862600055946, 56.29839799956111], id: 44512 },
    { coordinates: [24.107013000800833, 57.08219599928595], id: 44834 },
    { coordinates: [24.853149304538757, 57.1545337886851], id: 34260 },
    { coordinates: [21.059464724750008, 56.74525487928585], id: 40019 },
    { coordinates: [23.380593848492136, 56.49710478636962], id: 40357 },
    { coordinates: [23.404912627644844, 56.50322180994678], id: 40358 },
    { coordinates: [21.178432000772162, 56.88840099908353], id: 44817 },
    { coordinates: [21.378670000136207, 57.00701699921134], id: 44818 },
    { coordinates: [24.3623694917529, 57.687604242138605], id: 45137 },
    { coordinates: [25.04042440229012, 57.23437296511396], id: 34261 },
    { coordinates: [23.362391746735703, 56.493573922869686], id: 40359 },
    { coordinates: [25.71840146204599, 57.710242630545764], id: 40360 },
    { coordinates: [25.760348822101513, 57.682888564077324], id: 40361 },
    { coordinates: [27.315228984728, 56.538617066516444], id: 40365 },
    { coordinates: [27.493717558211355, 56.84559451919414], id: 40366 },
    { coordinates: [25.945197756903834, 56.68636646548043], id: 40367 },
    { coordinates: [24.644834700626863, 57.7437512495855], id: 40368 },
    { coordinates: [21.41489143197537, 57.12207575067509], id: 44819 },
    { coordinates: [24.383044999640287, 57.49113799953463], id: 45138 },
    { coordinates: [25.229436913024724, 57.31183671969695], id: 34577 },
    { coordinates: [22.15656212496071, 57.3442598408813], id: 40369 },
    { coordinates: [21.014828901164062, 56.18743077491622], id: 40370 },
    { coordinates: [23.659053779572908, 56.86572423740198], id: 40372 },
    { coordinates: [23.126961562828853, 57.271810543492066], id: 40375 },
    { coordinates: [21.41431900041183, 57.20982699934368], id: 44820 },
    { coordinates: [24.41222000060054, 57.26709699896639], id: 45139 },
    { coordinates: [25.380762341347737, 57.39941139731457], id: 34578 },
    { coordinates: [27.397058575827447, 56.42838329623612], id: 24992 },
    { coordinates: [27.636765482450997, 56.38112691531323], id: 24993 },
    { coordinates: [27.746299398139378, 56.37182204629765], id: 24994 },
    { coordinates: [28.03449930367927, 56.228965613601794], id: 24995 },
    { coordinates: [25.85744973027329, 57.85144307034441], id: 24996 },
    { coordinates: [25.97686140374188, 57.667540947256846], id: 24997 },
    { coordinates: [25.883944246053325, 57.621125490485625], id: 24998 },
    { coordinates: [25.674061534499003, 57.229223531104424], id: 164 },
    { coordinates: [24.75356638957195, 57.88138098919754], id: 168 },
    { coordinates: [24.585573628734572, 56.74947450151732], id: 181 },
    { coordinates: [25.220789695353574, 56.403695982777094], id: 190 },
    { coordinates: [28.11152217920722, 56.178250166609025], id: 347 },
    { coordinates: [28.010225889104, 56.21026517573354], id: 348 },
    { coordinates: [27.28989206294642, 56.80307671592934], id: 350 },
    { coordinates: [25.773853340213616, 57.74985132790687], id: 352 },
    { coordinates: [26.216221239036535, 56.82806254750621], id: 12481 },
    { coordinates: [21.9795733740512, 57.00915939916476], id: 12801 },
    { coordinates: [21.606050043371024, 56.36876080689368], id: 12817 },
    { coordinates: [23.039537841895662, 57.15805833782663], id: 13461 },
    { coordinates: [22.63785711771052, 56.86844337625234], id: 13467 },
    { coordinates: [25.979101234739574, 57.63995377135338], id: 25001 },
    { coordinates: [26.160617163550263, 57.57695058021465], id: 25002 },
    { coordinates: [25.962064655821, 57.42323038828538], id: 25003 },
    { coordinates: [25.69849436433921, 57.618645672427476], id: 24999 },
    { coordinates: [21.205815543660083, 56.885721960027084], id: 25004 },
    { coordinates: [23.209794235747722, 56.37960087647292], id: 25005 },
    { coordinates: [25.0131927901549, 56.57300791359529], id: 201 },
    { coordinates: [25.57073235637255, 56.70805845157047], id: 204 },
    { coordinates: [24.91694923118274, 56.63144004821874], id: 219 },
    { coordinates: [24.689828329426327, 56.389736729113615], id: 220 },
    { coordinates: [26.08111235808981, 57.66810696993444], id: 360 },
    { coordinates: [26.00808473854812, 57.67015760258423], id: 369 },
    { coordinates: [26.192929782585768, 57.65408193258964], id: 371 },
    { coordinates: [26.230071394186524, 57.59534985374946], id: 372 },
    { coordinates: [26.07497507803986, 57.674477747203646], id: 374 },
    { coordinates: [26.10261463265018, 57.70406991956032], id: 355 },
    { coordinates: [22.785488224779797, 57.0263951094714], id: 13468 },
    { coordinates: [22.93803302686997, 57.02551882991319], id: 13469 },
    { coordinates: [22.749687866764155, 56.85471860131904], id: 13470 },
    { coordinates: [24.441592131221302, 56.7848406343747], id: 13471 },
    { coordinates: [23.04381315317946, 57.11203964138203], id: 25007 },
    { coordinates: [23.355734324414815, 57.044588680195474], id: 25008 },
    { coordinates: [23.222684321121093, 57.171484335042955], id: 25009 },
    { coordinates: [23.137322387255832, 57.258080354226635], id: 25010 },
    { coordinates: [25.422912420052747, 56.638939949963586], id: 224 },
    { coordinates: [25.427728781464452, 56.643610131375105], id: 225 },
    { coordinates: [25.975257588887004, 57.4325638863513], id: 380 },
    { coordinates: [25.906923729168675, 57.41444170196578], id: 387 },
    { coordinates: [25.90256044164542, 57.409734653298536], id: 388 },
    { coordinates: [24.521368058588557, 56.73752337363829], id: 13472 },
    { coordinates: [24.511313926420414, 56.75025753470424], id: 13777 },
    { coordinates: [24.01815630468744, 56.66982923864233], id: 13778 },
    { coordinates: [23.14562640760329, 56.619326202877815], id: 13781 },
    { coordinates: [22.804524313385123, 56.55790147325415], id: 13783 },
    { coordinates: [22.10555914206761, 56.47933416998331], id: 25012 },
    { coordinates: [22.62779829858026, 57.57676511466145], id: 25013 },
    { coordinates: [27.60381309360513, 56.87050000141433], id: 25014 },
    { coordinates: [21.656124511460465, 56.67981695711237], id: 25015 },
    { coordinates: [25.997229745678514, 57.672899146807346], id: 25016 },
    { coordinates: [25.054068439247363, 56.617224761726334], id: 226 },
    { coordinates: [25.04264058401984, 56.623265499954194], id: 227 },
    { coordinates: [25.01390953218638, 57.8930697064939], id: 397 },
    { coordinates: [24.638409716694156, 57.1255611812126], id: 399 },
    { coordinates: [26.40289780448107, 57.509299516298995], id: 396 },
    { coordinates: [25.032651378349293, 56.49938061543277], id: 407 },
    { coordinates: [23.041893395636315, 56.55443691494137], id: 13785 },
    { coordinates: [23.133713255756202, 56.44841784615171], id: 13788 },
    { coordinates: [23.073487521192032, 56.484358007765906], id: 13790 },
    { coordinates: [23.13905659302546, 56.423858533026106], id: 13794 },
    { coordinates: [23.58714218156214, 56.63545101531573], id: 13795 },
    { coordinates: [23.406770979657434, 56.4947482656466], id: 25053 },
    { coordinates: [21.159029490769175, 56.362828426897075], id: 25054 },
    { coordinates: [25.261371154838812, 56.48380581794647], id: 228 },
    { coordinates: [22.003751351862437, 57.30231959806901], id: 279 },
    { coordinates: [22.107309429827897, 57.304978845418745], id: 280 },
    { coordinates: [22.0830973937676, 57.42066777068128], id: 281 },
    { coordinates: [21.41624744108298, 57.20947056040657], id: 412 },
    { coordinates: [25.646138842447215, 56.40080202058748], id: 416 },
    { coordinates: [26.28758903138918, 56.11173709242128], id: 418 },
    { coordinates: [25.484582383252707, 56.61859355148841], id: 419 },
    { coordinates: [23.66079916187098, 56.866106982584036], id: 13797 },
    { coordinates: [23.875181829753416, 56.647127443002084], id: 13799 },
    { coordinates: [22.19355339291009, 56.52940527266412], id: 25017 },
    { coordinates: [23.01953398305647, 56.715380424936036], id: 25018 },
    { coordinates: [24.4897449100285, 57.65080737835615], id: 25019 },
    { coordinates: [22.925424451105236, 56.754535798294], id: 25021 },
    { coordinates: [22.28065615125136, 57.12180241841323], id: 25022 },
    { coordinates: [22.15365077177984, 57.35661642239888], id: 284 },
    { coordinates: [22.690276285843318, 57.4311332061587], id: 286 },
    { coordinates: [22.55014371586649, 57.47911010675971], id: 287 },
    { coordinates: [22.201588400286532, 57.297414945653294], id: 283 },
    { coordinates: [24.802538827019752, 56.855029453343185], id: 420 },
    { coordinates: [24.30103772718908, 56.41748632775881], id: 421 },
    { coordinates: [24.43153304001059, 56.680391620076385], id: 424 },
    { coordinates: [21.41819878141852, 56.82771115137172], id: 961 },
    { coordinates: [22.947161817475198, 56.8172679921414], id: 13805 },
    { coordinates: [23.156763902873603, 57.13025453113117], id: 13806 },
    { coordinates: [23.373076732059214, 56.48750394446255], id: 13807 },
    { coordinates: [25.10567002429142, 56.50981980100396], id: 25036 },
    { coordinates: [27.118895310972906, 56.08335052109615], id: 25038 },
    { coordinates: [25.81536419944901, 56.10472377762851], id: 25039 },
    { coordinates: [26.989812019917846, 56.13198695284536], id: 25040 },
    { coordinates: [22.476015611032963, 57.60950423360472], id: 288 },
    { coordinates: [22.655752621295814, 57.44456787301716], id: 289 },
    { coordinates: [22.59263736101743, 57.62512725756587], id: 290 },
    { coordinates: [21.884577815350205, 57.51638508876904], id: 294 },
    { coordinates: [26.039860916726052, 57.59238445970705], id: 1602 },
    { coordinates: [23.488107407346735, 57.00188196671585], id: 2245 },
    { coordinates: [21.347420780450676, 56.748454809370386], id: 962 },
    { coordinates: [23.05405468383931, 56.98199796797844], id: 13809 },
    { coordinates: [23.919953447414855, 56.99571426801238], id: 13810 },
    { coordinates: [22.90187615520939, 56.52491496198735], id: 13811 },
    { coordinates: [22.795019346702617, 57.50866143513653], id: 25055 },
    { coordinates: [22.24884948245183, 57.21792358587101], id: 25057 },
    { coordinates: [21.98086001379598, 57.582090969414175], id: 295 },
    { coordinates: [22.08069240424697, 57.609810077714414], id: 296 },
    { coordinates: [22.36116448006258, 57.50638418982844], id: 297 },
    { coordinates: [22.69660824752976, 57.42342096734363], id: 298 },
    { coordinates: [26.56628288611041, 56.811759402618925], id: 3521 },
    { coordinates: [24.42541093129744, 57.62322350799047], id: 5121 },
    { coordinates: [24.90499203610536, 56.56538206528614], id: 5765 },
    { coordinates: [23.652428238029845, 56.62703434829121], id: 13815 },
    { coordinates: [23.628127678202777, 56.42091231934305], id: 13816 },
    { coordinates: [23.413006455647363, 56.37678665012661], id: 13820 },
    { coordinates: [23.014418962742255, 56.59856797475332], id: 25023 },
    { coordinates: [25.212517544484015, 56.38933914469881], id: 25024 },
    { coordinates: [24.638797399786146, 57.74116793674931], id: 25025 },
    { coordinates: [22.314777319528798, 57.43246435968919], id: 299 },
    { coordinates: [22.377227251726232, 57.35980404496028], id: 301 },
    { coordinates: [21.38087240889062, 57.00585693708461], id: 302 },
    { coordinates: [21.41588785393303, 57.24948458445162], id: 303 },
    { coordinates: [24.74067804803777, 56.73762760208957], id: 5766 },
    { coordinates: [21.869733921146246, 57.289091736661625], id: 6084 },
    { coordinates: [21.661647091726817, 57.43867652808767], id: 6086 },
    { coordinates: [23.010892391019667, 56.633968471499486], id: 13825 },
    { coordinates: [22.911003692846236, 56.79224325884992], id: 13826 },
    { coordinates: [25.49241976514817, 56.421914451784936], id: 14098 },
    { coordinates: [22.18415415736236, 57.19051308184723], id: 14417 },
    { coordinates: [27.085231702588494, 57.28152953120532], id: 25041 },
    { coordinates: [27.229114892331935, 57.13261805843757], id: 25042 },
    { coordinates: [27.277851407900314, 56.2527757551314], id: 25043 },
    { coordinates: [25.677051388502054, 57.61984424127559], id: 25044 },
    { coordinates: [25.951861227310975, 57.40410573169998], id: 25045 },
    { coordinates: [21.77178835147178, 57.38046222681828], id: 304 },
    { coordinates: [21.615683239940466, 57.458550737563534], id: 305 },
    { coordinates: [21.772075720832916, 57.47025986873443], id: 306 },
    { coordinates: [21.860105889656108, 57.31953923978011], id: 307 },
    { coordinates: [22.04911077535123, 57.10706421175116], id: 6090 },
    { coordinates: [26.78905513196622, 55.720158567397206], id: 7042 },
    { coordinates: [26.139318177226578, 56.53091600739524], id: 7043 },
    { coordinates: [26.8385460143397, 55.759530745982325], id: 7044 },
    { coordinates: [25.99158183186802, 57.434665718683476], id: 7698 },
    { coordinates: [25.934086075551363, 57.41541716835911], id: 7716 },
    { coordinates: [21.8960101389495, 57.5222573712788], id: 14436 },
    { coordinates: [25.721330630041084, 56.967151123412705], id: 14437 },
    { coordinates: [26.63626175676486, 56.08662177124673], id: 16017 },
    { coordinates: [20.99283153774804, 56.32905908434237], id: 16018 },
    { coordinates: [22.145123713531376, 57.14665988373903], id: 14433 },
    { coordinates: [21.722040518667733, 56.33144168788777], id: 26577 },
    { coordinates: [22.638062011974746, 56.54526001343232], id: 26578 },
    { coordinates: [24.64775098151717, 57.88564990780555], id: 27857 },
    { coordinates: [24.445235240765395, 57.65061087010353], id: 28177 },
    { coordinates: [26.98999081369817, 57.090687875713584], id: 26257 },
    { coordinates: [22.21589246462321, 57.21080174537242], id: 308 },
    { coordinates: [22.64391619189864, 57.2143716859131], id: 309 },
    { coordinates: [22.55080729759823, 57.054498626604044], id: 310 },
    { coordinates: [22.47460775396236, 57.07621436718184], id: 311 },
    { coordinates: [25.921551688071236, 57.41743291272183], id: 7717 },
    { coordinates: [25.740939058761988, 57.62761029820644], id: 7719 },
    { coordinates: [21.172237194560157, 56.88126575148818], id: 8009 },
    { coordinates: [27.479961517370626, 56.863240873751614], id: 8012 },
    { coordinates: [27.65489541997599, 56.36720599904297], id: 8013 },
    { coordinates: [23.02500544675851, 57.38154725331822], id: 16337 },
    { coordinates: [22.005601557914176, 56.99896833673856], id: 16978 },
    { coordinates: [27.02668487123433, 56.224850231326094], id: 20177 },
    { coordinates: [25.635278102594935, 57.82223311385821], id: 20818 },
    { coordinates: [24.201739248949785, 56.865756090864856], id: 20819 },
    { coordinates: [24.41210541184398, 56.77267499316295], id: 21137 },
    { coordinates: [24.50099015100021, 57.9131889770762], id: 25026 },
    { coordinates: [25.159435708293834, 57.12610144971926], id: 25027 },
    { coordinates: [24.96356276415192, 57.37373096486395], id: 25028 },
    { coordinates: [22.041470264298816, 57.27976537700877], id: 25029 },
    { coordinates: [24.993198274095693, 57.62018837231712], id: 25030 },
    { coordinates: [22.342793932463014, 57.12580542689107], id: 312 },
    { coordinates: [22.248292015641493, 57.2186236726488], id: 313 },
    { coordinates: [22.928245107440507, 57.36907272198272], id: 315 },
    { coordinates: [26.114832577877394, 56.17631571189725], id: 8641 },
    { coordinates: [21.130422674511436, 56.18827048728805], id: 9286 },
    { coordinates: [22.398660193328936, 56.90684225598242], id: 9290 },
    { coordinates: [27.67897441969292, 57.334269438391274], id: 8321 },
    { coordinates: [26.014307226959918, 56.49624110324773], id: 22417 },
    { coordinates: [24.422438369997792, 57.40557342505366], id: 22737 },
    { coordinates: [25.507674721677006, 57.54994670148912], id: 22738 },
    { coordinates: [25.09303850251895, 57.59749416341187], id: 22753 },
    { coordinates: [25.25611510100098, 56.80807011057022], id: 25047 },
    { coordinates: [21.81837284077713, 57.12585334851052], id: 25048 },
    { coordinates: [27.490627254192262, 56.84893607551494], id: 25049 },
    { coordinates: [23.26031126382086, 57.08695273977105], id: 25050 },
    { coordinates: [26.046492986090104, 57.70278088452345], id: 25046 },
    { coordinates: [22.77547176660197, 57.41354898529594], id: 316 },
    { coordinates: [22.113535101233012, 57.10177497119111], id: 317 },
    { coordinates: [21.836005090885, 57.170141277659745], id: 318 },
    { coordinates: [22.85620009409323, 57.45826640164738], id: 319 },
    { coordinates: [26.872349620122783, 55.90250700002696], id: 9291 },
    { coordinates: [25.63031790969432, 56.48460893020938], id: 9922 },
    { coordinates: [27.221636239296494, 55.87423998465632], id: 15700 },
    { coordinates: [24.264477487293863, 57.133408872715265], id: 22755 },
    { coordinates: [21.880306901523177, 57.06536112187284], id: 23058 },
    { coordinates: [26.083647594106438, 57.66285114580145], id: 23377 },
    { coordinates: [26.20162445899972, 57.664481266455695], id: 23378 },
    { coordinates: [24.28170359341616, 57.1632288683566], id: 28497 },
    { coordinates: [26.553589286986853, 57.26858556258251], id: 29137 },
    { coordinates: [25.491370000292907, 57.54552299883842], id: 29457 },
    { coordinates: [27.18064104033595, 56.79145049500758], id: 29777 },
    { coordinates: [22.1449271589599, 57.6415874161915], id: 321 },
    { coordinates: [27.316776779714075, 56.534147341341], id: 322 },
    { coordinates: [26.210609877795758, 56.68043890061667], id: 324 },
    { coordinates: [27.150390000492827, 56.764730005752206], id: 25000 },
    { coordinates: [23.770711697728995, 56.971825839422515], id: 44500 },
    { coordinates: [26.9946802820843, 57.5848915735483], id: 23697 },
    { coordinates: [24.399525749884244, 56.77024678483268], id: 24017 },
    { coordinates: [26.718958928325524, 56.164363939061985], id: 24337 },
    { coordinates: [22.488120507704387, 56.64893599537727], id: 24977 },
    { coordinates: [22.08754884849701, 56.686882798028925], id: 24978 },
    { coordinates: [25.97469813628208, 57.431128472396075], id: 25031 },
    { coordinates: [26.723266754087383, 57.338600456887335], id: 25032 },
    { coordinates: [25.62388498589646, 56.89256052888377], id: 25033 },
    { coordinates: [23.07114589521507, 56.57687189538363], id: 25034 },
    { coordinates: [25.700315947891582, 57.705662859313406], id: 25035 },
    { coordinates: [26.56038051255893, 56.80652505633665], id: 325 },
    { coordinates: [26.686303854720364, 56.88027106337089], id: 326 },
    { coordinates: [26.51133889241801, 56.92302259718652], id: 327 },
    { coordinates: [27.660720298222945, 57.27143199436671], id: 328 },
    { coordinates: [27.61279227535227, 57.2785787840048], id: 329 },
    { coordinates: [26.322333240138793, 57.46819194537407], id: 46100 },
    { coordinates: [27.58496000058859, 57.06929099938117], id: 47700 },
    { coordinates: [25.626602032008815, 56.462745041393205], id: 10242 },
    { coordinates: [26.94107008741598, 56.54539038959556], id: 10246 },
    { coordinates: [25.932354598537042, 57.424592495419006], id: 10563 },
    { coordinates: [22.262180802478237, 57.09162546399619], id: 24982 },
    { coordinates: [25.164455935918152, 57.74429084587013], id: 24983 },
    { coordinates: [21.985404947551938, 57.32436048631491], id: 24984 },
    { coordinates: [26.191677643479206, 56.81785390799996], id: 24985 },
    { coordinates: [25.76074612863549, 56.660170127688545], id: 24986 },
    { coordinates: [22.287205189172887, 57.616223153115456], id: 24979 },
    { coordinates: [23.51955172952613, 56.53400826754476], id: 25051 },
    { coordinates: [23.529901579488506, 56.42318291644395], id: 25052 },
    { coordinates: [26.94676651964322, 56.717446899688134], id: 334 },
    { coordinates: [26.88452904028825, 56.65636112913405], id: 337 },
    { coordinates: [26.861354181752635, 56.64182764422806], id: 338 },
    { coordinates: [27.053622726304, 56.23724298188692], id: 340 },
    { coordinates: [25.683421310456428, 56.288867290483466], id: 11841 },
    { coordinates: [27.51989035797956, 57.142999681342296], id: 12164 },
    { coordinates: [22.36046491973668, 57.000104257284185], id: 12165 },
    { coordinates: [21.89184166309456, 57.56468940969158], id: 24988 },
    { coordinates: [25.712781273342436, 57.62757754285906], id: 24989 },
    { coordinates: [24.893888393829865, 57.28563856825097], id: 24990 },
    { coordinates: [26.96914833305401, 57.05457167185186], id: 24991 },
    { coordinates: [21.86727600301494, 57.55223231773636], id: 24987 }
];
