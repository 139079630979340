import React from 'react'
import { StyledDivider } from './style'

interface DividerProps {
  marginTop?: number
  marginBottom?: number
}

export const Divider = ({
  marginTop = 30,
  marginBottom = 30,
}: DividerProps) => {
  return <StyledDivider style={{ marginTop, marginBottom }} />
}
