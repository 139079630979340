import styled from 'styled-components'
import { Button } from 'antd'

export const ProfileMenuContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.profileBoxShadow};
  width: 280px;
  font-family: ${(props) => props.theme.profileFontFamily};
`

export const WelcomeText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.profileBlack};
  margin-bottom: 8px;
  line-height: 1.2;

  span {
    font-size: 16px;
    font-weight: normal;
    color: ${(props) => props.theme.profileName};
  }
`

export const MenuItem = styled.div`
  padding: 12px 0;
  font-size: 16px;
  color: ${(props) => props.theme.profileBlack};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  &:hover {
    color: ${(props) => props.theme.profileHover};
  }
`

export const AddCompanyButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
`

export const ExitButton = styled.div`
  margin-top: 12px;
  color: ${(props) => props.theme.profileHover};
  cursor: pointer;
  text-align: center;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`
