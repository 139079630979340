import { StyledLoginForm } from './style'
import { Input } from '../../Input'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'

export interface RegisterProps {
  appendData: any
}

export const RegisterForm = ({ appendData }: RegisterProps) => {
  const intl = useIntl()

  const handleSubmit = (values: any) => {
    appendData(values)
  }

  return (
    <StyledLoginForm onFinish={handleSubmit}>
      <div className={'inputs'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'register.title' })}
        </div>
        <div className={'fulname'}>
          <div>
            {intl.formatMessage({ id: 'register.name' })}
            <Input size={'large'} required name={'name'} />
          </div>
          <div>
            {intl.formatMessage({ id: 'register.surname' })}
            <Input size={'large'} required name={'surname'} />
          </div>
        </div>
        <div className={'email'}>
          {intl.formatMessage({ id: 'register.e_mail' })}
          <Input size={'large'} required name={'email'} />
        </div>
        <div className={'email_again'}>
          {intl.formatMessage({ id: 'register.e_mail_again' })}
          <Input size={'large'} required name={'reemail'} />
        </div>
        <div className={'pw-wrap'}>
          <div className={'pw-first'}>
            {intl.formatMessage({ id: 'register.password' })}
            <Input password size={'large'} required name={'password'} />
          </div>
          <div className={'pw-scnd'}>
            {intl.formatMessage({ id: 'register.password_again' })}
            <Input
              password
              size={'large'}
              required
              name={'password_confirmation'}
            />
          </div>
        </div>
        <Button
          className={'cstm-btn'}
          label={intl.formatMessage({ id: 'register.register' })}
          size={'large'}
          htmlType={'submit'}
        />
        <Button
          label={intl.formatMessage({ id: 'register.use_google' })}
          className={'cstm-btn google'}
          icon={<img src={'/google.png'} />}
        />
        <div className={'no-acc-reg'}>
          {intl.formatMessage({ id: 'register.have_acc' })}
          <a href={'/login'}>{intl.formatMessage({ id: 'register.login' })}</a>
        </div>
      </div>
    </StyledLoginForm>
  )
}
