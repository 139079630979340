import { Tabs as AntdTabs } from 'antd'
import { Tab } from 'rc-tabs/lib/interface'

export interface TabsProps {
  type?: 'line' | 'card' | 'editable-card'
  activeKey?: string
  onChange?: (activeKey: string) => void
  children?: React.ReactNode
  defaultActiveKey?: string
  tabBarExtraContent?:
    | React.ReactNode
    | { left?: React.ReactNode; right?: React.ReactNode }
  items?: Tab[]
}

export const Tabs = ({
  type,
  activeKey,
  onChange,
  children,
  defaultActiveKey,
  tabBarExtraContent,
  items,
}: TabsProps) => {
  return (
    <AntdTabs
      type={type}
      activeKey={activeKey}
      onChange={onChange}
      defaultActiveKey={defaultActiveKey}
      tabBarExtraContent={tabBarExtraContent}
      items={items}
    >
      {children}
    </AntdTabs>
  )
}
