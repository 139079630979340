import React, { useState, useEffect, useRef } from 'react'
import { StyledSecondHeader } from './style'
import { useIntl } from 'react-intl'
import { Button, Icon, Search } from 'ui'
import { useCategoryState } from '../../../context'
import { CategoryData } from 'types/CategoryData'
import CategoryMenu from '../../../../ui/Menu/CategoryMenu'
import { useNavigate } from 'react-router-dom'

import { Dropdown, MenuProps } from 'antd'
import ProfileMenu from '../ProfileMenu'
import CartDrawer from 'ui/Drawer/Drawers/CartDrawer'
import { useCartState } from 'public/context/CartContext'
import { useAuth } from '../../../../hooks/useAuth'

const DefaultSecondHeader = () => {
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState<boolean>(false)
  const [menuData, setMenuData] = useState<CategoryData[]>([])
  const [showDropdownOverlay, setShowDropdownOverlay] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const { priceTotal } = useCartState()
  const { category } = useCategoryState()
  const { user } = useAuth()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <ProfileMenu fullName={`${user?.name ?? ''} ${user?.surname ?? ''}`} />
      ),
    },
  ]

  const menuRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (category ? category : []) {
      const categories: CategoryData[] =
        Object.values(category ? category : []) || []
      setMenuData(categories)
    }
  }, [category])

  const toggleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    setShowMenu(!showMenu)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false)
    }
  }

  const handleNavigateProducts = () => {
    navigate(`products/all`)
  }

  const handleOnClickFarms = () => {
    navigate(`farms/all`)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onCartClose = () => {
    setIsCartDrawerOpen(false)
  }

  return (
    <StyledSecondHeader>
      <div className="content-wrapper">
        <div className="content">
          <div className="block">
            <div>
              {showMenu && !!menuData.length && (
                <div ref={menuRef} className={'menu-pos'}>
                  <CategoryMenu categories={menuData} />
                </div>
              )}
              <Button
                label={intl.formatMessage({ id: 'general.products' })}
                onMouseEnter={toggleMenu}
                icon={<Icon name={'arrow-down-white'} type={'small'} />}
                iconPosition={'end'}
                size={'large'}
                onClick={handleNavigateProducts}
              />
            </div>
            <Button
              type="primary"
              size={'small'}
              label={intl.formatMessage({ id: 'general.manufacturers' })}
              onClick={handleOnClickFarms}
            />
            <Button
              type="text"
              label={intl.formatMessage({ id: 'general.sales' })}
              href={'/products/all/discounted'}
            />
            <Button
              type="text"
              label={intl.formatMessage({ id: 'general.news' })}
              href={'/products/all/new'}
            />
          </div>

          <Search prefix={<Icon name={'search-icon-black'} />} />
          <div className="block">
            <Button
              type="text"
              label={intl.formatMessage({ id: 'general.whish_list' })}
              icon={<Icon name={'heart-black'} />}
            />

            {user?.authenticated ? (
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                onOpenChange={(open) => setShowDropdownOverlay(open)}
              >
                <Button
                  type="text"
                  label={intl.formatMessage({ id: 'general.profile' })}
                  icon={<Icon name={'user-profile-black'} />}
                />
              </Dropdown>
            ) : (
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.profile' })}
                icon={<Icon name={'user-profile-black'} />}
                onClick={() => navigate('/login')}
              />
            )}

            <div className={'second-header-btn-wrapper'}>
              <div className={'second-header-cart-total'}>
                {priceTotal.toFixed(2) + ' €'}
              </div>
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.my_basked' })}
                icon={<Icon name={'shopping-cart-black'} />}
                onClick={() => setIsCartDrawerOpen(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <CartDrawer
        onCartClose={onCartClose}
        isCartDrawerOpen={isCartDrawerOpen}
      />
      {showMenu && <div className="overlay" />}
      {showDropdownOverlay && <div className="overlay" />}
    </StyledSecondHeader>
  )
}

export default DefaultSecondHeader
