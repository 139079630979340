import styled from "styled-components";

interface FarmGaleriesWrapperProps { }

export const FarmDeliveriesWrapper = styled.div<FarmGaleriesWrapperProps>`
  padding:0 20px;
  
  .custom-collapse {
   background: none;
   border-radius : 0;
   border:0;  
  }

  .custom-collapse .ant-collapse-item:nth-child(odd){
   background-color: #F5F5F5;
  }

  .custom-collapse .ant-collapse-item:nth-child(even){
    background-color: #ffffff;
  }


  .custom-collapse .ant-collapse-item  {
    border-bottom: 2px solid #CDD6DA;
  }

  .custom-collapse .ant-collapse-header-text {
    font-size: 16px;
    font-weight: 600;
    color: #2A1A1A;
  }

  .custom-collapse .ant-collapse-content
  {
    border: 0;
  }

  .custom-collapse>.ant-collapse-item:last-child {
    border-radius: 0;
  }


  .custom-collapse .ant-collapse-header {
   background: none; 
   border-radius: 0;
   padding: 13px 20px 7px 20px;
  }

 .custom-collapse .ant-collapse-content-box{
   padding: 0px 20px 10px 20px;
  }

  .custom-collapse .ant-collapse-arrow {
    color: inherit;
  }

  .delivery-item-title {
    display: block;
    margin-bottom:16px;
    font-size: 16px;
    font-weight:400;
  }
`