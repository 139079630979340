import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, DatePicker, Input, Table } from 'ui'
import dayjs from 'dayjs'
import Filter from '../../../components/Filter'
import EnumSelect from '../../../Selects/EnumSelect'

const OrderListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({})
  const intl = useIntl()

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'orders.customer' }),
      dataIndex: 'customer_name',
    },
    {
      title: intl.formatMessage({ id: 'orders.number' }),
      dataIndex: 'number',
    },
    {
      title: intl.formatMessage({ id: 'orders.status' }),
      dataIndex: 'status',
    },
    {
      title: intl.formatMessage({ id: 'orders.payment_status' }),
      dataIndex: 'payment_status',
    },
    {
      title: intl.formatMessage({ id: 'orders.total' }),
      dataIndex: 'total',
      render: (value: string) => '€ ' + value,
    },
  ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.orders' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Filter onFinish={setAdvancedSearch}>
          <DatePicker
            label={intl.formatMessage({ id: 'general.date_from' })}
            name="date_from"
          />
          <DatePicker
            label={intl.formatMessage({ id: 'general.date_to' })}
            name="date_to"
          />
          <EnumSelect
            label={intl.formatMessage({ id: 'orders.order_status' })}
            name="status"
            code="OrderStatusType"
          />
          <Input
            label={intl.formatMessage({ id: 'general.search' })}
            name="search"
          />
          <Button
            type="primary"
            label={intl.formatMessage({ id: 'general.select' })}
            htmlType="submit"
          />
        </Filter>
        <Table url="/api/v2/orders" columns={columns} filter={advancedSearch} />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default OrderListPage
