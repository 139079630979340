import { styled } from 'styled-components'

export const StyledProcessPurchase = styled('div')`
  .form-summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10%;
    padding-top: 30px;

    .form-wrapper {
      width: 100%;

      .form-title {
        font-size: 24px;
        font-weight: 400;
        padding-bottom: 18px;
      }
    }
  }
`
