import { useIntl } from 'react-intl'
import { StyledCartWarning } from './style'

const CartWarning = () => {
  const intl = useIntl()
  return (
    <StyledCartWarning>
        <div className={'cart-warning-container'}>
          {intl.formatMessage({ id: 'cart.warning_messange' })}
        </div>
    </StyledCartWarning>
  )
}

export default CartWarning
