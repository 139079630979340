import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { buffer } from "ol/extent";
import { Vector as VectorSource } from "ol/source";
import { Feature, Map, MapBrowserEvent } from "ol";
import { Point } from "ol/geom";
import { IMapComponentProps, OnFeatureClickCallback, OnUnselectCallback } from "./MapComponent";
import { SelectedStatus } from "./enums";
import { config } from "./config";
import { useAppContext } from "./AppContext";
import { createMap, handleClusterClick, onPointerMove, zoomToFeature } from "./mapFunctions";
import { IFeature, IPadding } from "./interfaces";
import { getPointResolution } from "ol/proj";

const DEFAULT_DPI = 96.198892;
const inchesPerMeter = 1000 / 25.4;

const _padding: IPadding = { left: 0, duration: 0 };
const featureLayerId = config.defaults.featureLayerId;

const getBuffer = (feature: Feature) => buffer(feature.getGeometry().getExtent(), 100);

let _map: MutableRefObject<Map>;
let sourceRef: MutableRefObject<VectorSource>;
let selectedSourceRef: MutableRefObject<VectorSource>;

let onFeatureClick: MutableRefObject<OnFeatureClickCallback>;
let onUnselectFeature: MutableRefObject<OnUnselectCallback>;

export const useMap = (target: MutableRefObject<HTMLDivElement>, props: IMapComponentProps): Map => {
    _map = useRef<Map>(null);
    const centerPoint = new Point([
        props.startPosition.x | config.defaults.center[0],
        props.startPosition.y,
        config.defaults.center[1]
    ]);
    sourceRef = useRef<VectorSource>(new VectorSource());
    selectedSourceRef = useRef<VectorSource>(new VectorSource());

    onFeatureClick = useRef<OnFeatureClickCallback>(props.onSelectFeature);
    onFeatureClick.current = useMemo(() => props.onSelectFeature, [props.onSelectFeature]);
    onUnselectFeature = useRef<OnUnselectCallback>(props.onUnselectFeature);
    onUnselectFeature.current = useMemo(() => props.onUnselectFeature, [props.onUnselectFeature]);

    const { setMapClick } = useAppContext();

    useEffect(() => {
        if (target.current) {
            _map.current = createMap(target.current, sourceRef.current, props.startPosition);

            _map.current.on("pointermove", (evt) => onPointerMove(evt, _map.current, target.current));

            _map.current.on("singleclick", (evt) => {
                if (evt.dragging) {
                    return;
                }
                const hasFeatureAtPixel = _map.current.hasFeatureAtPixel(evt.pixel, {
                    layerFilter: (layer) => layer.get("id") === featureLayerId
                });

                if (hasFeatureAtPixel) {
                    _map.current.forEachFeatureAtPixel(
                        evt.pixel,
                        (feature) => handleObjectClick(feature as Feature, evt),
                        {
                            layerFilter: (layer) => layer.get("id") === featureLayerId
                        }
                    );
                } else {
                    resetSelectState();
                }
                // setMapClick(evt);
            });

            _map.current.on("moveend", onMoveEnd);

            // @ts-ignore
            window.MAP = _map;

            window.onresize = () =>
                setTimeout(function () {
                    _map.current.updateSize();
                }, 200);

            window.visualViewport.onresize = () =>
                setTimeout(function () {
                    _map.current.updateSize();
                }, 300);

            const handleObjectClick = (feature: Feature, evt: MapBrowserEvent<any>) => {
                const clusteredFeatures: Feature[] = feature.get("features");
                if (clusteredFeatures.length) {
                    if (clusteredFeatures.length === 1) {
                        if (clusteredFeatures[0].get("SELECTED") === SelectedStatus.Default) {
                            resetSelectState();
                            clusteredFeatures[0].set("SELECTED", SelectedStatus.Selected);
                        } else {
                            // resetSelectState();
                            const view = _map.current.getView();
                            view.fit(getBuffer(clusteredFeatures[0]), {
                                padding: [0, 0, 0, (view as any).paddingLeft],
                                duration: 500
                            });
                        }
                        onFeatureClick.current?.(clusteredFeatures[0]);
                        setMapClick(evt);
                    } else {
                        handleClusterClick(clusteredFeatures, evt.map);
                        setMapClick(null);
                    }
                } else {
                    resetSelectState();
                }
            };
        }
    }, []);

    useEffect(() => {
        if (props?.featureData?.length) {
            sourceRef.current.clear();
            sourceRef.current.addFeatures(createFeatures(props.featureData));
        } else {
            sourceRef.current.clear();
        }
    }, [props.featureData]);

    useEffect(() => {
        props.zoomToFeature && zoomToFeature(props.zoomToFeature);
    }, [props.zoomToFeature]);

    useEffect(() => {
        _padding.left =
            props.padding.left === 0 && _padding.left > 0
                ? -_padding.left
                : props.padding.left > 0
                ? props.padding.left
                : 0;
        const view = _map.current.getView();
        const maxZoom = view.getZoom();
        const center = view.getCenter();
        centerPoint.setCoordinates(center);
        view.fit(centerPoint, {
            padding: [0, 0, 0, _padding.left],
            maxZoom,
            duration: props.padding.duration,
            callback: () => {
                (view as any).paddingLeft = _padding.left < 0 ? 0 : _padding.left;
            }
        });
    }, [props.padding.left, props.padding.duration]);

    const createFeatures = (featureData: IFeature[]) =>
        featureData.map((point: IFeature) => {
            const geometry = new Point(point.coordinates);
            geometry.transform("EPSG:4326", "EPSG:3857");
            const feature = new Feature(geometry);
            feature.setId(point.id);
            feature.setProperties({ SELECTED: 0 });
            return feature;
        });

    const onMoveEnd = (e) => {
        const view = e.target.getView();
        const center = view.getCenter();
        const pointResolution = getPointResolution(view.getProjection(), view.getResolution(), center, "m");
        const scale = roundToPlaceValue(Math.round(parseFloat(pointResolution.toString()) * inchesPerMeter * DEFAULT_DPI));

        document.dispatchEvent(new CustomEvent("mapscale", { detail: scale }));
    };

    return _map.current;
};

const resetSelectState = () => {
    sourceRef.current.forEachFeature((feature: Feature) => {
        if (feature.get("SELECTED") !== SelectedStatus.Default) {
            feature.set("SELECTED", SelectedStatus.Default);
            onUnselectFeature.current?.(feature);
        }
    });
    selectedSourceRef.current.clear();
};

const roundToPlaceValue = (number: number): number => {
    // example: 3980000 => 4000000 etc
    const placeValue = Math.floor(number).toString().length - 1;
    const factor = 10 ** placeValue;

    return Math.round(number / factor) * factor;
};