enum FarmCardTabs {
    DESCRIPTION = 'description',
    PRODUCTS='products',
    WORKING_TIME = 'working_time',
    DELIVERY = 'delivery',
    GALLERY = 'gallery',
    PARTNERS = 'partners',
    MEMBERS = 'members'
}

export default FarmCardTabs;