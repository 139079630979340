import styled from 'styled-components'

export const StyledCategoryMenu = styled.div`
    display: flex;
    position: absolute;
    top: 80px;
    

    .wrapper-menu {
        display: flex;
        position: relative;
        z-index: 40;
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .category,
    .subcategory,
    .subsubcategory {
        position: relative;
        left: 0;
        min-width: 373px;
        min-height: 277px;
        max-width: 373px;
        max-height: 277px;
        overflow-y: auto;
        border-right: 1px solid ${(props) => props.theme.brand01};
        background-color: ${(props) => props.theme.white};

        li {
            font-size: 16px;
            cursor: pointer;
            font-family: 'Albert Sans', sans-serif;
            position: relative;
            text-align: left;
            font-weight: 400;
            color: ${(props) => props.theme.colorText};
            padding: 12px 0 12px 7%;
            justify-content: space-between;

            &:after {
                content: '';
                display: block;
                width: 85%;
                height: 1px;
                background-color: ${(props) => props.theme.menuBorderBottom};
                position: absolute;
                bottom: 0;
                left: 7%;
            }

            .icon {
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 12px;
            }
            
        }

        li.no-underline:after {
            width: 0;
        }

        /* Hide scrollbar for Webkit browsers (Chrome, Safari) */

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.subcategory {
    position: absolute;
    left: 350px;
    top: 0;
}

.subsubcategory {
    position: absolute;
    left: 700px;
    top: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}


a {
    text-decoration: none;
    color: ${(props) => props.theme.brand01};
}

li:hover {
    background-color: ${(props) => props.theme.lightGrey};
}
`
