import React from 'react'
import { Validations } from 'interfaces'
import { InputNumber as AntdNumberInput } from 'antd'
import { Form } from 'antd'
import useFormValidation from '../../utils/useFormValidation'
import { Rule } from 'rc-field-form/lib/interface'

export interface InputNumberProps extends Validations {
  onChange?: (value: number | string | undefined | null) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  size?: 'large' | 'middle' | 'small'
  value?: number
  defaultValue?: number
  min?: number
  max?: number
  maxLength?: number
  disabled?: boolean
  label?: string
  rules?: Rule[]
  name?: (string | number)[] | string | number
  formatter?: (
    value: any | undefined,
    info: {
      userTyping: boolean
      input: string
    }
  ) => string
  parser?: (displayValue: string | undefined) => any
  initialValue?: number
  precision?: number
  step?: number
  className?: string
  suffix?: React.ReactNode
  controls?: boolean | { upIcon?: React.ReactNode; downIcon?: React.ReactNode }
}

export const InputNumber = ({
  onChange,
  size,
  value,
  defaultValue,
  onBlur,
  min,
  max,
  maxLength,
  disabled,
  label,
  name,
  rules,
  validations,
  formatter,
  parser,
  initialValue,
  precision,
  step,
  className,
  suffix,
  controls,
}: InputNumberProps) => {
  const { formValidations } = useFormValidation()

  return (
    <Form.Item
      label={label}
      name={name}
      rules={validations ? formValidations(validations) : rules}
      initialValue={initialValue}
      className={className}
    >
      <AntdNumberInput
        onChange={onChange}
        size={size}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
        min={min}
        suffix={suffix}
        max={max}
        controls={controls}
        formatter={formatter}
        maxLength={maxLength}
        className={
          !className ? (disabled ? 'ant-input-disabled' : '') : className
        }
        disabled={disabled}
        parser={parser}
        precision={precision}
        step={step}
      />
    </Form.Item>
  )
}
