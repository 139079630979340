import { StyledDrawer, StyledFooter } from './style'
import { BannerProps, ImageProps } from '../../../types/BannerData'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import { Form } from 'antd'
import { Button } from '../../Button'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useMemo, useState } from 'react'
import { BannerImages } from '../../UploadImage/banners/BannerImages'
import { UploadFile } from 'antd/es/upload/interface'

interface DrawerProps {
  open?: boolean
  onClose?: () => void
  banner: BannerProps | null
  updateBanner: (values: any) => void
  updateSuccess?: boolean
  setUpdateSuccess?: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void
}

export const EditBannerDrawer = ({
  open,
  onClose,
  banner,
  updateBanner,
  updateSuccess,
  setUpdateSuccess,
}: DrawerProps) => {
  const [imagesData, setImagesData] = useState<any>([])
  const [removedImageIds, setRemovedImageIds] = useState<number[]>([])
  const intl = useIntl()
  const [form] = useForm()

  useEffect(() => {
    if (banner) {
      form.setFieldsValue({
        title: banner.title,
        url: banner.url,
      })

      const initialImagesData = banner.images.map((image, index) => ({
        uid: image.id || index.toString(),
        id: image.id,
        name: image.image_name || `Image ${index + 1}`,
        status: 'done',
        url: image.url_original,
        thumbUrl: image.url_thumbnail,
        url_to: image.url_to || '',
        originFileObj: null,
      }))

      setImagesData(initialImagesData)
    }

    if (updateSuccess && setUpdateSuccess) {
      form.resetFields()
      setImagesData([])
      setRemovedImageIds([])

      setUpdateSuccess(false)
    }
  }, [banner, form, updateSuccess])

  const memoizedImages = useMemo(() => {
    return banner?.images ? banner.images : []
  }, [banner?.images])

  const handleSubmit = () => {
    const values = form.getFieldsValue()
    const formData = new FormData()

    formData.append('title', values.title)
    formData.append('url', values.url)

    const existingImages = imagesData.filter(
      (file: any) => !file?.originFileObj
    )
    const newImages = imagesData.filter((file: any) => file?.originFileObj)

    existingImages.forEach((file: any, index: number) => {
      formData.append('existing_image_ids[]', file?.id || '')
      formData.append('existing_url_to[]', file?.url_to || '')
    })

    newImages.forEach((file: any, index: number) => {
      formData.append('images[]', file?.originFileObj)
      formData.append('url_to[]', file?.url_to || '')
    })

    if (removedImageIds.length > 0) {
      removedImageIds.forEach((id: any) => {
        formData.append('removed_image_ids[]', id)
      })
    }

    updateBanner(formData)
  }

  const Footer = () => {
    return (
      <StyledFooter>
        <Button
          label={intl.formatMessage({ id: 'general.submit' })}
          htmlType={'submit'}
          onClick={handleSubmit}
        />
      </StyledFooter>
    )
  }

  return (
    <StyledDrawer open={open} closable onClose={onClose} footer={Footer()}>
      <Form layout={'vertical'} form={form} name={'banner_edit'}>
        <Input
          value={banner?.title}
          label={intl.formatMessage({ id: 'general.name' })}
          name={'title'}
        />

        {banner?.banner_type === 'static' ? (
          <Input
            value={banner?.url}
            type={'text'}
            label={intl.formatMessage({ id: 'banners.url' })}
            name={'url'}
          />
        ) : (
          ''
        )}
        <Form.Item name={'images'}>
          <BannerImages
            type={banner?.banner_type}
            images={memoizedImages}
            form={form}
            banner={banner || undefined}
            imagesData={imagesData}
            setImagesData={setImagesData}
            setRemovedImageIds={setRemovedImageIds}
          />
        </Form.Item>
      </Form>
    </StyledDrawer>
  )
}
