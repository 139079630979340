import styled from 'styled-components'

export const StyledAllDiscountProducts = styled('div')`
  .products-filters-container {
    display: flex;
    flex-direction: row;

    .ant-spin-nested-loading{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .form-container {
      padding-left: 200px;
      padding-right: 40px;
    }

    .products-wrapper {
      padding-right: 200px;

      .products {
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        justify-content: end;
      }

      .pagination {
        display: flex;
        justify-content: center;
        padding: 50px 0;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 150px;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 100px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      justify-content: center;
      .form-container {
        display: none;
      }

      .form-container {
        padding: 0 20px 0 20px;
      }
      .products-wrapper {
        padding: 0 20px 0 20px;
        .products {
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .products-filters-container {
      .products-wrapper {
        padding: 0 10px 0 10px;
      }
    }
  }
`
