import styled from 'styled-components'
import { Carousel } from 'antd'

export const StyledCarousel = styled(Carousel)`
  margin: 0 0 10px 0;
  flex-grow: 1;

  .carusel-banner-image {
    width: 100%;
  }

  img {
    width: max-content;
  }
  li.slick-active {
    img {
      border: 2px solid ${(props) => props.theme.white} !important;
    }
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;

    .slick-active {
    }
  }

  .slick-list {
    min-height: 300px;
  }

  .custom-dot {
    height: 116px;
    width: 116px;
  }

  .banner-image-wrapper {
    padding: 50px 200px 50px 200px;
  }

  @media only screen and (max-width: 1200px) {
    .banner-image-wrapper {
      padding: 30px 100px 30px 100px;
    }
  }

  @media only screen and (max-width: 768px) {
    .banner-image-wrapper {
      padding: 20px 50px 20px 50px;
    }
  }

  @media only screen and (max-width: 414px) {
    .banner-image-wrapper {
      padding: 20px 20px 20px 20px;
      height: 250px;

      a img {
        height: 250px;
        object-fit: cover;
        object-position: left;
      }
    }
  }
`

export const StyledCustomPaging = styled('div')`
  position: relative;
  height: 116px;
  width: 116px;

  &.custom-dot {
    position: absolute;
    left: -42px;
  }
`

export const StyledDotContainer = styled('div')`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
`

export const StyledImage = styled('img')`
  position: relative;
  top: 25px;
  left: 0;
  max-width: 116px;
  max-height: 116px;
  object-fit: cover;
  border-radius: 4px;
`

export const StyledAppendDotsContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`

export const StyledAppendDotsInner = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 110px;
  align-items: center;

  li {
    width: 24px !important;
  }
`
