import React, { useState } from 'react'
import { message, Image, UploadProps, UploadFile, Upload } from 'antd'
import { useIntl } from 'react-intl'
import { StyledNewProductsGallery } from './style'
import { RcFile } from 'antd/es/upload/interface'
import { options } from '../../../config/config'
import { getBase64 } from '../../../utils/productCardHelpers'
import { BeforeUpload } from '../../../utils/imageHelpers'
import { FallbackImage } from '../fallBack/FallBackImage'

interface NewProductsGalleryProps {
  onValidate: (isValid: boolean) => void
}

export const NewProductsGallery = ({ onValidate }: NewProductsGalleryProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const intl = useIntl()

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    const maxFiles = options.images.maxImages

    if (newFileList.length > maxFiles) {
      message.error(
        intl.formatMessage({
          id: 'validation.max_images',
        })
      )
      return
    }

    setFileList(newFileList)

    onValidate(newFileList.length > 0)
  }

  const props: UploadProps = {
    name: 'image',
    multiple: true,
    fileList: fileList,
    onChange: handleChange,
    listType: 'picture-card',
    beforeUpload: BeforeUpload,
    onPreview: handlePreview,
    onDrop() {},
  }
  return (
    <StyledNewProductsGallery {...props}>
      <p className="ant-upload-drag-icon">
        <img src={'/svgs/upload-image.svg'} alt="upload icon" />
      </p>
      <p className="ant-upload-text">
        <a>{intl.formatMessage({ id: 'gallery.upload_file' })} </a>
        {intl.formatMessage({ id: 'gallery.or_here' })}
      </p>
      <div className={'previews'}>
        <FallbackImage
          src={previewImage}
          type={'ProductCard'}
          preview={false}
        />
      </div>
    </StyledNewProductsGallery>
  )
}
