import { StyledNewProductForm } from './style'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import { InputNumber } from '../../InputNumber'

export const NewProductMacros = () => {
  const intl = useIntl()

  return (
    <StyledNewProductForm>
      <div className={'ingridients'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'macros.ingredients' })}
        </div>
        <Input
          validations={'required'}
          name={'ingredients'}
          initialValue={''}
        />
      </div>

      <div className={'macros-wrap'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'macros.title' })}
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.kcal' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'kcal'}
              initialValue={0}
            />
          </div>
          <div className={'fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.fat' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'fat'}
              initialValue={0}
            />
          </div>
          <div className={'saturated-fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.saturated_fat' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'saturated_fat'}
              initialValue={0}
            />
          </div>
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.carbohydrates' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'carbs'}
              initialValue={0}
            />
          </div>
          <div className={'fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.sugars' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'sugars'}
              initialValue={0}
            />
          </div>
          <div className={'saturated-fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.protein' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'protein'}
              initialValue={0}
            />
          </div>
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.salt' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'minZeroNumber'}
              name={'salt'}
              initialValue={0}
            />
          </div>
        </div>

        <div className={'allergens'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'macros.allergens' })}
          </div>
          <Input style={{ minHeight: 100 }} name={'allergens'} />
        </div>

        <div className={'conditions'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'macros.storing_conditions' })}
          </div>
          <Input style={{ minHeight: 100 }} name={'store_conditions'} />
        </div>
      </div>
    </StyledNewProductForm>
  )
}
