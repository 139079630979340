import styled from 'styled-components'

export const StyledCategoryAdmin = styled('div')`
  .sub-cat {
    margin-left: 30px;
  }

  .dragging {
    opacity: 0.5; /* Makes the dragged item semi-transparent */
  }

  .add-new {
    min-width: 400px;
    padding-left: 40px;
    padding-top: 40px;

    .ant-form {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
    }
  }

  .scrolltop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    zindex: 1000;
  }
`

export const StyledDraggable = styled('div')`
  .dragging {
    opacity: 0.5;
    transition: opacity 0.2s ease;
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
  }
`

export const StyledDroppable = styled('div')`
  .isOver {
    border: 2px dashed #007bff;
    background-color: rgba(0, 123, 255, 0.1);
    transition: border 0.2s ease, background-color 0.2s ease;
    max-width: 600px;
  }
`
