import { Checkbox } from '../Checkbox'
import { StyledQmarkCheckboxes } from './style'
import { useIntl } from 'react-intl'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { QualityMarkData } from '../../../types/ProductData'
import { QmarkFilterOptions } from 'types/FilterOptions'

interface QmarkProps {
  qmarks: QmarkFilterOptions[] | undefined
}
export const QualityMarkCheckboxes = ({ qmarks }: QmarkProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isEmpty, setIsEmpty] = useState<boolean>()

  const intl = useIntl()

  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (qmarks?.length === 0) {
      setIsOpen(false)
      setIsEmpty(true)
    } else {
      setIsOpen(true)
      setIsEmpty(false)
    }
  }, [qmarks])

  return (
    <StyledQmarkCheckboxes>
      <div
        className={'qmark-filter-title'}
        onClick={!isEmpty ? handleOnClick : undefined}
      >
        <div>{intl.formatMessage({ id: 'form.qmark_filter_title' })}</div>
        <div className={'icon'}>
          {isOpen ? <DownOutlined /> : <RightOutlined />}
        </div>
      </div>
      {isOpen && <Checkbox name="qmark" options={qmarks} filterView={true} />}
    </StyledQmarkCheckboxes>
  )
}
