import { Select, SelectOption } from '../Select'
import { useIntl } from 'react-intl'
import { CSSProperties } from 'react'
import { FarmsFilterOptions } from 'types/FilterOptions'

interface Props {
  farms: FarmsFilterOptions[] | undefined
}

export const FarmsSelector = ({ farms }: Props) => {
  const intl = useIntl()

  const style: CSSProperties = {
    minWidth: 245,
    minHeight: 45,
    marginBottom: 5,
    maxWidth: 245,
  }

  return (
    <div>
      <div className={'form-title'}>
        {intl.formatMessage({ id: 'form.farm_title' })}
      </div>
      <Select
        placeholder={intl.formatMessage({ id: 'form.select_farm' })}
        className="drop-down"
        showSearch={true}
        name={'farm'}
        size={'small'}
        allowClear
        style={style}
        children={farms?.map((farm) => (
          <SelectOption title={farm.name} key={`-${farm.id}`} value={farm.id}>
            {farm.name}
          </SelectOption>
        ))}
      />
    </div>
  )
}
