import React, { CSSProperties, useState } from 'react'
import { Select, SelectOption } from '../Select'
import { CategoryData } from '../../../types/CategoryData'
import { useIntl } from 'react-intl'
import useFormValidation from '../../../utils/useFormValidation'
import { Validations } from '../../../interfaces'
import { Rule } from 'rc-field-form/lib/interface'
import { useCategoryDispatch, useCategoryState } from '../../../public/context'
import { StyledCategorySelector } from './style'

export interface categoryProps extends Validations {
  displayFlex?: boolean
  admin?: boolean
  rules?: Rule[]
}

export const CategorySelector = ({
  displayFlex,
  admin,
  validations,
  rules,
}: categoryProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    CategoryData | undefined
  >(undefined)
  const [subcategories, setSubcategories] = useState<CategoryData[]>([])
  const [selectedSubcategory, setSelectedSubcategory] = useState<
    CategoryData | undefined
  >(undefined)
  const [subSubCategories, setSubSubCategories] = useState<CategoryData[]>([])
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<
    CategoryData | undefined
  >(undefined)

  const { category } = useCategoryState()
  const dispatch = useCategoryDispatch()
  const intl = useIntl()

  // Convert value to a number
  const handleCategoryChange = (value: string) => {
    const selected = categoriesObj?.find(
      (category) => category.id === Number(value)
    )
    setSelectedCategory(selected)

    setSelectedSubcategory(undefined)
    setSelectedSubSubCategory(undefined)
    setSubSubCategories([])
    setSubcategories(selected?.subcategories || [])
    dispatch({ type: 'SAVE_ACTIVE_CATEGORY', activeCategory: selected || null })
  }

  const handleSubcategoryChange = (value: string) => {
    const selectedSub = subcategories.find(
      (subcategory) => subcategory.id === Number(value)
    )
    setSelectedSubcategory(selectedSub)

    setSelectedSubSubCategory(undefined)
    setSubSubCategories(selectedSub?.subcategories || [])
  }

  const handleSubSubCategoryChange = (value: string) => {
    const selectedSubSub = subSubCategories.find(
      (subSubCategory) => subSubCategory.id === Number(value)
    )
    setSelectedSubSubCategory(selectedSubSub)
  }

  const categoriesObj: CategoryData[] =
    Object.values(category ? category : []) || []

  const styleProps: CSSProperties = {
    minWidth: 245,
    minHeight: 41,
    marginBottom: 0,
    maxWidth: 255,
  }

  const { formValidations } = useFormValidation()

  return (
    <StyledCategorySelector>
      <div className={'category-sel-wrap'}>
        <div>
          <div className={!admin ? 'form-title' : 'label'}>
            {!admin
              ? intl.formatMessage({ id: 'form.categories_title' })
              : intl.formatMessage({ id: 'general.group' })}
          </div>
          <div className={displayFlex ? 'displayFlex' : admin ? 'admin' : ''}>
            <Select
              placeholder={intl.formatMessage({
                id: 'form.select_main_category',
              })}
              className="drop-down"
              showSearch={false}
              name={'main-category'}
              size={'small'}
              allowClear
              style={styleProps}
              onChange={handleCategoryChange}
              value={selectedCategory?.id}
              rules={validations ? formValidations(validations) : rules}
            >
              {categoriesObj?.map((category) => (
                <SelectOption key={category.id} value={category.id}>
                  {category.name}
                </SelectOption>
              ))}
            </Select>

            <Select
              placeholder={intl.formatMessage({
                id: 'form.select_sub_category',
              })}
              className="drop-down"
              showSearch={false}
              name={'sub-category'}
              size={'small'}
              allowClear
              style={styleProps}
              onChange={handleSubcategoryChange}
              value={selectedSubcategory?.id}
              disabled={!selectedCategory && admin}
              rules={validations ? formValidations(validations) : rules}
            >
              {(selectedCategory ? subcategories : []).map((subcategory) => (
                <SelectOption key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </SelectOption>
              ))}
            </Select>

            <Select
              placeholder={intl.formatMessage({
                id: 'form.select_sub_subcategory',
              })}
              className="drop-down"
              showSearch={false}
              name={'sub-subcategory'}
              size={'small'}
              allowClear
              style={styleProps}
              onChange={handleSubSubCategoryChange}
              value={selectedSubSubCategory?.id}
              disabled={!selectedSubcategory && admin}
              rules={validations ? formValidations(validations) : rules}
            >
              {(selectedSubcategory ? subSubCategories : []).map(
                (subSubCategory) => (
                  <SelectOption
                    key={subSubCategory.id}
                    value={subSubCategory.id}
                  >
                    {subSubCategory.name}
                  </SelectOption>
                )
              )}
            </Select>
          </div>
        </div>
      </div>
    </StyledCategorySelector>
  )
}
