import * as React from "react";
import { FC } from "react";
import { Feature } from "ol";
import { DemoAttributeTable } from "./DemoAttributeTable";
import { Collapsible } from "./Collapsible";

export interface IDemoFeatures {
    identifiedObject: Feature;
    onZoomToFeature: (feature: Feature) => void;
}

export const DemoFeatures: FC<IDemoFeatures> = (props: IDemoFeatures) => {
    return (
        <Collapsible title="Objekti, objekta atribūti" defaultCollapsed={false}>
            <FeatureAttributes
                identifiedObject={props.identifiedObject}
                onZoomToFeature={props.onZoomToFeature}
            ></FeatureAttributes>
        </Collapsible>
    );
};

const FeatureAttributes = ({
    identifiedObject,
    onZoomToFeature
}: {
    identifiedObject: Feature;
    onZoomToFeature: (feature: Feature) => void;
}) => {
    return (
        <div className="list attributes">
            <div className="list-title">Uzklikots:</div>
            {identifiedObject && (
                <div className="feature" key={"feature" + identifiedObject.getId()}>
                    <DemoAttributeTable onZoomToFeature={onZoomToFeature} feature={identifiedObject} />
                </div>
            )}
        </div>
    );
};
