import React from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Tabs } from '../../../ui'
import InformationTab from './components/InformationTab'
import BasicInformation from './components/BasicInformation'

const TestPage2 = () => {
  const intl = useIntl()

  const items = [
    {
      key: 'information',
      label: intl.formatMessage({ id: 'project.information' }),
      children: <InformationTab />,
    },
    {
      key: 'basic_information',
      label: intl.formatMessage({ id: 'project.basic_information' }),
      children: <BasicInformation />,
    },
    {
      key: 'gallery',
      label: intl.formatMessage({ id: 'project.gallery' }),
      children: <>todo, need proper backend</>,
    },
  ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.deals' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.active_orders' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs items={items}></Tabs>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TestPage2
