import styled from 'styled-components'

export const StyledThirdHeader = styled('div')`

  @media only screen and (max-width: 1200px){
    display: none;
  }

  .product-name-bread-wrapper {
    flex-grow: 1;
    border-bottom: 1px solid ${(props) => props.theme.borderBottom};
    padding-bottom: 13px;

    .breadcrumbs-container {
      display: flex;
      justify-content: space-between;

      .breadcrumb {
        cursor: pointer;
      }

      div {
        span {
          a {
            text-decoration: none;
            color: ${(props) => props.theme.black};
          }
        }
      }
    }

    .product-name-and-qmarks-container {
      justify-content: space-between;
      align-items: center;
      padding: 32px 0px 0 0px;

      .product-name {
        font-weight: 500;
        font-size: 42px;
        line-height: 38px;
        margin: 9px;
        margin-left: 0;
      }

      .qmarks {
        img {
          width: 36px;
          height: 24px;
        }
      }
    }
  }
`
