import React from 'react'
import { DotPosition } from 'antd/es/carousel'
import { StyledCarousel } from './style'
import { ProductData, ProductImageProps } from 'types/ProductData'
import { Card } from '../Card'
import { CategoryCard } from 'ui/Card/CategoryCard'
import { CategoryData } from 'types/CategoryData'

export interface ResponsiveObject {
  breakpoint: number
  settings: 'unslick' | Settings
}

export interface Settings {
  slidesToShow?: number | undefined
}

export interface CarouselProps {
  arrows?: boolean
  autoplay?: boolean
  autoplaySpeed?: number
  adaptiveHeight?: boolean
  dotPosition?: DotPosition
  dots?: boolean | { className?: string }
  draggable?: boolean
  fade?: boolean
  infinite?: boolean
  speed?: number
  easing?: string
  effect?: 'scrollx' | 'fade'
  afterChange?: (current: number) => void
  beforeChange?: (current: number, next: number) => void
  waitForAnimate?: boolean
  productImages?: ProductImageProps[]
  slidesToShow?: number
  slidesPerRow?: number
  products?: ProductData[]
  background?: boolean
  dotsClass?: string
  popularCategory?: CategoryData[]
  responsive?: ResponsiveObject[]
  customDots?: boolean
}

export const Carousel = ({
  arrows,
  autoplay,
  autoplaySpeed,
  adaptiveHeight,
  dotPosition,
  dots,
  draggable,
  fade,
  infinite,
  speed,
  easing,
  effect,
  afterChange,
  beforeChange,
  waitForAnimate,
  slidesPerRow,
  slidesToShow,
  products,
  popularCategory,
  responsive,
  customDots = true,
}: CarouselProps) => {
  const showDots = customDots && (dots || (products && products.length > 1))

  return (
    <StyledCarousel
      responsive={responsive}
      arrows={arrows}
      autoplay={autoplay}
      autoplaySpeed={autoplaySpeed}
      adaptiveHeight={adaptiveHeight}
      dotPosition={dotPosition}
      dots={showDots}
      draggable={draggable}
      fade={fade}
      infinite={infinite}
      speed={speed}
      easing={easing}
      effect={effect}
      afterChange={afterChange}
      beforeChange={beforeChange}
      waitForAnimate={waitForAnimate}
      slidesPerRow={slidesPerRow}
      slidesToShow={slidesToShow}
    >
      {popularCategory &&
        popularCategory.length > 0 &&
        popularCategory.map((category, index) => (
          <CategoryCard
            key={`category-${index}`}
            category={category}
            bordered={true}
            hoverable={true}
          />
        ))}

      {products &&
        products.length > 0 &&
        products.map((product, index) => (
          <div key={`product-${index}`}>
            <Card product={product} imageUrl={product.image} hoverable />
          </div>
        ))}
    </StyledCarousel>
  )
}
