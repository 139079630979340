import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import {
  Button,
  Tabs,
  Table,
  DatePicker,
  Dropdown,
  Select,
  SelectOption,
  Modal,
} from 'ui'
import Filter from 'admin/components/Filter'
import { StyledButtonList } from 'styles/buttonList'
import dayjs from 'dayjs'
import useQueryApiClient from '../../../../utils/useQueryApiClient'
import FileDownload from 'js-file-download'
import { MenuProps } from 'antd/es/menu'
import { Form } from 'antd'
import FarmActivityStatusSelect from '../../../Selects/FarmActivityStatusSelect'

const FarmListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({})
  const [showCreateModal, setShowCreateModal] = useState<boolean>()

  const intl = useIntl()
  const [form] = Form.useForm()

  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/farms`,
      method: 'POST',
    },
    onSuccess: (response) => {
      FileDownload(response, `Fidavista_${dayjs().format('YYYY-MM-DD')}.xml`)
    },
  })

  const { appendData: syncProductRemainders, isLoading } = useQueryApiClient({
    request: {
      url: `api/v2/farms/syncProductRemainders`,
      method: 'POST',
    },
  })

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/farms/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      )
    },
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'farms.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'farms.reg_nr' }),
      dataIndex: 'reg_nr',
    },
    {
      title: intl.formatMessage({ id: 'farms.pvd_nr' }),
      dataIndex: 'pvd_nr',
    },

    {
      title: intl.formatMessage({ id: 'farms.contact_name' }),
      dataIndex: 'contact_name',
    },
    {
      title: intl.formatMessage({ id: 'farms.contact_phone' }),
      dataIndex: 'contact_phone',
    },
    {
      title: intl.formatMessage({ id: 'farms.status' }),
      dataIndex: 'public',
      render: (value: boolean, record: any) => {
        if (!record.confirmed) {
          return intl.formatMessage({ id: 'farms.confirmed' })
        }

        if (value) {
          return intl.formatMessage({ id: 'farms.public' })
        } else {
          return intl.formatMessage({ id: 'farms.hidden' })
        }
      },
    },
    {
      title: intl.formatMessage({ id: 'farms.owner_name' }),
      dataIndex: 'owner_name',
    },
    {
      title: intl.formatMessage({ id: 'farms.owner_email' }),
      dataIndex: 'owner_email',
    },
    {
      title: intl.formatMessage({ id: 'farms.activitie_status' }),
      dataIndex: 'activitie_status',
    },
    {
      title: intl.formatMessage({ id: 'farms.progres' }),
      dataIndex: 'progres',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
  ]

  const items = [
    {
      key: 'active_farms',
      label: intl.formatMessage({ id: 'navigation.farms' }),
      children: (
        <>
          <Filter onFinish={setAdvancedSearch}>
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_from' })}
              name="date_from"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_to' })}
              name="date_to"
            />
            <Select
              label={intl.formatMessage({ id: 'farms.status' })}
              name="public"
            >
              <SelectOption key={1} value={1}>
                {intl.formatMessage({ id: 'farms.public' })}
              </SelectOption>
              <SelectOption key={2} value={0}>
                {intl.formatMessage({ id: 'farms.hidden' })}
              </SelectOption>
              <SelectOption key={3} value={3}>
                {intl.formatMessage({ id: 'farms.unaccepted' })}
              </SelectOption>
            </Select>
            <FarmActivityStatusSelect
              name="activitie_status"
              label={intl.formatMessage({ id: 'farms.activitie_status' })}
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/farms"
            columns={columns}
            filter={advancedSearch}
          />
        </>
      ),
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv') {
      exportData({ format: e.key }, [], e.key)
    }
  }

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: '1',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ]

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  }

  const onCloseModal = () => {
    setShowCreateModal(false)
    form.resetFields()
  }

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.farms' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
              <Button
                label={intl.formatMessage({ id: 'general.create' })}
                type="primary"
                onClick={() => setShowCreateModal(true)}
              />
              <Button
                label={intl.formatMessage({
                  id: 'farms.sync_product_remainders',
                })}
                type="primary"
                onClick={() => syncProductRemainders()}
                loading={isLoading}
              />
            </StyledButtonList>
          }
          items={items}
        />

        <Modal
          title={intl.formatMessage({ id: 'farms.create_modal' })}
          open={showCreateModal}
          onCancel={onCloseModal}
        >
          test
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default FarmListPage
