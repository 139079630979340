import styled from 'styled-components'

export const StyledCart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding-top: 30px;

  @media only screen and (max-width: 973px) {
    flex-direction: column;
  }
`
