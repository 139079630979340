import "elm-pep";
export * from "./Map/MapComponent";
export { wgs84data } from "./Demo/demoData";
export { renderMap, unmountMap } from "./renderMap";
export { renderUI, unmountUI } from "./renderUI";
export { SelectedStatus } from "./Map/enums";
export { IAssets, IFeature, IStartPosition, IPadding } from "./Map/interfaces";

import { AssetManager } from "./Map/assetManager";
export const setAssets = AssetManager.setAssets;
export const getAssets = AssetManager.getAssets;
