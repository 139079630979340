import { ProductData } from 'types/ProductData'
import { StyledDiscountItems } from './style'
import { Button, Carousel } from '../../../../ui'
import { useIntl } from 'react-intl'

interface DiscountItemsProps {
  products?: ProductData[]
  slidesPerRow?: number
}

const DiscountItems = ({ products, slidesPerRow }: DiscountItemsProps) => {
  const intl = useIntl()

  const responsive = [
    {
      breakpoint: 590,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1484,
      settings: {
        slidesToShow: 3,
      },
    },

    {
      breakpoint: 1760,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 5,
      },
    },
  ]

  return (
    <StyledDiscountItems>
      <div className={'product-slider-container'}>
        <div className={'title'}>
          <h1>{intl.formatMessage({ id: 'general.buy_cheaper' })}</h1>
        </div>
        <Carousel
          responsive={responsive}
          products={products}
          dots={true}
          adaptiveHeight={true}
          slidesPerRow={slidesPerRow || 5}
          draggable={true}
        />
      </div>

      <div className={'view-all-items-button'}>
        <Button
          type="primary"
          size={'large'}
          label={intl.formatMessage({ id: 'discount_items.view_items_button' })}
          href={'/products/all/discounted'}
        />
      </div>
    </StyledDiscountItems>
  )
}

export default DiscountItems
