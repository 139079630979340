import styled from 'styled-components'
import { Form } from 'antd'
export const StyledMobileFilterForm = styled(Form)`
  width: 100%;
  font-family: ${(props) => props.theme.fontText};

  .category-sel-wrap {
    display: flex;
    flex-direction: column;

    .form-title {
      display: none;
    }

    .ant-form-item {
      width: 100%;
      margin-bottom: 20px;
    }

    .ant-select-selector {
      background-color: ${(props) => props.theme.lightGrey};
      border: 1px solid ${(props) => props.theme.grey};
      min-width: 245px;
      min-height: 45px;
      border-radius: 5px;
    }
  }

  .ant-select {
    max-width: 100% !important;
    min-height: 54px !important;
  }

  .qmark {
    flex-direction: column;
    gap: 5px;
  }
`
