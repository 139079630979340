import styled from 'styled-components'

export const StyledBannerImagePreview = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  height: auto;
  border: 1px solid ${(props) => props.theme.grey};
  padding: 10px;
  margin: 10px 0;
`

export const StyledBannerPreview = styled('div')`
  display: flex;
  flex-direction: column;

  .wrapper-banner {
    display: flex;
    flex: 1;
  }
`

export const StyledSliderBannerImages = styled('div')``

export const StyledImagePlaceholder = styled('div')`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;

  .container-banner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 20px;
    margin: 0;

    .remove-wrap {
      display: flex;
      justify-content: flex-end;
    }

    .inputs {
      margin-top: 30px;
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .img-banner {
      border: 1px solid ${(props) => props.theme.lightGrayBorder};
      border-radius: 12px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }
  }
`
