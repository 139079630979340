import { StyledAllFarmsFarmCard } from './style'
import React from 'react'
import { QualityMarkData } from '../../types/ProductData'
import { useIntl } from 'react-intl'
import { HeartOutlined } from '@ant-design/icons'
import { Button } from 'ui/Button'
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage'

export interface props {
  name?: string
  qmarks?: QualityMarkData[]
  registeredFullAddress?: string
  imageUrl?: string
}

export const AllFarmsFarmCard = ({
  name,
  qmarks,
  registeredFullAddress,
  imageUrl,
}: props) => {
  const intl = useIntl()

  const coverImg = (
    <FallbackImage
      src={imageUrl ? imageUrl : ''}
      type={'FarmCard'}
      preview={false}
    />
  )

  return (
    <StyledAllFarmsFarmCard cover={coverImg}>
      <div className={'overlay-wrapper'}>
        <div className={'overlay'}>
          <div className={'top-farm'}>
            {intl.formatMessage({ id: 'all_farm_card.favorite' })}
          </div>
          <div className={'favorite'}>
            <HeartOutlined />
          </div>
        </div>
      </div>
      <div className={'farms-card-content'}>
        <div className={'farms-card-title'}>{name}</div>
        <div className={'registered-full-address'}>{registeredFullAddress}</div>
        <div className={'qmarks-wrapper'}>
          <div className={'qmarks'}>
            {qmarks?.map((qmark, index) => (
              <div
                className={'qmark-fm'}
                key={`frm-cr${index}-d`}
                id={qmark.slug}
              >
                <img src={qmark.logo} />
              </div>
            ))}
          </div>
          <Button
            className={'farms-card-inspect-button'}
            label={'Apskatīt'}
          ></Button>
        </div>
      </div>
    </StyledAllFarmsFarmCard>
  )
}
