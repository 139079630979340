import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Collapsible } from "./Collapsible";
import { IFeature, IPadding } from "../Map/interfaces";

const placeholder =
    "Piemērs: \n" +
    JSON.stringify(
        [
            { coordinates: [25.770026697148015, 56.36069844958856], id: 149 },
            { coordinates: [25.987570746315026, 56.41150001113826], id: 147 }
        ],
        null,
        2
    );
export interface IDemoPaddingControl {
    onChangePadding: (padding: IPadding) => void;
    onToggleMap: () => void;
}

export const DemoPaddingControl: FC<IDemoPaddingControl> = ({ onChangePadding, onToggleMap }: IDemoPaddingControl) => {
    const padding = { left: 300, duration: 500 };
    const setPadding = () => {
        const leftPadding = document.getElementById("padding-left");
        leftPadding.style.width = padding.left + "px";
        if (padding.duration !== undefined) {
            leftPadding.style.transitionDuration = padding.duration + "ms";
        }

        onChangePadding(padding);
    };
    return (
        <div className="list padding">
            <input type="number" placeholder="left (px)" onChange={(e) => (padding.left = Number(e.target.value))} />
            <input
                type="text"
                placeholder="duration (ms)"
                onChange={(e) => (padding.duration = Number(e.target.value))}
            />
            <button title={"set-padding"} onClick={setPadding}>
                Mainīt Padding
            </button>
            <button title={"toggle-map"} onClick={onToggleMap}>
                Slēpt/rādīt karti
            </button>
        </div>
    );
};

export const DemoDataInput = ({ onChangeData }: { onChangeData: (featureData: IFeature[]) => void }) => {
    const [note, setNote] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const preRef = useRef<HTMLPreElement>();

    const onChange = (e) => {
        if (!e.target.value || e.target.value === "") {
            setError(false);
            return;
        }
        setNote(e.target.value);
    };

    function parseStringToJSON(formattedString) {
        // Clean the string by removing newlines and extra spaces
        let cleanedString = formattedString.replace(/\n/g, "").replace(/\s+/g, " ");
        // Add double quotes around property names
        cleanedString = cleanedString.replace(/(\w+):/g, '"$1":');
        // Parse the cleaned string into a JSON object
        try {
            const jsonObject = JSON.parse(cleanedString);
            return jsonObject;
        } catch (error) {
            console.error("Invalid JSON format:", error);
            return null;
        }
    }

    const addData = useCallback(() => {
        const parsed = parseStringToJSON(note);
        onChangeData(parsed);
    }, [note]);

    return (
        <Collapsible title="Objektu dati" defaultCollapsed={false}>
            <div className="list">
                <textarea
                    name="inputData"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={note}
                    className={error ? "error" : ""}
                />
            </div>
            <div className="list">
                <button title={"set-padding"} onClick={addData}>
                    Pievienot datus
                </button>
            </div>
        </Collapsible>
    );
};

const testaDati = [
    { coordinates: [25.770026697148015, 56.36069844958856], id: 149 },
    { coordinates: [25.987570746315026, 56.41150001113826], id: 147 },
    { coordinates: [26.189303421740433, 56.63123912979483], id: 152 },
    { coordinates: [25.368403265072867, 57.81154160767845], id: 153 },
    { coordinates: [24.378823558138343, 57.5208201961745], id: 156 },
    { coordinates: [24.730319305287424, 56.919927646422074], id: 154 },
    { coordinates: [24.428498362162042, 57.467951806678485], id: 157 },
    { coordinates: [25.228780361798336, 57.55301766417868], id: 159 },
    { coordinates: [25.012529448410582, 57.51421735680268], id: 158 },
    { coordinates: [24.927752574076543, 57.67930762329122], id: 160 },
    { coordinates: [24.99533600773848, 57.87377128836405], id: 162 },
    { coordinates: [24.78666042943193, 57.82632282169908], id: 163 },
    { coordinates: [26.43049666449693, 57.39177392132719], id: 3 },
    { coordinates: [27.158679065344003, 57.50853134684934], id: 8 }
];
