import { useFarmState } from 'public/context/FarmContext'
import { Spinner } from 'ui'
import MapComponent from 'ui/Map/Map'
import { StyledMobileHeaderMap } from './style'

const MobileHeaderMap = () => {
  const { farmsCoordinates, isLoading } = useFarmState()

  return (
    <Spinner spinning={isLoading}>
      <StyledMobileHeaderMap>
        <MapComponent
          data={farmsCoordinates ? farmsCoordinates : []}
          dataType={'farms'}
        />
      </StyledMobileHeaderMap>
    </Spinner>
  )
}

export default MobileHeaderMap
