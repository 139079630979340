import React from 'react'
import { StyledGuestOrLoginPrompt } from './style'
import { Button } from 'ui'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface GuestOrLoginPromptProps {
  setSkip: React.Dispatch<React.SetStateAction<boolean>>
}

const GuestOrLoginPrompt = ({ setSkip }: GuestOrLoginPromptProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const redirectToLogin = () => {
    localStorage.setItem('/comesFrom', 'cart')
    navigate(`login`)
  }

  return (
    <StyledGuestOrLoginPrompt>
      <div className={'guest-or-login-wrapper'}>
        <p className={'guest-or-login-title'}>
          {intl.formatMessage({ id: 'process_purchase.login_register' })}
        </p>
        <p className={'guest-or-login-text'}>
          {intl.formatMessage({ id: 'process_purchase.better_to_reg' })}
        </p>
        <Button
          className={'login_register_btn'}
          label={intl.formatMessage({
            id: 'process_purchase.login_register_btn',
          })}
          type={'primary'}
          onClick={() => redirectToLogin()}
        />
      </div>
      <div className={'guest-or-login-wrapper'}>
        <p className={'guest-or-login-title'}>
          {intl.formatMessage({ id: 'process_purchase.guest' })}
        </p>
        <Button
          className={'skip-btn'}
          label={intl.formatMessage({ id: 'process_purchase.continue' })}
          type={'primary'}
          onClick={() => setSkip(true)}
        />
      </div>
    </StyledGuestOrLoginPrompt>
  )
}

export default GuestOrLoginPrompt
