import styled from 'styled-components'

export const StyledIcon = styled.div<{
  type: 'small' | 'medium' | 'large' | 'card' | 'filter-icon' | 'avarage'
}>`
  width: ${(props) =>
    props.type === 'small'
      ? props.theme.iconWidthSM
      : props.type === 'medium'
        ? props.theme.iconWidthMED
        : props.type === 'large'
          ? props.theme.iconWidthLG
          : props.type === 'filter-icon'
            ? props.theme.themeiconHeightFilter
            : props.type === 'avarage'
              ? props.theme.iconWidthAvarage
              : props.theme.iconWidthCard};

  height: ${(props) =>
    props.type === 'small'
      ? props.theme.iconHeightSM
      : props.type === 'medium'
        ? props.theme.iconHeightMED
        : props.type === 'large'
          ? 'auto'
          : props.type === 'filter-icon'
            ? props.theme.themeiconHeightFilter
            : props.type === 'avarage'
              ? props.theme.iconHeightAvarage
              : props.theme.iconHeightCard};

  img {
    width: ${(props) =>
      props.type === 'small'
        ? props.theme.iconWidthSM
        : props.type === 'medium'
          ? props.theme.iconWidthMED
          : props.type === 'large'
            ? props.theme.iconWidthLG
            : props.type === 'filter-icon'
              ? props.theme.iconWidthFilter
              : props.type === 'avarage'
                ? props.theme.iconWidthAvarage
                : props.theme.iconWidthCard};

    height: ${(props) =>
      props.type === 'small'
        ? props.theme.iconHeightSM
        : props.type === 'medium'
          ? props.theme.iconHeightMED
          : props.type === 'large'
            ? 'auto'
            : props.type === 'filter-icon'
              ? props.theme.themeiconHeightFilter
              : props.type === 'avarage'
                ? props.theme.iconHeightAvarage
                : props.theme.iconHeightCard};
  }
`
