import React from 'react'
import { CategoryData } from 'types/CategoryData'

interface PayloadType {
  refetch: Function
  category?: CategoryData[]
  popularCategory?: CategoryData[]
}

type PayloadAction = {
  type: 'SAVE_PAYLOAD' | 'REFETCH'
  payload: PayloadType
  isLoading: boolean
}

type RefetchAction = {
  type: 'REFETCH'
}

type SaveActiveCategoryAction = {
  type: 'SAVE_ACTIVE_CATEGORY'
  activeCategory: CategoryData | null
}

type Action = PayloadAction | RefetchAction | SaveActiveCategoryAction

type Dispatch = (action: Action) => void

type State = {
  category?: CategoryData[]
  popularCategory?: CategoryData[]
  activeCategory?: CategoryData | null
  refetch: Function
}

type CategoryProviderProps = { children: React.ReactNode }

const CategoryStateContext = React.createContext<State>(undefined!)
const CategoryDispatchContext = React.createContext<Dispatch>(undefined!)

function categoryReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SAVE_PAYLOAD': {
      let payload = action.payload
      return {
        ...state,
        ...payload,
      }
    }
    case 'REFETCH': {
      state.refetch()
      return {
        ...state,
      }
    }
    case 'SAVE_ACTIVE_CATEGORY': {
      return {
        ...state,
        activeCategory: action.activeCategory,
      }
    }
  }
}

function CategoryProvider({ children }: CategoryProviderProps) {
  const initialState: State = {
    category: [],
    popularCategory: [],
    activeCategory: null,
    refetch: () => null,
  }

  const [state, dispatch] = React.useReducer(categoryReducer, initialState)

  return (
    <CategoryStateContext.Provider value={state}>
      <CategoryDispatchContext.Provider value={dispatch}>
        {children}
      </CategoryDispatchContext.Provider>
    </CategoryStateContext.Provider>
  )
}

const useCategoryState = () => React.useContext(CategoryStateContext)
const useCategoryDispatch = () => React.useContext(CategoryDispatchContext)

export { CategoryProvider, useCategoryDispatch, useCategoryState }
