import { Checkbox } from '../Checkbox'
import { StyledQmarkCheckboxes } from './style'
import { useIntl } from 'react-intl'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { DeliveryFilterOptions } from 'types/FilterOptions'

interface Props {
  delivery: DeliveryFilterOptions[] | undefined
}

export const DeliveriesCheckboxes = ({ delivery }: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  const intl = useIntl()

  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <StyledQmarkCheckboxes>
      <div className={'qmark-filter-title'} onClick={handleOnClick}>
        <div>{intl.formatMessage({ id: 'form.delivery_filter_title' })}</div>
        <div className={'icon'}>
          {isOpen ? <DownOutlined /> : <RightOutlined />}
        </div>
      </div>
      {isOpen && (
        <Checkbox name="delivery" options={delivery} filterView={true} />
      )}
    </StyledQmarkCheckboxes>
  )
}
