import styled from 'styled-components'

export const StyledMobileMenu = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 1001;
  width: 100vw;
  height: 100px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 15px 15px 0 0;

  .mobile-menu-container {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    display: block;

    .mobile-menu-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;

      height: 100%;

      .mobile-menu-filter {
        margin: 0 20px 20px 20px;
      }

      .mobile-menu-content {
        display: flex;
        gap: 0;

        .mobile-menu-item {
          .ant-btn {
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            gap: 0;

            span {
              height: 24px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 414px) {
    display: block;

    .mobile-menu-container {
      justify-content: space-around;

      .mobile-menu-filter {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  @media only screen and (max-width: 356px) {
    .mobile-menu-container {
      .mobile-menu-content {
        .mobile-menu-item {
          .ant-btn {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
`
