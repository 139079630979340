import React from 'react'
import { useIntl } from 'react-intl'
import { Form, UploadFile } from 'antd'
import {
  Button,
  Checkbox,
  Input,
  Switch,
  TextArea,
  Drawer,
  CustomGrid,
} from 'ui'
import { useNavigate } from 'react-router-dom'
import useQueryApiClient from 'utils/useQueryApiClient'
import UploadImage from '../../../../ui/UploadImage/UploadImage'

interface QualityMarkCreateUpdateDrawerProps {
  id?: number
  isDrawerVisible: boolean
  onClose: Function
}

const QualityMarkCreateUpdateDrawer = ({
  id,
  isDrawerVisible,
  onClose,
}: QualityMarkCreateUpdateDrawerProps) => {
  const intl = useIntl()

  const navigate = useNavigate()
  const [form] = Form.useForm()

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/qmark/${id}`,
      disableOnMount: !id,
    },
    onSuccess: (response) => {
      const parse = {
        ...response,
        conflict: Object.values(response.conflict),
      }

      form.setFieldsValue(parse)
    },
  })

  const { appendData } = useQueryApiClient({
    request: {
      url: id ? `api/v2/qmark/${id}` : `api/v2/qmark`,
      method: 'POST',
      multipart: true,
    },
    onSuccess: () => onClose(),
  })

  const { data: checkboxOptions } = useQueryApiClient({
    request: {
      url: `api/v2/qmark/select`,
    },
  })

  const onFinish = (values: any) => {
    let bodyFormData = new FormData()
    Object.entries(values).forEach((entry: any) => {
      if (entry[0] === 'logo_image') {
        entry[1] = entry[1]?.map((file: UploadFile) => {
          bodyFormData.append(
            `dynamic_files[${file['uid']}]`,
            file.originFileObj!
          )
          return file['uid']
        })
      }

      if (typeof entry[1] === 'object') {
        bodyFormData.append(entry[0], JSON.stringify(entry[1]))
        return
      }

      bodyFormData.append(entry[0], entry[1])
    })

    appendData(bodyFormData)
  }

  const innerOnClose = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Drawer
      open={isDrawerVisible}
      onClose={() => innerOnClose()}
      title={
        id
          ? intl.formatMessage({ id: 'general.edit' })
          : intl.formatMessage({ id: 'general.add' })
      }
      footer={
        <CustomGrid gapRow={10}>
          <Button
            label={intl.formatMessage({ id: 'general.cancel' })}
            onClick={() => navigate('/admin/qmark')}
          />
          <Button
            label={intl.formatMessage({ id: 'general.submit' })}
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
          />
        </CustomGrid>
      }
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Input name="name" label={intl.formatMessage({ id: 'qmark.name' })} />
        <TextArea
          name="description"
          label={intl.formatMessage({ id: 'qmark.description' })}
        />
        <Switch
          name="product"
          label={intl.formatMessage({ id: 'qmark.product' })}
        />
        <Checkbox
          name="conflict"
          formLabel={intl.formatMessage({ id: 'qmark.not_conflicted' })}
          options={checkboxOptions}
          breakNewLine
        />
        <UploadImage
          name="logo_image"
          label={intl.formatMessage({ id: 'general.logo' })}
        />
      </Form>
    </Drawer>
  )
}

export default QualityMarkCreateUpdateDrawer
