import React from 'react'
import { useIntl } from 'react-intl'
import {
  ProfileMenuContainer,
  WelcomeText,
  MenuItem,
  AddCompanyButton,
  ExitButton,
} from './style'
import useQueryApiClient from 'utils/useQueryApiClient'
import { useNavigate } from 'react-router-dom'

interface Props {
  fullName: string
}

const ProfileMenu = ({ fullName }: Props) => {
  const { appendData } = useQueryApiClient({
    request: {
      url: '/api/v2/logout',
      method: 'POST',
    },
    onSuccess: () => (window.location.href = '/'),
  })

  const { data } = useQueryApiClient({
    request: {
      url: '/api/v2/user/order',
      data: {
        count: true,
      },
    },
  })

  const navigate = useNavigate()
  const intl = useIntl()
  return (
    <ProfileMenuContainer>
      <WelcomeText>
        {intl.formatMessage({ id: 'general.hello' })}, <br />{' '}
        <span>{fullName}</span>
      </WelcomeText>
      <MenuItem onClick={() => navigate('/profile/my_orders')}>
        {intl.formatMessage({ id: 'general.my_orders' })} <span>({data})</span>
      </MenuItem>
      <MenuItem onClick={() => navigate('/profile/profile_information')}>
        {intl.formatMessage({ id: 'general.profile_information' })}
      </MenuItem>
      <ExitButton onClick={() => appendData()}>
        {intl.formatMessage({ id: 'general.exit' })}
      </ExitButton>
      <AddCompanyButton>
        {intl.formatMessage({ id: 'farms.add_farm' })}
      </AddCompanyButton>
    </ProfileMenuContainer>
  )
}

export default ProfileMenu
