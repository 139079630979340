import { Collapse } from "antd"
import { CollapseProps } from "antd/lib";
import { FarmData } from "types/FarmData";
import { FC } from "react";
import { FarmDeliveriesWrapper } from "./style";
import WorkingTime from "../WorkingTime";

interface Props {
    farm: FarmData | undefined
    daysOfWeek: string[]
}


const openHoursTime = (openHours: string | undefined) => openHours ? JSON.parse(openHours) : null

const Deliveries: FC<Props> = ({ farm, daysOfWeek }) => {

    const items: CollapseProps['items'] = farm?.deliveries ? farm?.deliveries.map(delivery => ({
        key: delivery.id,
        label: delivery.name,
        children: <>
            <WorkingTime
                workingTime={openHoursTime(delivery.open_hours)}
                workingTimeTitle={`${delivery.address} ${delivery.phone}`}
                daysOfWeek={daysOfWeek}
            />
        </>,
    })) : [];

    return <FarmDeliveriesWrapper className="farm-partner">
        <Collapse expandIconPosition="end" className="custom-collapse" items={items} />
    </FarmDeliveriesWrapper>
}

export default Deliveries
