import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Input, Modal } from 'ui'
import useQueryApiClient from 'utils/useQueryApiClient'
import { Form } from 'antd'

interface CreateNewModalProps {
  showModal: boolean
  onCloseModal: Function
}

const CreateNewModal = ({ showModal, onCloseModal }: CreateNewModalProps) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/roles',
      method: 'POST',
    },
    onSuccess: () => onCloseInner(),
  })

  const onCloseInner = () => {
    form.resetFields()
    onCloseModal()
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'permissions.create_modal' })}
      open={showModal}
      onCancel={() => onCloseInner()}
      footer={
        <>
          <Button
            label={intl.formatMessage({ id: 'general.cancel' })}
            onClick={onCloseInner}
          />
          <Button
            label={intl.formatMessage({ id: 'general.submit' })}
            onClick={() => form.submit()}
            type="primary"
          />
        </>
      }
    >
      <Form form={form} onFinish={appendData} layout="vertical">
        <Input
          name="name"
          label={intl.formatMessage({ id: 'permissions.name' })}
          validations="required"
        />
        <Input
          name="display_name"
          label={intl.formatMessage({ id: 'permissions.display_name' })}
        />
        <Input
          name="description"
          label={intl.formatMessage({ id: 'permissions.description' })}
        />
      </Form>
    </Modal>
  )
}

export default CreateNewModal
