import styled from 'styled-components'
import { Drawer } from '../Drawer'
import { Drawer as AnteDrawer } from 'antd'

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    .preview {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: 800px;
    }
  }

  .ant-drawer-footer {
    .footer {
    }
  }
`

export const StyledFooter = styled.div`
  .btns {
  }
`

export const StyledCartDrawerFooter = styled.div`
  .cart-drawer-sum-wrapper {
    display: flex;
    justify-content: end;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontText};

    .cart-drawer-sum-title {
      font-weight: 400;
    }

    .cart-drawer-sum {
      font-weight: 700;
    }
  }

  button {
    height: 52px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
  }
`
export const StyledDeliveryDrawerFooter = styled.div`
  .delivery-drawer-btn {
    height: 52px;
    width: 100%;
    margin: 10px 0 10px 0;
  }
`

export const MobileMenuStyledDrawer = styled(AnteDrawer)`
  &.mobile-menu {
    background-color: ${(props) => props.theme.brand01} !important;
  }

  .ant-menu-item {
    color: ${(props) => props.theme.white} !important;
  }

  .ant-drawer-body {
    padding: 20px;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile-menu-drawer {
      position: relative;
      margin: 0 !important;
      padding: 0;
      display: block;
    }

    &.mobile-filter {
      top: calc(100vh - 750px) !important;
    }

    &.mobile-menu {
      top: calc(100vh - 400px) !important;
    }
  }
`
