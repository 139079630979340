import { styled } from 'styled-components'

export const StyledGuestOrLoginPrompt = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  background-color: ${(props) => props.theme.greyBackground1};
  margin-top: 50px;

  .guest-or-login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 32px 20px 20px;
    width: 100%;
    height: 100%;

    .guest-or-login-title {
      margin: 0 0 20px 0;
      font-size: 16px;
      font-weight: 600;
    }

    .guest-or-login-text {
      margin: 0 0 20px 0;
      font-size: 16px;
      font-weight: 400;
    }

    .login_register_btn {
      height: 52px;
    }

    .skip-btn {
      height: 52px;
      border: solid 1px ${(props) => props.theme.brand02};
      background: transparent;
      color: ${(props) => props.theme.black};
    }
  }
`
