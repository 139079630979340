import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, Table } from 'ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import useQueryApiClient from 'utils/useQueryApiClient'
import { routes } from 'config/config'
import QualityMarkCreateUpdateDrawer from './QualityMarkCreateUpdateDrawer'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  )
}

const QualityMarkListPage = () => {
  const [tableData, setTableData] = useState<any>([])
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [selectedEntry, setSelectedEntry] = useState<number>(0)

  const intl = useIntl()

  const { refetch } = useQueryApiClient({
    request: {
      url: 'api/v2/qmark',
    },
    onSuccess: (response) => {
      setTableData(response)
    },
  })

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/qmark/sort',
      method: 'PATCH',
    },
  })

  const { appendData: deleteQmark } = useQueryApiClient({
    request: {
      url: 'api/v2/qmark/:id',
      method: 'DELETE',
    },
    onSuccess: () => refetch(),
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'qmark.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'qmark.logo' }),
      dataIndex: 'logo',
      render: (value: string) => (
        <img
          width={100}
          height={80}
          src={`${routes.api.baseUrl}${value}`}
          alt="logo"
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'qmark.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'qmark.description' }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({ id: 'qmark.conflict' }),
      dataIndex: 'conflict',
      render: (value: number[]) =>
        value
          .map((item) => {
            return tableData.find((e: any) => e.id === item)?.name
          })
          .join(', '),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value)
              setIsDrawerVisible(true)
            }}
          />
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete' })}
            onClick={() => deleteQmark([], { id: value })}
          />
        </>
      ),
    },
  ]

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData((prevState: any) => {
        const activeIndex = prevState.findIndex(
          (record: any) => record.id === active?.id
        )
        const overIndex = prevState.findIndex(
          (record: any) => record.id === over?.id
        )

        const newList = arrayMove(prevState, activeIndex, overIndex)

        appendData({ items: newList })

        return newList
      })
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  )

  const onClose = () => {
    refetch()
    setIsDrawerVisible(false)
    setSelectedEntry(0)
  }

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.qmark' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <div className="flex-end">
          <Button
            type="primary"
            label={intl.formatMessage({ id: 'general.create' })}
            onClick={() => setIsDrawerVisible(true)}
          />
        </div>
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={tableData.map((i: any) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              dataSource={tableData}
              columns={columns}
              disablePagination
              components={{ body: { row: Row } }}
            />
          </SortableContext>
        </DndContext>
        <QualityMarkCreateUpdateDrawer
          isDrawerVisible={isDrawerVisible}
          id={selectedEntry}
          onClose={onClose}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default QualityMarkListPage
