import { Checkbox } from '../Checkbox'
import { StyledQmarkCheckboxes } from './style'
import { useIntl } from 'react-intl'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { ServicesFilterOptions } from 'types/FilterOptions'

interface Props {
  services: ServicesFilterOptions[] | undefined
}

export const AdditionalOptionsCheckboxes = ({ services }: Props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  const intl = useIntl()

  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (services?.length === 0) {
      setIsOpen(false)
      setIsEmpty(true)
    } else {
      setIsOpen(true)
      setIsEmpty(false)
    }
  }, [services])

  return (
    <StyledQmarkCheckboxes>
      <div
        className={'qmark-filter-title'}
        onClick={!isEmpty ? handleOnClick : undefined}
      >
        <div>{intl.formatMessage({ id: 'form.options_filter_title' })}</div>
        <div className={'icon'}>
          {isOpen ? <DownOutlined /> : <RightOutlined />}
        </div>
      </div>
      {isOpen && (
        <Checkbox name="services" options={services} filterView={true} />
      )}
    </StyledQmarkCheckboxes>
  )
}
