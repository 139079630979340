import React from 'react'
import { Steps as AntdSteps } from 'antd'
import { StepProps } from 'antd/es/steps'

interface StepsProps {
  type?: 'default' | 'navigation' | 'inline'
  current?: number
  onChange?: (current: number) => void
  items?: StepProps[]
  labelPlacement?: 'horizontal' | 'vertical'
}

export const Steps = ({
  type = 'default',
  current,
  onChange,
  items,
  labelPlacement = 'vertical',
}: StepsProps) => {
  return (
    <AntdSteps
      type={type}
      current={current}
      onChange={onChange}
      items={items}
      labelPlacement={labelPlacement}
    />
  )
}
