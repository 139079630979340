import { StyledMobileFilterForm } from './style'
import { CategorySelector } from '../../Select/Selectors/CategorySelectors'
import { LocationSelector } from '../../Select/Selectors/LocationSelector'
import { FarmsSelector } from '../../Select/Selectors/FarmsSelector'
import { QualityMarkCheckboxes } from '../../Checkbox/Checkboxes/QualityMarkCheckboxes'
import { AdditionalOptionsCheckboxes } from '../../Checkbox/Checkboxes/AdditionalOptionsCheckboxes'
import { DeliveriesCheckboxes } from '../../Checkbox/Checkboxes/DeliveriesCheckboxes'
import { useEffect } from 'react'
import { Filter } from 'types/Filter'
import { FilterOptions } from 'types/FilterOptions'

interface Props {
  setFilter: (newFilter: Filter) => void
  filterOptions: FilterOptions
  filter: Filter
}
export const MobileFilterForm = ({
  setFilter,
  filterOptions,
  filter,
}: Props) => {
  const [form] = StyledMobileFilterForm.useForm()

  useEffect(() => {
    if (!!filter) {
      form.setFieldsValue(filter)
    }
  }, [form, filter])

  return (
    <div className={'form-container'}>
      <StyledMobileFilterForm
        layout={'vertical'}
        onFieldsChange={() => setFilter(form.getFieldsValue())}
        form={form}
      >
        <div className={'categories-form'}>
          <CategorySelector />
          <LocationSelector locations={filterOptions?.locations} />
          <FarmsSelector farms={filterOptions?.farms} />
          <QualityMarkCheckboxes qmarks={filterOptions?.qmark} />
          <AdditionalOptionsCheckboxes services={filterOptions?.services} />
          <DeliveriesCheckboxes delivery={filterOptions?.delivery} />
        </div>
      </StyledMobileFilterForm>
    </div>
  )
}
