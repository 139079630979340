import styled from 'styled-components'

export const StyledAllNewProducts = styled('div')`
  @media only screen and (max-width: 1600px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 100px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 0px !important;
      }
    }
    .form-container {
      display: none !important;
    }
    .products-wrapper {
      padding-right: 0 !important;
      .products{
        padding: 0 !important;
        justify-content: center !important;
      }
    .category_name{
      text-align: center;
      display: block !important;
      font-size: 18px;
      font-weight: 600;
    }
  }
  }

  @media only screen and (max-width: 414px) {
    .products-filters-container {
      .products-wrapper {
        .products {
          justify-content: center !important;
          padding: 0 10px !important;
        }
        
        .category_name{
          text-align: start;
          padding: 0 20px;
        }
      }
    }
  }
  
  .products-filters-container {
    display: flex;
    flex-direction: row;

    .ant-spin-nested-loading{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    
    .form-container {
      padding-left: 200px;
      padding-right: 40px;
    }

    .products-wrapper {
      padding-right: 200px;

      .products {
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        justify-content: end;
      }

      .pagination {
        display: flex;
        justify-content: center;
        padding: 50px 0;
      }
    }
  }
`
