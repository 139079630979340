import { StyledCartProductCard } from './style'
import { useIntl } from 'react-intl'
import { CartProduct, useCartDispatch } from 'public/context/CartContext'
import { ReactNode, useState } from 'react'
import AmountSelector from 'ui/CartAmountSelector/CartAmountSelector'
import { ProductPrice, ProductUnityPrice } from 'utils/productCardHelpers'
import { Button } from 'ui/Button'
import { FallbackImage } from 'ui/UploadImage/fallBack/FallBackImage'

export interface CartProductCard {
  product: CartProduct
  amountCart: number
}

export const CartProductCard = ({
  product,
  amountCart = 0,
}: CartProductCard) => {
  const intl = useIntl()
  const cartDispatch = useCartDispatch()

  const productHasDiscount = !!(
    product.cartProduct?.storage_stock_discount_price &&
    product.cartProduct?.storage_stock_price &&
    product.cartProduct.storage_stock_discount_price <
      product.cartProduct.storage_stock_price
  )

  const removeProductFromCart = () => {
    if (product?.cartProduct?.id)
      cartDispatch({ type: 'REMOVE_ITEM', productId: product.cartProduct.id })
  }

  const cardCover = (
    <FallbackImage
      src={product.cartProduct?.image || ''}
      type={'ProductCard'}
    />
  )

  return (
    <StyledCartProductCard cover={cardCover}>
      <div className={'left-side-container'}>
        <p className={'cart-product-title'}>{product?.cartProduct?.name}</p>
        <div className={'amount-selector-wrapper'}>
          <AmountSelector
            amountCart={amountCart}
            product={product.cartProduct}
            unitType={product.cartProduct?.storage_unit}
          />
        </div>
      </div>
      <div className={'right-side-container'}>
        <Button
          onClick={removeProductFromCart}
          className={'remove-cart-product-btn'}
          label={intl.formatMessage({ id: 'general.delete' })}
        />
        <div className={'price-container'}>
          <ProductPrice
            product={product.cartProduct}
            productHasDiscount={productHasDiscount}
          />
          <ProductUnityPrice product={product.cartProduct} />
        </div>
      </div>
    </StyledCartProductCard>
  )
}
