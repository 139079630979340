import { StyledNewProductForm } from './style'
import { useIntl } from 'react-intl'
import { NewProductsGallery } from '../../UploadImage/products/NewProductsGallery'
import { Requirements } from '../../UploadImage/Requirements'

interface NewProductGalleryProps {
  onValidate: (isValid: boolean) => void
}

export const NewProductGallery = ({ onValidate }: NewProductGalleryProps) => {
  const intl = useIntl()

  return (
    <StyledNewProductForm>
      <div className={'title'}>
        {intl.formatMessage({ id: 'general.gallery_title' })}
      </div>
      <div className={'requirements'}>
        <Requirements />
        <div className={'image-upload'}>
          <NewProductsGallery onValidate={onValidate} />
        </div>
      </div>
    </StyledNewProductForm>
  )
}
