import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { MapBrowserEvent, Overlay } from "ol";
import { useAppContext } from "./AppContext";
import { getMap } from "./MapComponent";

export const Popup = ({ content }: { content: HTMLDivElement | TrustedHTML | null }) => {
    const { mapClick } = useAppContext();
    const [visible, setVisible] = useState<boolean>(false);

    const overlayRef = useRef<Overlay>();

    useEffect(() => {
        overlayRef.current = new Overlay({
            element: document.getElementById("map-popup"),
            offset: [0, -25],
            positioning: "bottom-center",
            className: "popup-overlay identify-overlay"
        });
        getMap().addOverlay(overlayRef.current);
    }, []);

    useEffect(() => {
        if (!mapClick || content === null) {
            setVisible(false);
            return;
        }
        setVisible(true);
        overlayRef.current.setPosition(mapClick.coordinate);
        setTimeout(() => overlayRef.current.panIntoView({ animation: { duration: 250 } }), 0);
    }, [content, mapClick]);

    return <div id="map-popup">{content && visible && <div dangerouslySetInnerHTML={{ __html: content }} />}</div>;
};
