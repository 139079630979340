import * as React from "react";
import { FC, useRef } from "react";
import { Feature, Map } from "ol";
import { useMap } from "./useMap";
import { Popup } from "./Popup";
import { IStartPosition, IFeature, IPadding } from "./interfaces";
import { initializeAssets } from "./symbols";
import "../css/map.css";
import "ol/ol.css";

export type OnFeatureClickCallback = (feature: Feature) => void;
export type OnUnselectCallback = (feature: Feature) => void;

export interface IMapComponentProps {
    /**
     * show popup, at click coordinates, content as html pass here
     */
    popupHtml: HTMLDivElement | TrustedHTML | null;
    /**
     * map start position, [xCoordinate, yCoordinate, zoomLevel]
     */
    startPosition: IStartPosition;
    /**
     * point data, to create map features.
     */
    featureData: IFeature[];
    /**
     * callback on object click, returns identified object (feature)
     */
    onSelectFeature: OnFeatureClickCallback;
    /**
     * callback, when object unselected, returns OBJECTID of unselected feature
     */
    onUnselectFeature: OnUnselectCallback;
    /**
     * feature, to zoom-to
     */
    zoomToFeature: Feature | null;
    /**
     * map padding, left, in px, animation duration, in ms
     * NOTE: for map transitions, easing function is "ease-in-out", so it is reccomended to use it for sidebar too
     * @example { left: 300, duration: 500 }
     */
    padding: IPadding;
}

let mapRef: React.MutableRefObject<Map>;

export const MapComponent: FC<IMapComponentProps> = (props) => {
    initializeAssets();
    const ref = useRef<HTMLDivElement>();

    mapRef = useRef<Map>();
    mapRef.current = useMap(ref, props);

    return (
        <div ref={ref} style={{ height: "100%", display: "block" }}>
            {mapRef.current && <Popup content={props.popupHtml} />}
        </div>
    );
};

export const getMap = (): Map => mapRef.current;
