import React from 'react'
import CartWarning from '../CartWarning'
import { StyledCart } from './style'
import { FarmProduct } from 'public/context/CartContext'
import GroupedFarmCart from '../GroupedFarmCart/GroupedFarmCart'
import CartSummary from '../CartSummary/CartSummary'

interface CartProps {
  farmProducts: FarmProduct[]
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  current: number
  action?: 'view'
}

const Cart = ({ farmProducts, setCurrent, current, action }: CartProps) => {
  return (
    <>
      <CartWarning />
      <StyledCart>
        <GroupedFarmCart farmProducts={farmProducts} action={action} />
        <CartSummary
          farmProducts={farmProducts}
          setCurrent={setCurrent}
          current={current}
          action={action}
        />
      </StyledCart>
    </>
  )
}

export default Cart
