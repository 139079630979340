import React from 'react'
import { StyledHeader } from './style'

const DefaultHeader = () => {
  return (
    <StyledHeader>
      <div className="left-side-header">todo meklēt</div>
      <div className="right-side-header">todo lietotaja profils</div>
    </StyledHeader>
  )
}

export default DefaultHeader
