import React from 'react'

export interface CustomGridProps {
  gapRow?: number
  gapCol?: number
  inOneRow?: number
  children?: React.ReactNode
}

export const CustomGrid = ({
  children,
  gapRow = 0,
  gapCol = 30,
  inOneRow = 2,
}: CustomGridProps) => {
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(' + inOneRow + ', 1fr)',
          gridColumnGap: gapCol + 'px',
          gridRowGap: gapRow + 'px',
        }}
      >
        {children}
      </div>
    </>
  )
}
