import { StyledNewProductForm } from './style'
import { useIntl } from 'react-intl'
import { Input } from '../../Input'

export interface shareProps {
  productId?: number
}

export const ShareProductForm = ({ productId }: shareProps) => {
  const intl = useIntl()

  const productLink = `${window.location.origin}/products/${productId}`

  const Suffix = () => {
    const handleCopyClick = () => {
      navigator.clipboard.writeText(productLink).catch((err) => {
        console.error('Failed to copy text: ', err)
      })
    }

    return (
      <div onClick={handleCopyClick} className={'suffix'}>
        <img src={'/svgs/copy-icon.svg'} alt="copy icon" />
        <span>{intl.formatMessage({ id: 'products.copy' })}</span>
      </div>
    )
  }

  return (
    <StyledNewProductForm>
      <div className={'copy-product'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'products.copy_link' })}
        </div>
        <Input value={productLink} suffix={<Suffix />} />
      </div>
      <div className={'social-icons'}>
        <img src={'/svgs/socials/facebook.svg'} />
        <img src={'/svgs/socials/twitter.svg'} />
        <img src={'/svgs/socials/linkedin.svg'} />
        <img src={'/svgs/socials/whatsapp.svg'} />
        <img src={'/svgs/socials/google.svg'} />
        <img src={'/svgs/socials/skype.svg'} />
      </div>
    </StyledNewProductForm>
  )
}
