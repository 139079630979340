import styled from 'styled-components'

export const StyledLabel = styled.label`
  display: flex;

  &.title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  &.extra-bold {
    font-weight: 600;
  }

  &.regular-bold {
    font-weight: 500;
  }

  &.regular {
    font-weight: 400;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }

  &.end {
    justify-content: center;
    text-align: end;
  }

  &.theme-color {
    color: ${({ theme }) => theme.brand01};
  }

  .theme-color {
    color: ${({ theme }) => theme.brand01};
  }

  &.italic {
    font-style: italic;
  }

  &.primary {
    color: ${({ theme }) => theme.brand02};
  }

  &.clickable {
    cursor: pointer;
  }

  &.white {
    color: ${({ theme }) => theme.white};
  }

  &.black {
    color: ${({ theme }) => theme.black};
  }
`
