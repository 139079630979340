import styled from 'styled-components'
import '../../../../fonts/AlbertSans/stylesheet.css'

export const StyledPage = styled.div`
  border-radius: 8px;
  margin: 0 auto;
  font-family: ${(props) => props.theme.fontText};
  min-height: 100vh;
  background-color: ${(props) => props.theme.mainBgColor};
`
