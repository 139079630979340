import { StyledLoginPage } from './style'
import { RegisterForm } from '../../../ui/Form/auth/RegisterForm'
import useQueryApiClient from '../../../utils/useQueryApiClient'

export const Register = () => {
  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/register',
      method: 'POST',
    },
  })

  return (
    <StyledLoginPage>
      <div className={'content'}>
        <RegisterForm appendData={appendData} />
      </div>
    </StyledLoginPage>
  )
}
