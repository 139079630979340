import { message } from 'antd'

import { IntlShape } from 'react-intl'

interface CopyToClipboardProps {
  data: object | undefined
  headers: string
  formatRow: (row: any) => string
  intl: IntlShape
}

const copyToClipboard = ({
  data,
  headers,
  formatRow,
  intl,
}: CopyToClipboardProps) => {
  if (!data || !Array.isArray(data)) {
    message.error(intl.formatMessage({ id: 'general.no_data' }))
    return
  }

  const rows = data.map((row: any) => formatRow(row)).join('\n')

  const copyData = [headers, rows].join('\n')

  navigator.clipboard
    .writeText(copyData)
    .then(() => {
      message.success(intl.formatMessage({ id: 'general.copy_success' }))
    })
    .catch((error) => {
      message.error(intl.formatMessage({ id: 'general.copy_failure' }))
    })
}

export default copyToClipboard
