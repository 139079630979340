import { styled } from 'styled-components'

export const StyledCartSummary = styled.div`
  .cart-summary {
    max-width: 380px;
    width: max-content;
    .cart-summary-title {
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 18px;

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    .cart-order-summary-wrapper {
      background-color: ${(props) => props.theme.summaryBackground};
      width: 100%;
      padding: 20px 20px 20px 20px;
      border: solid 1px ${(props) => props.theme.lightborder};

      .cart-farm-order-summary.total {
        padding: 32px 0 32px 0;
        border-top: 1px solid ${(props) => props.theme.borderBottom};
        border-bottom: 1px solid ${(props) => props.theme.borderBottom};
      }

      .cart-farm-order-summary.mobile-total {
        display: none;
      }

      .cart-farm-order-summary {
        font-size: 16px;
        margin-bottom: 32px;
        .farm-title {
          font-weight: 600;
        }

        .farm-summary-wrapper {
          .cart-summary-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .cart-summary-item {
              margin: 4px 0 0 0;
              font-weight: 400;
              height: 24px;
            }
          }
        }
      }

      .cart-discount-coupon {
        border-bottom: 1px solid ${(props) => props.theme.borderBottom};
        padding-bottom: 32px;
      }

      .cart-discount-subscribe {
        padding-top: 32px;
        padding-bottom: 32px;
      }

      .cart-summary-submit-wrapper {
        .cart-summary-final-price {
          display: flex;
          justify-content: space-between;
          font-family: ${(props) => props.theme.fontText};
          font-size: 16px;
          margin-bottom: 16px;

          .cart-summary-final-price-title {
            font-weight: 400;
          }
          .cart-summary-final-price {
            font-weight: 700;
          }
        }
      }

      .cart-summary-submit-btn {
        width: 100%;
        height: 52px;
        font-family: ${(props) => props.theme.fontText};
      }

      .cart-summary-submit-btn-view {
        width: 100%;
        height: 52px;
        font-family: ${(props) => props.theme.fontText};
        border: solid 1px ${(props) => props.theme.brand02};
        background: transparent;
        color: ${(props) => props.theme.black};

        @media only screen and (max-width: 973px) {
          width: 100%;
        }

        @media only screen and (max-width: 576px) {
          padding: 0;
          background-color: ${(props) => props.theme.white};

          .cart-farm-order-summary.total {
            display: none;
          }
          .cart-farm-order-summary.mobile-total {
            display: block;
          }

          .cart-farm-order-summary.mobile-total .cart-summary-container {
            padding: 9px 0 13px 0;
            border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};
          }

          .cart-farm-order-summary.mobile-total
            .cart-summary-container:last-child {
            border-bottom: 0;
          }

          .cart-summary-item.price {
            color: ${(props) => props.theme.colorText};
            font-weight: 600 !important;
          }

          .cart-farm-order-summary {
            margin-bottom: 20px;
          }

          .cart-discount-coupon,
          .cart-discount-subscribe,
          .cart-summary-submit-wrapper {
            display: none;
          }
        }
      }

      @media only screen and (max-width: 576px) {
        padding: 0;
      }
    }
    @media only screen and (max-width: 974px) {
      max-width: 100%;
    }
  }
  .total-summary {
    display: none;
  }

  @media only screen and (max-width: 576px) {
    .total-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 31px 20px 61px 20px;
      background-color: ${(props) => props.theme.brand01};
      border-radius: 15px;
      position: fixed;
      width: 100vw;
      bottom: 70px;
      left: 0;
    }

    .total-summary-text {
      color: ${(props) => props.theme.white};
      font-weight: 400;
    }

    button {
      height: auto;
      padding: 8px 16px;
    }

    .cart-summary-submit-btn-view {
      display: none;
    }

    .cart-summary-submit-btn {
      display: none;
    }
  }
`
