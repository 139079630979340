import { StyledFarmCard } from './style'
import { QualityMarkData } from '../../types/ProductData'
import { useIntl } from 'react-intl'
import { FarmData } from '../../types/FarmData'
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage'

export interface FarmProps {
  farm?: FarmData
}

export const FarmCard = ({ farm }: FarmProps) => {
  const intl = useIntl()
  const coverImg = (
    <FallbackImage src={farm?.logo ? farm.logo : ''} type={'FarmCard'} preview={false} />
  )

  return (
    <StyledFarmCard cover={coverImg} hoverable={true}>
      <div className={'container'}>
        <div className={'farm-name'}>{farm?.name}</div>
        <div className={'address-farm'}>
          <div className={'address'}>{farm?.actual_full_address}</div>
        </div>
        <div className={'qmarks-wrapper'}>
          <div className={'qmarks-url'}>
            <div className={'qmarks'}>
              {farm?.quality_marks?.map((qmark, index) => (
                <FallbackImage
                  src={qmark.logo ? qmark.logo : ''}
                  type={'notFound'}
                  preview={false}
                  key={index}
                />
              ))}
            </div>
            <div className={'url-to'}>
              {intl.formatMessage({ id: 'farm_card.view' })}
              <div className={'rect-angle-wrap'}>
                <div className={'rect'}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFarmCard>
  )
}
