import styled from 'styled-components'

export const StyledPopularProducts = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.containerBgColor};

  .title {
    text-align: left;
    padding-left: 200px;
    font-weight: 300;
  }

  .grid-view {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0px 200px 0px 200px;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 1600px) {
    .grid-view {
      padding: 0px 50px 0px 50px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .grid-view {
      padding: 0px 0px 0px 0px !important;
    }

    .title {
      padding: 1em 0 0 1em;
      h1 {
        font-size: 24px;
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .grid-view {
      padding: 0px 10px 0px 10px !important;
    }

    .title {
      padding: 1em 0 0 20px;
      h1 {
        font-size: 18px;
      }
    }
  }
`
