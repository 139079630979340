import React, { ReactNode, useState } from 'react'
import { ProductData } from 'types/ProductData'
import { CarFilled, DownOutlined, UpOutlined } from '@ant-design/icons'
import { FarmData, FarmDeliveries } from '../types/FarmData'
import { IntlShape, useIntl } from 'react-intl'

interface HelperProps {
  product?: ProductData
  productHasDiscount?: boolean
  farm?: FarmData
  displayFlex?: boolean
}

interface DeliveryProps {
  delivery?: FarmDeliveries
  label?: string
  children?: ReactNode
  isParcel?: boolean
  index?: number
  intl?: IntlShape
}

const ProductUnityPrice = ({ product }: HelperProps) => {
  const packageUnitPrice = Number(product?.package_unit_price)
  if (product?.storage_unit === 'iep' && !!packageUnitPrice) {
    return (
      <div className="package-price d-flex flex-row">
        <div className="price">{packageUnitPrice.toFixed(2)} €</div>
        <div className="type-of-price"> /{product.package_unit}</div>
      </div>
    )
  }

  return null
}

const ProductPackageUnit = ({ product }: HelperProps) => {
  const packageUnitVal = Number(product?.package_unit_val)
  if (
    product?.storage_unit === 'iep' &&
    !isNaN(packageUnitVal) &&
    packageUnitVal > 0
  ) {
    return (
      <span>
        {packageUnitVal.toFixed(2)}
        <span className="unit">{product.package_unit}</span>
      </span>
    )
  }

  return null
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const ProductPrice = ({
  product,
  productHasDiscount,
  displayFlex,
}: HelperProps) => {
  const storageStockPrice = Number(product?.storage_stock_price)
  const storageStockDiscountPrice = Number(
    product?.storage_stock_discount_price
  )

  if (!isNaN(storageStockPrice)) {
    if (!productHasDiscount) {
      return (
        <div>
          <div
            className="price-type-container d-flex flex-row"
            style={displayFlex ? { display: 'flex' } : {}}
          >
            <div className="price">{storageStockPrice.toFixed(2)} €</div>
            <div className="type-of-price">
              {' '}
              {!displayFlex ? product?.storage_unit : ''}
            </div>
          </div>
        </div>
      )
    }

    if (!isNaN(storageStockDiscountPrice)) {
      return (
        <div className="price-type-wrapper d-flex flex-rcol">
          <div className="price-type-second d-flex flex-row ">
            <div className="price">{storageStockPrice.toFixed(2)} €</div>
            <div className="type-of-price"> /{product?.storage_unit}</div>
          </div>
          <div className="price-grey grey-price d-flex flex-row">
            <div className="price">
              {storageStockDiscountPrice.toFixed(2)} €
            </div>
            <div className="type-of-price"> /{product?.storage_unit}</div>
          </div>
        </div>
      )
    }
  }

  return null
}

const DeliveryDropdown = ({
  delivery,
  label,
  children,
  isParcel,
}: DeliveryProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()

  if (!isParcel) {
    return (
      <div className={'dropdown-content'}>
        <div
          className="dropdown-header d-flex"
          onClick={() => setIsOpen(!isOpen)}
          style={
            isOpen
              ? {
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                }
              : {}
          }
        >
          {intl.formatMessage({ id: `delivery.${label}` })}
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        {isOpen && (
          <div
            className="dropdown-content"
            style={
              isOpen
                ? { borderTop: 'none', borderLeft: 'none', borderRight: 'none' }
                : {}
            }
          >
            <p className={'address'}>{delivery && delivery.address}</p>
            <table className="open-hours-table">
              <tbody>
                {delivery?.open_hours &&
                  parseOpenHours(delivery?.open_hours).map((hours, index) => (
                    <tr
                      key={index}
                      className={
                        index && index % 2 === 1
                          ? 'light-gray-background'
                          : 'white-background'
                      }
                    >
                      <td className={'td-day'}>
                        {intl.formatMessage({
                          id: `${'open_hours'}.${hours.day}`,
                        })}
                      </td>
                      <td>
                        {hours.from !== 'Closed'
                          ? hours.from + ' - ' + hours.to
                          : ''}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {children}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div className={'dropdown-content'}>
        <div
          className="dropdown-header d-flex"
          onClick={() => setIsOpen(!isOpen)}
          style={
            isOpen
              ? {
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                }
              : {}
          }
        >
          {intl.formatMessage({ id: `delivery.${label}` })}
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
        {isOpen && (
          <div
            className="dropdown-content"
            style={
              isOpen
                ? { borderTop: 'none', borderLeft: 'none', borderRight: 'none' }
                : {}
            }
          >
            <p>
              {intl.formatMessage({ id: 'delivery.price' })}
              {delivery?.price}
            </p>
            {children}
          </div>
        )}
      </div>
    )
  }
}

const RenderProductDelivery = ({ product }: HelperProps) => {
  const intl = useIntl()

  const farm_deliveries =
    !!product?.farm_deliveries && product.farm_deliveries.length > 0
      ? product.farm_deliveries
      : !!product?.farm?.deliveries && product.farm.deliveries.length > 0
      ? product.farm.deliveries
      : []

  const deliveryTypes = {
    self: {
      label: 'self',
      isParcel: false,
      containerClass: 'distribution-points',
    },
    parcel: {
      label: 'DPD',
      isParcel: true,
      containerClass: 'parcel-terminals',
    },
    omniva: {
      label: 'omniva',
      isParcel: true,
      containerClass: 'parcel-terminals',
    },
    izipizi: {
      label: 'izipizi',
      isParcel: true,
      containerClass: 'parcel-terminals',
    },
    delivery: { label: 'courier', isParcel: true, containerClass: 'couriers' },
  }

  if (!!farm_deliveries) {
    return (
      <>
        {farm_deliveries.map((delivery, index) => {
          const {
            label = '',
            isParcel = false,
            containerClass = '',
          } = delivery.type && deliveryTypes.hasOwnProperty(delivery.type)
            ? deliveryTypes[delivery.type as keyof typeof deliveryTypes]
            : {}

          if (!label) return null

          const backgroundColor =
            index % 2 === 1 ? 'white-background' : 'light-gray-background'

          return (
            <div
              className={`delivery-wrapper d-flex ${backgroundColor}`}
              key={delivery.id}
            >
              <div
                className={`${containerClass} d-flex flex-row flex-gap`}
                key={delivery.id}
              >
                <DeliveryDropdown
                  key={delivery.id}
                  delivery={delivery}
                  index={index}
                  label={label}
                  isParcel={isParcel}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  } else {
    return (
      <div className="delivery-wrapper d-flex white-background">
        <div className="distribution-points d-flex flex-row flex-gap">
          <div className="text-center">
            <CarFilled />{' '}
          </div>
          <div>
            <div className="label">
              {intl.formatMessage({ id: 'product_view.place_of_pickup' })}
            </div>
            <div>
              {product?.farm?.actual_full_address ??
                intl.formatMessage({
                  id: 'product_view.address_not_available',
                })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const parseOpenHours = (openHoursString: string) => {
  try {
    let jsonString = JSON.parse(openHoursString)
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    return days.map((day, index) => {
      const dayData = jsonString[index + 1] || {}
      return {
        day,
        from: dayData['no'] || 'Closed',
        to: dayData['lidz'] || 'Closed',
      }
    })
  } catch (error) {
    console.error('Error parsing open hours:', error)
    return []
  }
}

export {
  ProductUnityPrice,
  ProductPackageUnit,
  ProductPrice,
  RenderProductDelivery,
  DeliveryDropdown,
  parseOpenHours,
}
