import styled from 'styled-components'

export const StyledMobileHeaderMap = styled('div')`
  height: 253px;
  font-family: ${(props) => props.theme.fontText};

  #map-popup {
    font-family: ${(props) => props.theme.fontText};
    position: relative;
    right: 90px;
  }
`
