import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import DefaultLayout from '../../components/DefaultLayout'
import Filter from '../../components/Filter'
import {
  Button,
  Checkbox,
  Input,
  Select,
  SelectOption,
  Table,
  Tabs,
} from '../../../ui'
import { ProductPrice } from '../../../utils/productCardHelpers'
import { ProductData } from 'types/ProductData'
import { CategorySelector } from '../../../ui/Select/Selectors/CategorySelectors'
import { options } from '../../../config/config'
import { StyledButtonList } from '../../../styles/buttonList'
import { DrawerShareProduct } from '../../../ui/Drawer/Drawers/DrawerShareProduct'
import { DrawerCreateProduct } from '../../../ui/Drawer/Drawers/DrawerCreateProduct'
import { ShareProductForm } from '../../../ui/Form/products/ShareProductForm'
import { useAuth } from '../../../hooks/useAuth'

export const Products = () => {
  const [advancedSearch, setAdvancedSearch] = useState({})
  const [perPage, setPerPage] = useState(20)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [isOpenCreateDrawer, setIsOpenCreteDrawer] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  )
  const { user } = useAuth()
  const intl = useIntl()

  const handleFilterChange = (values: any) => {
    setAdvancedSearch((prevFilters) => ({
      ...prevFilters,
      ...values,
      farm: user?.farmId,
    }))
  }

  const handleOpenCreateDrawer = () => {
    setIsOpenCreteDrawer(true)
  }

  const handleCloseCreateDrawer = () => {
    setIsOpenCreteDrawer(false)
  }

  const handleOpenDrawer = (productId: number) => {
    setSelectedProductId(productId)
    setIsOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false)
    setSelectedProductId(null)
  }

  const renderBooleanCheckmarks = (value: boolean) => {
    return <Checkbox checked={value} />
  }

  const per_page = options.filters.pageSizeOptions

  const handlePageSizeChange = (value: any) => {
    setPerPage(value)
  }

  const actionsSelect = () => (
    <Select name={'action'} showSearch={false}>
      <SelectOption value="edit" key={'1'}>
        {intl.formatMessage({ id: 'products.edit' })}
      </SelectOption>
      <SelectOption value="delete" key={'2'}>
        {intl.formatMessage({ id: 'products.delete' })}
      </SelectOption>
    </Select>
  )

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.category' }),
      dataIndex: 'category_id',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.count' }),
      dataIndex: 'storage_stock_count',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.price' }),
      render: (text: any, record: ProductData | undefined) => (
        <ProductPrice
          displayFlex
          product={record}
          productHasDiscount={!!record?.storage_stock_discount_price}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'products.availability' }),
      dataIndex: 'availability',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.visibility' }),
      dataIndex: 'hidden',
      render: (value: boolean) => renderBooleanCheckmarks(value),
    },
    {
      title: intl.formatMessage({ id: 'products.e_market' }),
      dataIndex: 'emarket',
      render: (value: boolean) => renderBooleanCheckmarks(value),
    },
    {
      title: intl.formatMessage({ id: 'products.share' }),
      render: (record: ProductData | undefined) => (
        <img
          onClick={() => handleOpenDrawer(record?.id as number)}
          src={'/share_icon.svg'}
          alt="Share"
          className={'pointer'}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'products.sold' }),
      dataIndex: 'sales_count',
      render: (value: number) => value,
    },
    {
      title: intl.formatMessage({ id: 'products.actions' }),
      render: () => actionsSelect(),
    },
  ]

  const items = [
    {
      key: 'all_products',
      label: intl.formatMessage({ id: 'navigation.products' }),
      children: (
        <>
          <Filter onFinish={handleFilterChange} products>
            <div className={'left-side'}>
              <Input
                label={intl.formatMessage({ id: 'general.search' })}
                name="search"
              />
              <Button
                type="primary"
                label={intl.formatMessage({ id: 'general.select' })}
                htmlType="submit"
              />
              <CategorySelector displayFlex />

              <Select
                name={'emarket'}
                showSearch={false}
                label={intl.formatMessage({ id: 'products.e_market' })}
              >
                <SelectOption value={true}>
                  {intl.formatMessage({
                    id: 'products.visible',
                  })}
                </SelectOption>
                <SelectOption value={false}>
                  {intl.formatMessage({
                    id: 'products.hidden',
                  })}
                </SelectOption>
              </Select>
            </div>
            <div className={'right-side'}>
              <Select
                onChange={handlePageSizeChange}
                name={'page_size'}
                showSearch={false}
                label={intl.formatMessage({ id: 'products.per_page' })}
                initialValue={perPage}
              >
                {per_page.map((opt, index) => (
                  <SelectOption key={`opt-${index}`} value={opt}>
                    {opt}
                  </SelectOption>
                ))}
              </Select>
            </div>
          </Filter>
          {user?.farmId && (
            <Table
              url="/api/v2/products"
              columns={columns}
              filter={{ ...advancedSearch, farm: user.farmId }}
              page_size={perPage}
              products
            />
          )}
          <DrawerShareProduct
            open={isOpenDrawer}
            onClose={handleCloseDrawer}
            children={
              <ShareProductForm
                productId={selectedProductId ? selectedProductId : undefined}
              />
            }
            title={intl.formatMessage({ id: 'drawers.share_product' })}
          />
          <DrawerCreateProduct
            open={isOpenCreateDrawer}
            onClose={handleCloseCreateDrawer}
            farmId={user?.farmId}
          />
        </>
      ),
    },
  ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.products' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          items={items}
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                label={intl.formatMessage({ id: 'products.create' })}
                type="primary"
                onClick={handleOpenCreateDrawer}
              />
            </StyledButtonList>
          }
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}
