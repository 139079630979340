import * as React from "react";
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from "react";
import { MapBrowserEvent } from "ol";

export interface IAppContext {
    mapClick: MapBrowserEvent<any>;
    setMapClick: Dispatch<SetStateAction<MapBrowserEvent<any>>>;
}

type AppContextProps = {
    children?: ReactNode;
    props?: IAppContext;
};

const AppContext = createContext<IAppContext>(null);

export const useAppContext = (): IAppContext => useContext(AppContext);

export const AppContextProvider: FC<AppContextProps> = (props: React.PropsWithChildren<IAppContext>) => {
    const [mapClick, setMapClick] = useState<MapBrowserEvent<any>>();

    const values = {
        mapClick,
        setMapClick
    };

    return <AppContext.Provider value={values}>{props.children}</AppContext.Provider>;
};
