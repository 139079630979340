import { StyledCategoryCard } from './style'
import { ReactNode, useState } from 'react'
import { CategoryData } from 'types/CategoryData'
import { useIntl } from 'react-intl'
import { ImageProps } from '../../types/BannerData'
import UseQueryApiClient from '../../utils/useQueryApiClient'
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage'

export interface CategoryCardProps {
  bordered?: boolean
  hoverable?: boolean
  category?: CategoryData
  img?: string
  imageUrl?: string
}

export const CategoryCard = ({
  bordered,
  hoverable,
  imageUrl,
  category,
}: CategoryCardProps) => {
  const intl = useIntl()

  const coverImg = (
    <FallbackImage
      src={imageUrl ? imageUrl : ''}
      type={'CategoryCard'}
      preview={false}
    />
  )

  return (
    <StyledCategoryCard
      bordered={bordered}
      cover={coverImg}
      hoverable={hoverable}
    >
      <div className={'category-title'}>
        <p>{category?.name}</p>
      </div>

      <div className={'product-count'}>
        {category?.products_count}{' '}
        {intl.formatMessage({ id: 'general.products' })}
      </div>
    </StyledCategoryCard>
  )
}
