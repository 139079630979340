import { StyledTopCategorySelector } from './style'
import { CategoryData } from '../../../types/CategoryData'
import { Select, SelectOption } from '../Select'
import { useIntl } from 'react-intl'
import { Button, GetProp, Upload, UploadFile, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useState } from 'react'
import UseQueryApiClient from '../../../utils/useQueryApiClient'
import { ImageProps } from '../../../types/BannerData'
import { getBase64 } from '../../../utils/productCardHelpers'

interface CategorySelectorProps {
  categories: CategoryData[]
  name: string
  placeholder?: string
  label: string
  onCategoryChange: (position: number, categoryId: number) => void
  initialValue?: CategoryData
  image?: ImageProps
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const TopCategorySelector = ({
  placeholder,
  name,
  categories,
  label,
  initialValue,
  image,
  onCategoryChange,
}: CategorySelectorProps) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const intl = useIntl()
  const allCategories: CategoryData[] = []

  const APP_URL = window.runConfig.backendUrl

  const { appendData } = UseQueryApiClient({
    request: {
      url: '/api/v2/categories/upload',
      method: 'POST',
      multipart: true,
    },
    onSuccess: (response) => {
      fileList.forEach((file) => {
        file.status = 'done'
      })
    },
  })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const handleOnSelectCategory = (value: number) => {
    const nameToNumber = parseInt(name)

    onCategoryChange(nameToNumber, value)
  }

  categories.forEach((category) => {
    allCategories.push(category)

    if (category.has_subcategories) {
      category.subcategories?.forEach((subCat) => {
        allCategories.push(subCat)

        if (subCat.has_subcategories) {
          subCat.subcategories?.forEach((subSubCat) => {
            allCategories.push(subSubCat)
          })
        }
      })
    }
  })

  const handleCustomRequest = ({ file }: any) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('position', name)
    formData.append('file_name', file.name)

    appendData(formData)
  }

  const uploadProps = {
    name: 'file',
    fileList: fileList,
    onPreview: handlePreview,
    onChange: handleChange,
    customRequest: handleCustomRequest,
  }

  return (
    <StyledTopCategorySelector>
      <div className={'category'}>
        <div className={'label'}>
          {intl.formatMessage({ id: `top_category.${label}` })}
        </div>
        <Select
          name={name}
          placeholder={initialValue?.name}
          onChange={handleOnSelectCategory}
          value={initialValue?.id}
        >
          {allCategories.map((category) => (
            <SelectOption key={category.id} value={category.id}>
              {category.name}
            </SelectOption>
          ))}
        </Select>
        <div className={'preview'}>
          {image ? (
            <img src={`${APP_URL}${image.url_original}`} />
          ) : (
            <img src={'/Rectangle_142.png'} />
          )}
        </div>
        <div className={'img'}>
          <Upload {...uploadProps}>
            <Button className={'btn'} icon={<UploadOutlined />}>
              {intl.formatMessage({ id: 'general.upload' })}
            </Button>
          </Upload>
        </div>
      </div>
    </StyledTopCategorySelector>
  )
}

export default TopCategorySelector
