import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { AppContextProvider } from "./Map/AppContext";
import { IMapComponentProps, MapComponent } from "./Map/MapComponent";

/**
 * Renders map on container. This method must be called every time you change Map props, with the same container node
 * @param props Map props
 * @param container HTML div node
 */

let root: Root;

export const renderMap = (props: IMapComponentProps, container: HTMLDivElement): void => {
    if (!root) {
        root = createRoot(container);
    }

    root.render(
        <AppContextProvider>
            <MapComponent {...props} />
        </AppContextProvider>
    );
};

export const unmountMap = () => {
    try {
        root.unmount();
        root = null;
    } catch {
        console.log("unmount error", root);
    }
};
