import { StyledNewProductForm } from './style'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import { Select } from '../../Select'
import { Checkbox } from '../../Checkbox'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { InputNumber } from '../../InputNumber'

export const NewProductBaseInfoForm = () => {
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: 'api/v2/filters/qmarks',
      data: {
        view: 'admin',
      },
    },
  })

  return (
    <StyledNewProductForm>
      <div className={'availability'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.availability' })}
        </div>
        <Input name={'availability'} />
      </div>

      <div className={'prod-availability'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.product_viable_in_mass' })}
        </div>
        <div className={'min-price col-djfgr'}>
          <div className={'min'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.min_amount' })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              validations={'minZeroNumber'}
              name={'mass_min_value'}
              initialValue={0}
            />
          </div>
          <div className={'price'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.bulk_price' })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              validations={'minZeroNumber'}
              name={'bulk_price'}
              initialValue={0}
            />
          </div>
        </div>
      </div>
      <div className={'additional-info'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.additional_information' })}
        </div>
        <div className={'chkboxes'}>
          <Checkbox options={data} name={'additional_info'} />
        </div>
      </div>
    </StyledNewProductForm>
  )
}
