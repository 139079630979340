import React from 'react'
import { MobileMenuStyledDrawer } from './style'

export interface MobileMenuDrawerProps {
  open?: boolean
  closable?: boolean
  destroyOnClose?: boolean
  children?: React.ReactNode
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
  footer?: React.ReactNode
  title?: string
  className?:string
}

export const MobileMenuDrawer = ({
  open,
  children,
  destroyOnClose,
  closable = true,
  onClose,
  footer,
  title,
  className
}: MobileMenuDrawerProps) => {

  return (
    <MobileMenuStyledDrawer
      className={className}
      id={'mobile-menu-drawer'}
      open={open}
      width={'100%'}
      destroyOnClose={destroyOnClose}
      getContainer={document.getElementById('app-root')!}
      closable={closable}
      onClose={onClose}
      footer={footer}
      placement={'bottom'}
      title={title}
      height={'100%'}
      styles={{
        content: { marginTop: 185, borderRadius: 15, paddingBottom: 150 },
        mask: { backgroundColor: 'transparent' },
        wrapper: { boxShadow: 'none', paddingBottom: 150 },
      }}
    >
      {children}
    </MobileMenuStyledDrawer>
  )
}
