import React from 'react'
import { StyledPopularProducts } from './style'
import { ProductData } from 'types/ProductData'
import { Card } from '../../../../ui/Card'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

interface PopularProductsProps {
  products?: ProductData[]
  farm_name?: string
  title?: string
}

const PopularProducts = ({
  products,
  farm_name,
  title,
}: PopularProductsProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const renderTitle = () => {
    if (title) {
      return <h1>{title}</h1>
    }

    if (farm_name) {
      const farmMessage = `${intl.formatMessage({
        id: 'popular_products.other',
      })} ${farm_name} ${intl.formatMessage({
        id: 'popular_products.production',
      })}`
      return <h1>{farmMessage}</h1>
    }

    return <h1>{intl.formatMessage({ id: 'popular_products.title' })}</h1>
  }

  return (
    <StyledPopularProducts className={'popular-products'}>
      <div className={'title'}>{renderTitle()}</div>

      <div className={'grid-view'}>
        {products?.map((product, index) => {
          return (
            <Card
              key={`product-${index}`}
              product={product}
              hoverable={true}
              imageUrl={product.image}
            />
          )
        })}
      </div>
    </StyledPopularProducts>
  )
}

export default PopularProducts
