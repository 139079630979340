import styled from 'styled-components'
import { Upload } from 'antd'
const { Dragger } = Upload

export const StyledNewProductsGallery = styled(Dragger)`
  .ant-upload-text {
    a {
      color: ${(props) => props.theme.blue};
    }
  }
`

export const StyledImagePreviews = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  max-width: 200px;
`
