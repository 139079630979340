import * as React from "react";
import { DemoUI, IDemoUIProps } from "./Demo/DemoUI";
import { Root, createRoot } from "react-dom/client";

/**
 * Renders UI on container. This method must be called every time you change UI props, with the same container node
 * @param propsUI UI props
 * @param containerUI HTML div node
 */
let root: Root;

export const renderUI = (propsUI: IDemoUIProps, containerUI: HTMLDivElement): void => {
    if (!root) {
        root = createRoot(containerUI);
    }
    root.render(<DemoUI {...propsUI} />);
};

/**
 * Unmounts UI component from the node.
 * @param container HTML div node
 * @param containerUI HTML div node
 */
export const unmountUI = (containerUI: HTMLDivElement): void => {
    root.unmount();
};
