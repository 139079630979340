import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, Tabs, Table, DatePicker, Dropdown } from 'ui'
import Filter from 'admin/components/Filter'
import { StyledButtonList } from 'styles/buttonList'
import dayjs from 'dayjs'
import EnumSelect from '../../../Selects/EnumSelect'
import useQueryApiClient from '../../../../utils/useQueryApiClient'
import FileDownload from 'js-file-download'
import { MenuProps } from 'antd/es/menu'

const TransactionListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({})

  const intl = useIntl()

  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/fidavista`,
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response) => {
      FileDownload(response, `Fidavista_${dayjs().format('YYYY-MM-DD')}.xml`)
    },
  })

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/transactions/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      )
    },
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'transactions.date' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'transactions.farm_name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.settlement_deed' }),
      dataIndex: 'act_nr', //todo
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.order_nr' }),
      dataIndex: 'number',
      render: (value: string) => value || 'Nav numura',
    },
    {
      title: intl.formatMessage({ id: 'transactions.invoice_nr' }),
      dataIndex: 'invoice_nr',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.status' }),
      dataIndex: 'status',
      render: (value: string) =>
        intl.formatMessage({ id: `enum.${value.toUpperCase()}` }),
    },
    {
      title: intl.formatMessage({ id: 'transactions.status_desc' }),
      dataIndex: 'status_comments',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.bill_status' }),
      dataIndex: 'billing_status',
      render: (value: number) => {
        switch (value) {
          case 0:
            return intl.formatMessage({ id: 'enum.NOT_PAID' })
          case 1:
            return intl.formatMessage({ id: 'enum.PAID' })
          case 2:
            return intl.formatMessage({ id: 'enum.CANCELED' })
        }
      },
    },
    {
      title: intl.formatMessage({ id: 'transactions.price' }),
      dataIndex: 'total_sum',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'bill',
    },
  ]

  const items = [
    {
      key: 'active_transactions',
      label: intl.formatMessage({ id: 'transactions.active_transactions' }),
      children: (
        <>
          <Filter onFinish={setAdvancedSearch}>
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_from' })}
              name="date_from"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.date_to' })}
              name="date_to"
            />
            <EnumSelect
              label={intl.formatMessage({ id: 'transactions.delivered' })}
              name="delivered"
              code="PurchaseStatusType"
            />
            <EnumSelect
              label={intl.formatMessage({ id: 'transactions.bill_status' })}
              name="billing_status"
              code="BillStatusType"
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/transactions"
            columns={columns}
            linkProps={{ url: '/admin/form' }}
            filter={advancedSearch}
          />
        </>
      ),
    },
    {
      key: 'old_transactions',
      label: intl.formatMessage({ id: 'transactions.old_transactions' }),
      children: `Content of tab`,
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv') {
      exportData({ format: e.key }, [], e.key)
    }
  }

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: '1',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ]

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  }

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.transactions' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                type="primary"
                label={intl.formatMessage({ id: 'transactions.fida' })}
                onClick={() => appendData()}
              />
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
            </StyledButtonList>
          }
          items={items}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default TransactionListPage
