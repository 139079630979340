import { ImageProps } from '../../../types/BannerData'
import { StyledBannerImagePreview } from './style'
import { Image } from 'antd'
import { FallbackImage } from '../fallBack/FallBackImage'

interface PreviewProps {
  images: ImageProps[]
  type: 'banner_1' | 'banner_2' | 'banner_3' | 'banner_4' | undefined
}

export const Preview = ({ images, type }: PreviewProps) => {
  return (
    <StyledBannerImagePreview>
      {images.length >= 1 ? (
        images.map((image, index) => {
          return (
            <div key={index}>
              <FallbackImage src={`/${image.url_original}`} type={type} />
            </div>
          )
        })
      ) : (
        <FallbackImage src={''} type={type} />
      )}
    </StyledBannerImagePreview>
  )
}
