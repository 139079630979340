import React from 'react'
import { Link } from 'react-router-dom'

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex?: string
  record: any
  className: string
  colSpan?: number
  linkProps?: {
    url: string
    recordKey?: string
  }
}

export const EditableCell: React.FC<EditableCellProps> = React.memo(
  ({ record, children, className, dataIndex, colSpan, linkProps }) => {
    // Actions don't have links
    if (dataIndex === 'id' || dataIndex === undefined) {
      return (
        <td className={className} colSpan={colSpan}>
          {children}
        </td>
      )
    }

    const parsedProps = {
      url: linkProps?.url,
      recordKey: linkProps?.recordKey || 'id',
    }

    if (colSpan) {
      parsedProps.url = undefined
    }

    if (!linkProps?.url) {
      return (
        <td className={className} colSpan={colSpan}>
          {children}
        </td>
      )
    }

    return (
      <td className={`${className} history-clickable`} colSpan={colSpan}>
        <Link
          to={
            parsedProps.url
              ? parsedProps.url.replace(
                  ':id',
                  record?.[parsedProps.recordKey] || 'undefined'
                )
              : ''
          }
        >
          {children}
        </Link>
      </td>
    )
  }
)
