import styled from 'styled-components'

export const StyledLoginPage = styled('div')`
  .content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    background-image: url('/man-farmland-with-watering-can.png');
    background-size: cover;
    background-position: center;
  }
`
