import * as React from "react";
import { FC } from "react";
import { Feature } from "ol";
import { Point } from "ol/geom";

export interface IAttributeTableProps {
    feature: Feature;
    onZoomToFeature: (feature: Feature) => void;
}
export const DemoAttributeTable: FC<IAttributeTableProps> = (props) => {
    return (
        <table>
            <tbody>
                <tr>
                    <th></th>
                    <th>
                        <div>
                            <button onClick={() => props.onZoomToFeature(props.feature)}>Zoom To</button>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>ID</th>
                    <td>
                        <div>{props.feature.getId()}</div>
                    </td>
                </tr>
                <tr>
                    <th>Coordinates</th>
                    <td>
                        <div>{(props.feature.getGeometry() as Point).getCoordinates()}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
