import React from 'react'
import { StyledPage } from './style'
import { Breadcrumb } from 'antd'
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb'

interface PageLayoutsProps {
  children?: React.ReactNode
  breadcrumbs?: ItemType[]
}

const PageLayout = ({ children, breadcrumbs }: PageLayoutsProps) => {
  return (
    <StyledPage className={'page-layout'}>
      <Breadcrumb items={breadcrumbs}></Breadcrumb>
      {children}
    </StyledPage>
  )
}

export default PageLayout
