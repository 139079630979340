import styled from 'styled-components'

export const StyledSearch = styled.div`
  flex-direction: row;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 0;

  .flex-grow {
    flex-grow: 2;
    display: flex;
  }

  .ant-form-item {
    margin: 0;
    display: flex;
    flex-grow: 1;
    .ant-form-item-row {
      display: flex;
      flex-grow: 1;
    }
    .ant-row {
      display: flex;
      flex-grow: 1;
    }
  }

  .input-container {
    flex-grow: 1;
  }
`
