import { StyledFooter } from './style'
import { useIntl } from 'react-intl'
import { Icon } from 'ui'

interface ContactContent {
  icon: string
  content: string
}

const contactContent: ContactContent[] = [
  { icon: 'bank-icon', content: 'footer.bank' },
  { icon: 'location-tick-icon', content: 'footer.location-tick' },
  { icon: 'sms-tracking-icon', content: 'footer.sms-tracking' },
  { icon: 'call-calling-icon', content: 'footer.call-calling' },
]

const DefaultFooter = () => {
  const intl = useIntl()
  const copyrightText = intl.formatMessage({ id: 'footer.copyright' })

  return (
    <StyledFooter>
      <div className={'footer-contacts'}>
        <div className="footer-title">
          <p>Kontakti</p>
          <div className={'footer-icon-wrapper'}>
            <Icon name={'twitter'} type={'card'} />
            <Icon name={'youtube'} type={'card'} />
            <Icon name={'intagram'} type={'card'} />
          </div>
        </div>
        <div className={'footer-content-wrapper'}>
          {contactContent.map((content, index) => (
            <div className={'footer-content-container'} key={index}>
              <Icon name={content.icon} type={'avarage'} />
              <div className={'footer-content'}>
                <p>{intl.formatMessage({ id: content.content })}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-image-wrapper"></div>
      <div
        dangerouslySetInnerHTML={{ __html: copyrightText }}
        className="footer-copyright"
      ></div>
      <div className="mobile-footer-image-wrapper"></div>
    </StyledFooter>
  )
}

export default DefaultFooter
