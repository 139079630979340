import React, { useState } from 'react'
import { FarmData } from '../../../types/FarmData'
import { useIntl } from 'react-intl'
import { StyledFarmView } from './style'
import { Menu, Spin } from 'antd'
import type { MenuInfo } from 'rc-menu/lib/interface'
import { Button, Icon, Spinner } from 'ui'
import PopularProducts from 'public/components/DefaultLayout/PopularProducts'
import DOMPurify from 'dompurify'
import FarmCardTabs from 'enums/farm-card-tabs'
import Galeries from './components/Galeries'
import Partners from './components/Partners'
import Deliveries from './components/Deliveries'
import WorkingTime from './components/WorkingTime'

export interface FarmViewProps {
  farm?: FarmData
}

const FarmView = ({ farm }: FarmViewProps) => {
  const intl = useIntl()
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(FarmCardTabs.DESCRIPTION)
  const products = farm?.products || []
  const workingTimeData = farm?.working_time
  const workingTime = workingTimeData ? JSON.parse(workingTimeData) : null
  const daysOfWeek = [
    intl.formatMessage({ id: 'open_hours.Monday' }),
    intl.formatMessage({ id: 'open_hours.Tuesday' }),
    intl.formatMessage({ id: 'open_hours.Wednesday' }),
    intl.formatMessage({ id: 'open_hours.Thursday' }),
    intl.formatMessage({ id: 'open_hours.Friday' }),
    intl.formatMessage({ id: 'open_hours.Saturday' }),
    intl.formatMessage({ id: 'open_hours.Sunday' }),
  ]

  const renderContent = (menuItem: string) => {
    switch (menuItem) {
      case FarmCardTabs.DESCRIPTION:
        return (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(farm?.description || ''),
            }}
          ></div>
        )
      case FarmCardTabs.WORKING_TIME:
        return (
          <div className="working-time-container">
            <WorkingTime workingTime={workingTime} daysOfWeek={daysOfWeek} workingTimeTitle={farm?.actual_full_address} />
          </div>
        )
      case FarmCardTabs.DELIVERY:
        return <div className='farm-delivery'><Deliveries farm={farm} daysOfWeek={daysOfWeek} /></div>
      case FarmCardTabs.GALLERY:
        return <Galeries farm={farm} />
      case FarmCardTabs.PARTNERS:
        return <Partners farm={farm} />
      case FarmCardTabs.MEMBERS:
        return <>{intl.formatMessage({ id: 'farms.members' })}</>

      default:
        return null
    }
  }

  const items = [
    {
      key: FarmCardTabs.DESCRIPTION,
      label: intl.formatMessage({ id: 'farms.description' }),
    },
    {
      key: FarmCardTabs.WORKING_TIME,
      label: intl.formatMessage({ id: 'farms.working_time' }),
    },
    {
      key: FarmCardTabs.DELIVERY,
      label: intl.formatMessage({ id: 'farms.delivery' }),
    },
    {
      key: FarmCardTabs.GALLERY,
      label: intl.formatMessage({ id: 'farms.gallery' }),
    },
    {
      key: FarmCardTabs.PARTNERS,
      label: intl.formatMessage({ id: 'farms.partners' }),
    },
    {
      key: FarmCardTabs.MEMBERS,
      label: intl.formatMessage({ id: 'farms.members' }),
    },
  ]

  const mobileRenderContent = (menuItem: string) => {
    switch (menuItem) {
      case FarmCardTabs.DESCRIPTION:
        return (
          <div className={'farm-info'}>
            <div className={'farm-img-box'}>
              <img alt={'farm-img'} src={'../rt6rp0d0e2o 1.png'} />
              <div className="logos">
                <img
                  alt={'logo'}
                  src={'../qualityMark/eu_organic_logo_colour_rgb.jpg'}
                />
                <img alt={'logo'} src={'../qualityMark/karote.jpg'} />
                <img
                  alt={'logo'}
                  src={'../qualityMark/global-gap-certification-500x500.jpg'}
                />
              </div>
            </div>
            <h1>{farm?.name}</h1>
            <div className={'mobile-farm-info'}>
              <div className={'mobile-info-div'}>
                <div className={'info-column'}>
                  <Icon name="user-square" />
                  {farm?.contact_name ||
                    intl.formatMessage({ id: 'farms.contact_name' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="sms-tracking" />
                  {farm?.contact_email ||
                    intl.formatMessage({ id: 'farms.email' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="call-calling" />
                  {farm?.contact_phone ||
                    intl.formatMessage({ id: 'farms.contact_phone' })}
                </div>
                <div className={'info-column'}>
                  <Icon name="export" />
                  <a
                    href={farm?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {farm?.website || intl.formatMessage({ id: 'farms.email' })}
                  </a>
                </div>
                <div className={'info-column'}>
                  <Icon name="location-tick-icon" />
                  {farm?.actual_address ||
                    'Krasta iela 7, Līksna, Līksnas pag., Augšdaugavas nov., LV-5456'}
                </div>
                <div className={'bookmark'}>
                  <div className={'bookmark-2'}>
                    <Icon name="bookmark-2" />
                    {intl.formatMessage({
                      id: 'farms.reg_nr_short',
                    })}
                    {farm?.reg_nr} <br />
                    {intl.formatMessage({
                      id: 'farms.pvd_nr',
                    })}
                    {farm?.pvd_nr}
                  </div>
                  <div className={'mobile-buttons'}>
                    <a href={farm?.website}>
                      <Button
                        label={intl.formatMessage({ id: 'farms.google' })}
                      />
                    </a>
                    <a href={farm?.website}>
                      <Button
                        label={intl.formatMessage({ id: 'farms.waze' })}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mobile-description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(farm?.description || ''),
              }}
            ></div>
          </div>
        )
      case FarmCardTabs.PRODUCTS:
        return (
          <>
            {!!products?.length && (
              <div className="other-products-container">
                <PopularProducts
                  title={`${farm?.name} produkcija`}
                  products={products}
                  farm_name={farm?.name}
                />
              </div>
            )}
          </>
        )
      case FarmCardTabs.WORKING_TIME:
        return (
          <div className="working-time-container">
            <WorkingTime workingTime={workingTime} daysOfWeek={daysOfWeek} workingTimeTitle={farm?.actual_full_address} />
          </div>
        )
      case FarmCardTabs.DELIVERY:
        return <div className='farm-delivery'><Deliveries farm={farm} daysOfWeek={daysOfWeek} /></div>
      case FarmCardTabs.GALLERY:
        return <Galeries farm={farm} />
      case FarmCardTabs.PARTNERS:
        return <Partners farm={farm} />
      case FarmCardTabs.MEMBERS:
        return <>{intl.formatMessage({ id: 'farms.members' })}</>
    }
  }

  const MobileItems = [
    {
      key: FarmCardTabs.DESCRIPTION,
      label: intl.formatMessage({ id: 'farms.description' }),
    },
    {
      key: FarmCardTabs.PRODUCTS,
      label: intl.formatMessage({ id: 'farms.products' }),
      className: 'menu-products',
    },
    {
      key: FarmCardTabs.WORKING_TIME,
      label: intl.formatMessage({ id: 'farms.working_time' }),
    },
    {
      key: FarmCardTabs.DELIVERY,
      label: intl.formatMessage({ id: 'farms.delivery' }),
    },
    {
      key: FarmCardTabs.GALLERY,
      label: intl.formatMessage({ id: 'farms.gallery' }),
    },
    {
      key: FarmCardTabs.PARTNERS,
      label: intl.formatMessage({ id: 'farms.partners' }),
    },
    {
      key: FarmCardTabs.MEMBERS,
      label: intl.formatMessage({ id: 'farms.members' }),
    },
  ]

  const handleMenuClick = (e: MenuInfo) => {
    setSelectedMenuItem(e.key)
  }

  if (!farm) {
    return <Spinner spinning={true} wrapperClassName="spinner" ><Spin/></Spinner>;
  }
  
  return (
    <StyledFarmView>
      <div className={'mobile-menu-container'}>
        <ul className="custom-menu">
          {MobileItems.map((item) => (
            <li
              key={item.key}
              className={`custom-menu-item ${selectedMenuItem === item.key ? 'active' : ''
                }`}
              onClick={() => setSelectedMenuItem(item.key)}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className={'content-section'}>
          {mobileRenderContent(selectedMenuItem)}
        </div>
      </div>
      <div className={'farm-details'}>
        <div className={'farm-info'}>
          <div className={'farm-img-box'}>
            <img alt={'farm-img'} src={'../rt6rp0d0e2o 1.png'} />
            <div className={'bottom-img'}>
              <img alt={'quality-mark-img'} src={'../qualityMark/3-09 1.jpg'} />
            </div>
          </div>
          <div className={'farm-contact'}>
            <div className={'farm-title'}>
              <h1>{farm.name}</h1>
              <div className="logos">
                {
                  farm.quality_marks && farm.quality_marks?.map(qualityMark => <img
                    alt={qualityMark.name}
                    src={'../qualityMark/eu_organic_logo_colour_rgb.jpg'}
                  />)
                }
              </div>
              <div className={'mobile-website-box'}>
                <div className={'mobile-website'}>
                  <a
                    href={farm.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {farm.website}
                  </a>
                  <div>
                    <a href={farm.website}>
                      <Button
                        label={intl.formatMessage({ id: 'farms.google' })}
                      />
                    </a>
                    <a href={farm.website}>
                      <Button
                        label={intl.formatMessage({ id: 'farms.waze' })}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={'farm-datas'}>
              <ul>
                <li>
                  <Icon name="red-mail" />
                  {farm.contact_email ||
                    intl.formatMessage({ id: 'farms.email' })}
                </li>
                <li>
                  <Icon name="red-phone" />
                  {farm.contact_phone ||
                    intl.formatMessage({ id: 'farms.contact_phone' })}
                </li>
              </ul>

              <ul>
                <li>{farm.actual_full_address}</li>
              </ul>

              <ul>
                <li>
                  {intl.formatMessage({
                    id: 'farms.reg_nr_short',
                  })}
                  {farm.reg_nr}
                </li>
                <li>
                  {intl.formatMessage({
                    id: 'farms.pvd_nr',
                  })}
                  {farm.pvd_nr}
                </li>
              </ul>

              <ul>
                <li>
                  <a href={farm.website}>{farm.website || 'www.example.org'}</a>
                </li>
                <li>
                  <a href={farm.website}>
                    <Button
                      label={intl.formatMessage({ id: 'farms.google' })}
                    />
                  </a>
                </li>
                <li>
                  <a href={farm.url}>
                    <Button label={intl.formatMessage({ id: 'farms.waze' })} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={'mobile-farm-datas-div'}>
          <div className={'mobile-farm-datas'}>
            <ul>
              <li>
                <Icon name="red-mail" />
                {farm.contact_email}
              </li>
              <li>
                <Icon name="red-phone" />
                {farm.contact_phone}
              </li>
            </ul>

            <ul>
              <li>{farm.actual_full_address}</li>
            </ul>

            <ul>
              <li>
                {intl.formatMessage({
                  id: 'farms.reg_nr_short',
                })}
                {farm.reg_nr}
              </li>
              <li>
                {intl.formatMessage({
                  id: 'farms.pvd_nr',
                })}
                {farm.pvd_nr}
              </li>
            </ul>

            <ul>
              <li>
                <a href={farm.website}>{farm.website}</a>
              </li>
              <li>
                <a href={farm.website}>
                  <Button label={intl.formatMessage({ id: 'farms.google' })} />
                </a>
              </li>
              <li>
                <a href={farm.url}>
                  <Button label={intl.formatMessage({ id: 'farms.waze' })} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={'menu-container'}>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[selectedMenuItem]}
            items={items}
            onClick={handleMenuClick}
            style={{ flex: 1, minWidth: 0 }}
          />
          <div className={'content-section'}>
            {renderContent(selectedMenuItem)}
          </div>
        </div>
      </div>
      {!!products?.length && (
        <div className="desktop-products-container">
          <PopularProducts
            title={`${farm?.name} ${intl.formatMessage({ id: 'farms.products' })}`}
            products={products}
            farm_name={farm?.name}
          />
        </div>
      )}
    </StyledFarmView>
  )
}

export default FarmView
