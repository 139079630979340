import React, { useState } from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import { Button, Tabs, Table, DatePicker, Dropdown, Input, Modal } from 'ui'
import Filter from 'admin/components/Filter'
import { StyledButtonList } from 'styles/buttonList'
import dayjs from 'dayjs'
import useQueryApiClient from '../../../../utils/useQueryApiClient'
import FileDownload from 'js-file-download'
import { MenuProps } from 'antd/es/menu'
import { Form } from 'antd'

const FarmUserListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState({})
  const [showCreateModal, setShowCreateModal] = useState<boolean>()

  const intl = useIntl()
  const [form] = Form.useForm()

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/users/farms/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      )
    },
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'farm_users.full_name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'general.email' }),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({ id: 'general.role' }),
      dataIndex: 'role',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
    },
  ]

  const items = [
    {
      key: 'farm_users',
      label: intl.formatMessage({ id: 'navigation.farm_users' }),
      children: (
        <>
          <Filter onFinish={setAdvancedSearch}>
            <Input label={intl.formatMessage({ id: 'general.id' })} name="id" />
            <Input
              label={intl.formatMessage({ id: 'farm_users.full_name' })}
              name="full_name"
            />
            <Input
              label={intl.formatMessage({ id: 'general.email' })}
              name="email"
            />
            <Input
              label={intl.formatMessage({ id: 'general.role' })}
              name="role"
            />

            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />
          </Filter>
          <Table
            url="/api/v2/users/farms"
            columns={columns}
            filter={advancedSearch}
          />
        </>
      ),
    },
  ]

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv') {
      exportData({ format: e.key }, [], e.key)
    }
  }

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.print' }),
      key: '1',
    },
    {
      label: intl.formatMessage({ id: 'general.copy' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ]

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  }

  const onCloseModal = () => {
    setShowCreateModal(false)
    form.resetFields()
  }

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.farm_users' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
              <Button
                label={intl.formatMessage({ id: 'general.create' })}
                type="primary"
                onClick={() => setShowCreateModal(true)}
              />
            </StyledButtonList>
          }
          items={items}
        />

        <Modal
          title={intl.formatMessage({ id: 'farm_users.create_modal' })}
          open={showCreateModal}
          onCancel={onCloseModal}
        >
          test
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default FarmUserListPage
