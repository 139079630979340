import { StyledDrawer, StyledFooter } from './style'
import { Tabs } from '../../Tabs'
import { useIntl } from 'react-intl'
import { DrawerProps } from '../Drawer'
import { NewProductForm } from '../../Form/products/NewProductForm'
import { Button } from '../../Button'
import { NewProductBaseInfoForm } from '../../Form/products/NewProductBaseInfoForm'
import { NewProductGallery } from '../../Form/products/NewProductGallery'
import { NewProductMacros } from '../../Form/products/NewProductMacros'
import { NewProductDeliveryForm } from '../../Form/products/NewProductDeliveryForm'
import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import useQueryApiClient from '../../../utils/useQueryApiClient'

interface DrawerCreateProductProps {
  farmId?: number
  open?: boolean
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

export const DrawerCreateProduct = ({
  open,
  onClose,
  farmId,
}: DrawerCreateProductProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [imageValidated, setImageValidated] = useState(false)

  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/products`,
      method: 'POST',
    },
  })
  const intl = useIntl()

  const steps = [
    {
      key: 'add_product',
      label: intl.formatMessage({ id: 'navigation.information' }),
      content: <NewProductForm />,
    },
    {
      key: 'add_product_base',
      label: intl.formatMessage({ id: 'navigation.base_information' }),
      content: <NewProductBaseInfoForm />,
    },
    {
      key: 'add_product_image',
      label: intl.formatMessage({ id: 'navigation.gallery' }),
      content: <NewProductGallery onValidate={setImageValidated} />,
    },
    {
      key: 'add_product_macros',
      label: intl.formatMessage({ id: 'navigation.macros' }),
      content: <NewProductMacros />,
    },
    {
      key: 'add_products_delivery',
      label: intl.formatMessage({ id: 'navigation.delivery' }),
      content: <NewProductDeliveryForm />,
    },
  ]
  const [form] = Form.useForm()
  const nextStep = async () => {
    try {
      await form.validateFields()

      if (steps[currentStep].key === 'add_product_image' && !imageValidated) {
        message.error(
          intl.formatMessage({
            id: 'validation.image_upload_required',
          })
        )
        return
      }
      setCurrentStep(currentStep + 1)
    } catch (error: unknown) {
      if (isValidationError(error)) {
        error.errorFields.forEach((fieldError) => {
          message.error(
            intl.formatMessage({
              id: `validation.${fieldError.name[0]}`,
            })
          )
        })
      } else {
        message.error(intl.formatMessage({ id: 'validation.error' }))
      }
    }
  }

  function isValidationError(
    error: unknown
  ): error is { errorFields: { name: string[] }[] } {
    return typeof error === 'object' && error !== null && 'errorFields' in error
  }

  const handleCancelClick = (e: React.MouseEvent) => {
    form.resetFields()
    setCurrentStep(0)
    setImageValidated(false)

    if (onClose) {
      onClose(e)
    }
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields()

      const formData = form.getFieldsValue()

      if (farmId) {
        appendData({ ...formData, farm_id: farmId })
      }
    } catch (error: unknown) {
      if (isValidationError(error)) {
        error.errorFields.forEach((fieldError) => {
          message.error(
            intl.formatMessage({
              id: `validation.${fieldError.name[0]}`,
            })
          )
        })
      } else {
        message.error(intl.formatMessage({ id: 'validation.error' }))
      }
    }
  }

  const Footer = () => (
    <StyledFooter>
      <div className={'btns'}>
        {currentStep === 0 && (
          <Button
            label={intl.formatMessage({ id: 'general.cancel' })}
            onClick={handleCancelClick}
          />
        )}
        {currentStep > 0 && (
          <Button
            label={intl.formatMessage({ id: 'general.previous' })}
            onClick={prevStep}
          />
        )}
        {currentStep < steps.length - 1 && (
          <Button
            label={intl.formatMessage({ id: 'general.next_step' })}
            type={'primary'}
            onClick={nextStep}
          />
        )}
        {currentStep === steps.length - 1 && (
          <Button
            label={intl.formatMessage({ id: 'general.submit' })}
            type={'primary'}
            onClick={handleSubmit}
          />
        )}
      </div>
    </StyledFooter>
  )

  return (
    <StyledDrawer open={open} onClose={onClose} footer={<Footer />}>
      <Form form={form}>
        <Tabs
          activeKey={steps[currentStep].key}
          items={steps.map((step) => ({
            key: step.key,
            label: step.label,
            children: step.content,
          }))}
          defaultActiveKey={steps[currentStep].key}
        />
      </Form>
    </StyledDrawer>
  )
}
