import { FormInstance } from 'antd'
import { FarmProduct, SelectedDelivery } from 'public/context/CartContext'
import React from 'react'
import { FarmDeliveries } from 'types/FarmData'
import DpdpDelivery from './DpdDelivery/DpdDelivery'
import IzipiziDelivery from './IzipiziDelivery/IzipiziDelivery'
import OmnivaDelivery from './OmnivaDelivery/OmnivaDelivery'
import SelfDelivery from './SelfDelivery/SelfDelivery'
import { StyledSelectDelivery } from './style'
import VenipakDelivery from './VenipakDelivery/VenipakDelivery'

interface SelectDeliveryProps {
  farmProduct: FarmProduct
  selectedDelivery: FarmDeliveries
  setSelectedDeliveryData: React.Dispatch<
    React.SetStateAction<SelectedDelivery[] | null>
  >
  selectedDeliveryData: SelectedDelivery[] | null
  deliveryForm: FormInstance<any>
}

const SelectDelivery = ({
  selectedDelivery,
  setSelectedDeliveryData,
  farmProduct,
  selectedDeliveryData,
  deliveryForm,
}: SelectDeliveryProps) => {
  return (
    <StyledSelectDelivery>
      {selectedDelivery?.type === 'self' && (
        <SelfDelivery
          selectedDelivery={selectedDelivery}
          setSelectedDeliveryData={setSelectedDeliveryData}
          selectedDeliveryData={selectedDeliveryData}
        />
      )}
      {selectedDelivery?.type === 'omniva' && (
        <OmnivaDelivery
          farmProduct={farmProduct}
          selectedDelivery={selectedDelivery}
          setSelectedDeliveryData={setSelectedDeliveryData}
          selectedDeliveryData={selectedDeliveryData}
          deliveryForm={deliveryForm}
        />
      )}
      {selectedDelivery?.type === 'parcel' && (
        <DpdpDelivery
          farmProduct={farmProduct}
          selectedDelivery={selectedDelivery}
          setSelectedDeliveryData={setSelectedDeliveryData}
          selectedDeliveryData={selectedDeliveryData}
          deliveryForm={deliveryForm}
        />
      )}
      {selectedDelivery?.type === 'delivery' && (
        <VenipakDelivery
          farmProduct={farmProduct}
          selectedDelivery={selectedDelivery}
          setSelectedDeliveryData={setSelectedDeliveryData}
          selectedDeliveryData={selectedDeliveryData}
          deliveryForm={deliveryForm}
        />
      )}
      {selectedDelivery?.type === 'izipizi' && (
        <IzipiziDelivery
          farmProduct={farmProduct}
          selectedDelivery={selectedDelivery}
          setSelectedDeliveryData={setSelectedDeliveryData}
          selectedDeliveryData={selectedDeliveryData}
          deliveryForm={deliveryForm}
        />
      )}
    </StyledSelectDelivery>
  )
}

export default SelectDelivery
