interface Props {
    daysOfWeek: string[],
    workingTime: { no: string, lidz: string }[]
    workingTimeTitle: string | undefined
}


const WorkingTime = ({ daysOfWeek, workingTime, workingTimeTitle }: Props) => (
    <>
        <p>{workingTimeTitle}</p>
        <div className="working-time">
            {daysOfWeek.map((day, index) => (
                <div key={index} className="day-time-row">
                    <span className="day-name">{day}</span>
                    <span className="day-time">
                        {workingTime[index + 1].no} - {workingTime[index + 1].lidz}
                    </span>
                </div>
            ))}
        </div>
    </>
);

export default WorkingTime;