import useQueryApiClient from '../../../utils/useQueryApiClient'
import { useIntl } from 'react-intl'
import { ProfileForm } from '../../../ui/Form/Profile'
import { message } from 'antd'

export const Profile = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const intl = useIntl()
  const { appendData } = useQueryApiClient({
    request: {
      url: `api/v2/profile`,
      method: 'POST',
    },
    onSuccess: () =>
      messageApi.open({
        type: 'success',
        content: intl.formatMessage({ id: 'form.succesfuly_updated' }),
      }),
    onError: () =>
      messageApi.open({
        type: 'error',
        content: intl.formatMessage({ id: 'form.error' }),
      }),
  })

  const handleSave = async (formData: object) => {
    appendData(formData)
  }

  return (
    <>
      {contextHolder}
      <h1>{intl.formatMessage({ id: 'general.profile_information' })}</h1>
      <ProfileForm sendFormData={handleSave} />
    </>
  )
}
