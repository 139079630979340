import { Filter } from 'types/Filter'
import { FilterOptions } from 'types/FilterOptions'
import { MobileFilterForm } from 'ui/Form/MobileFilterForm/MobileFilterForm'

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<Filter>>
  filterOptions: FilterOptions
  filter: Filter
}

const MobileFilter = ({ setFilter, filterOptions, filter }: Props) => {
  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter)
  }

  return (
    <div className={'mobile-filter-container'}>
      <MobileFilterForm
        setFilter={handleFilterChange}
        filterOptions={filterOptions}
        filter={filter}
      />
    </div>
  )
}

export default MobileFilter
