import React, { useState } from 'react'
import { StyledProcessPurchaseForm } from './style'
import { Form, FormInstance } from 'antd'
import { Checkbox, CustomGrid, Input, TextArea } from 'ui'
import { useIntl } from 'react-intl'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface ProcessPurchaseFormProps {
  form: FormInstance
}

const ProcessPurchaseForm = ({ form }: ProcessPurchaseFormProps) => {
  const intl = useIntl()
  const [isLegalPerson, setIsLegalPerson] = useState<boolean>(false)

  const isLegalPersonChange = (isLegalPerson: boolean) => {
    setIsLegalPerson(isLegalPerson)
  }

  return (
    <StyledProcessPurchaseForm>
      <Form form={form} layout="vertical">
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            isLegalPersonChange(e.target.checked)
          }
          name="checked"
          value={'process_purchase.buy_as_legal_person'}
          checked={isLegalPerson}
          label={intl.formatMessage({
            id: 'process_purchase.buy_as_legal_person',
          })}
        />
        {!isLegalPerson && (
          <CustomGrid inOneRow={2}>
            <Input
              label={intl.formatMessage({ id: 'process_purchase.name' })}
              name="name"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.client_surname',
              })}
              name="client_surname"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.country' })}
              name="country"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.city' })}
              name="city"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.address' })}
              name="address"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.post_index' })}
              name="post_index"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.e_mail' })}
              name="e_mail"
              validations={'email'}
            />
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.phone_number',
              })}
              name="phone_number"
              validations={'phoneNumber'}
            />
          </CustomGrid>
        )}
        {isLegalPerson && (
          <CustomGrid inOneRow={2}>
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.company_name',
              })}
              name="company_name"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.registration_number',
              })}
              name="registration_number"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.PVN_registration_number',
              })}
              name="PVN_registration_number"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.city' })}
              name="city"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.address' })}
              name="address"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.post_index' })}
              name="post_index"
              validations={'required'}
            />
            <Input
              label={intl.formatMessage({ id: 'process_purchase.e_mail' })}
              name="e_mail"
              validations={'email'}
            />
            <Input
              label={intl.formatMessage({
                id: 'process_purchase.phone_number',
              })}
              name="phone_number"
              validations={'phoneNumber'}
            />
          </CustomGrid>
        )}

        <CustomGrid inOneRow={1}>
          <div className={'purchase-form-title'}>
            {intl.formatMessage({ id: 'process_purchase.comment' })}
          </div>
          <TextArea />
        </CustomGrid>
      </Form>
    </StyledProcessPurchaseForm>
  )
}

export default ProcessPurchaseForm
