import React, { useEffect, useState } from 'react'
import { Form, FormInstance, RadioChangeEvent, Space } from 'antd'
import { FarmDeliveries } from 'types/FarmData'
import SelectDelivery from '../SelectDelivery'
import {
  FarmProduct,
  SelectedDelivery,
  useCartDispatch,
} from 'public/context/CartContext'
import { Radio, RadioGroup } from 'ui'

interface DeliveryFormProps {
  farmProduct: FarmProduct
  deliveryForm: FormInstance<any>
  registerSubmitCallback: (callback: () => void) => void
}

const DeliveryForm = ({
  farmProduct,
  deliveryForm,
  registerSubmitCallback,
}: DeliveryFormProps) => {
  const dispatchCart = useCartDispatch()
  const [selectedDelivery, setSelectedDelivery] = useState<
    FarmDeliveries | undefined
  >(() => {
    const selectedDelivery = farmProduct?.selectedDelivery?.selectedDeliveryId
    return farmProduct?.farmDeliveries?.find(
      (delivery) => delivery.id === selectedDelivery
    )
  })
  const [selectedDeliveryData, setSelectedDeliveryData] = useState<
    SelectedDelivery[] | null
  >(null)

  const deliveriesSubmited = () => {
    if (selectedDeliveryData)
      selectedDeliveryData.map((selectedData) => {
        dispatchCart({
          type: 'SET_DELIVERY',
          farmId: farmProduct.farm_id,
          delivery: selectedData,
        })
      })
  }

  const handleDeliveryChange = (e: RadioChangeEvent) => {
    const deliveryId = e.target.value as number
    const handledDelivery = farmProduct?.farmDeliveries?.find(
      (delivery) => delivery.id === deliveryId
    )
    setSelectedDelivery(handledDelivery)
  }

  useEffect(() => {
    registerSubmitCallback(deliveriesSubmited)
  }, [selectedDeliveryData, selectedDelivery])

  return (
    <div className={'delivery-options-container'}>
      <div className="pick-delivery-header">
        <div>{farmProduct.farm_name}</div>
      </div>
      <div className={'delivery-options-wrapper'}>
        <div className={'delivery-radio-group'}>
          <RadioGroup
            onChange={handleDeliveryChange}
            initialValue={selectedDelivery?.id}
            value={selectedDelivery?.id}
            children={farmProduct?.farmDeliveries?.map((delivery, inddex) => (
              <Radio key={inddex} label={delivery.name} value={delivery.id} />
            ))}
          />
        </div>
        {selectedDelivery && (
          <SelectDelivery
            farmProduct={farmProduct}
            selectedDelivery={selectedDelivery}
            setSelectedDeliveryData={setSelectedDeliveryData}
            selectedDeliveryData={selectedDeliveryData}
            deliveryForm={deliveryForm}
          />
        )}
      </div>
    </div>
  )
}

export default DeliveryForm
