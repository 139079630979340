import { StyledCartSummary } from './style'
import { Button, Checkbox, Icon, Input } from 'ui'
import { Form, FormInstance } from 'antd'
import { useIntl } from 'react-intl'
import {
  FarmProduct,
  useCartDispatch,
  useCartState,
} from 'public/context/CartContext'
import useWindowSize from 'utils/useWindowSize'
import { useEffect } from 'react'
import { options } from 'config/config'

interface PrivatePersonValues {
  name: string
  surname: string
  country: string
  city: string
  address: string
  post_index: string
  e_mail: string
  phone_number: string
  discount_coupon?: string
  news_subscribe?: boolean
}

interface CasualUserValues {
  company_name: string
  registration_number: string
  PVN_registration_number?: string
  city: string
  address: string
  post_index: string
  discount_coupon?: string
  news_subscribe?: boolean
}

type FormValues = PrivatePersonValues | CasualUserValues

interface CartSummaryProps {
  farmProducts: FarmProduct[]
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  form?: FormInstance
  current: number
  action?: 'view'
}

const CartSummary = ({
  farmProducts,
  setCurrent,
  form,
  current,
  action,
}: CartSummaryProps) => {
  const { priceTotal } = useCartState()
  const dispatchCart = useCartDispatch()
  const intl = useIntl()
  const VAT_RATE_21 = options.market.VAT_RATE_21
  const VAT_RATE_05 = options.market.VAT_RATE_05
  const [_, windowWidth] = useWindowSize()

  const priceDiscountTotal = farmProducts.reduce(
    (total, farm) =>
      farm.farmProductsDiscountPrice
        ? total + farm.farmProductsDiscountPrice
        : total,
    0
  )

  const baseTotalWithoutVAT_21 = priceDiscountTotal / (1 + VAT_RATE_21)
  const VATAmount_21 = priceDiscountTotal - baseTotalWithoutVAT_21

  const baseTotalWithoutVAT_5 = priceDiscountTotal / (1 + VAT_RATE_05)
  const VATAmount_5 = priceDiscountTotal - baseTotalWithoutVAT_5
  const discount = priceTotal - priceDiscountTotal

  const deliveryTotal = farmProducts.reduce(
    (total, farm) =>
      farm.selectedDelivery?.price
        ? total + farm.selectedDelivery?.price
        : total,
    0
  )
  // TODO: Clear cart after purch

  const isAllDeliveriesSelected = farmProducts.reduce(
    (total, farm) => (farm.selectedDelivery ? total + 1 : total),
    0
  )
  const getFarmSummaryItems = (farm: FarmProduct) => {
    const productDiscount =
      farm.farmProductsPrice - farm.farmProductsDiscountPrice

    return [
      {
        id: 'delivery.price',
        label: intl.formatMessage({ id: 'delivery.price' }),
        value: `${farm.farmProductsPrice.toFixed(2)} €`,
      },
      {
        id: 'general.discount',
        label: intl.formatMessage({ id: 'general.discount' }),
        value: `${productDiscount.toFixed(2)} €`,
      },
      {
        id: 'product.delivery',
        label: intl.formatMessage({ id: 'product.delivery' }),
        value: farm.selectedDelivery
          ? farm.selectedDelivery.price
            ? `${farm.selectedDelivery.price.toFixed(2)} €`
            : intl.formatMessage({ id: 'delivery.free_delivery' })
          : '',
      },
    ]
  }

  const summaryItems = [
    {
      id: 'cart_summary.price_no_discount',
      label: intl.formatMessage({ id: 'cart_summary.price_no_discount' }),
      value: `${priceTotal.toFixed(2)} €`,
    },
    {
      id: 'general.discount',
      label: intl.formatMessage({ id: 'general.discount' }),
      value: `${discount.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.sum_without_PVN',
      label: intl.formatMessage({ id: 'cart_summary.sum_without_PVN' }),
      value: `${baseTotalWithoutVAT_21.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.PVN_21',
      label: intl.formatMessage({ id: 'cart_summary.PVN_21' }),
      value: `${VATAmount_21.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.delivery_price',
      label: intl.formatMessage({ id: 'cart_summary.delivery_price' }),
      value: `${deliveryTotal.toFixed(2)} €`,
    },
  ]

  const summaryMobileItems = [
    {
      id: 'cart_summary.price_no_discount',
      label: intl.formatMessage({ id: 'cart_summary.price_no_discount' }),
      value: `${priceTotal.toFixed(2)} €`,
    },
    {
      id: 'general.discount',
      label: intl.formatMessage({ id: 'general.discount' }),
      value: `${discount.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.sum_without_PVN',
      label: intl.formatMessage({ id: 'cart_summary.sum_without_PVN' }),
      value: `${baseTotalWithoutVAT_21.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.sum_without_PVN',
      label: intl.formatMessage({ id: 'cart_summary.sum_without_PVN' }),
      value: `${baseTotalWithoutVAT_21.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.PVN_5',
      label: intl.formatMessage({ id: 'cart_summary.PVN_5' }),
      value: `${VATAmount_5.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.PVN_21',
      label: intl.formatMessage({ id: 'cart_summary.PVN_21' }),
      value: `${VATAmount_21.toFixed(2)} €`,
    },
    {
      id: 'cart_summary.delivery_price',
      label: intl.formatMessage({ id: 'cart_summary.delivery_price' }),
      value: `${deliveryTotal.toFixed(2)} €`,
    },
  ]

  const handleBuyProducts = () => {
    if (current >= 2) return
    if (!form) {
      setCurrent(current + 1)
      return
    }

    setCurrent(current + 1)
  }

  const allSummaryItems =
    windowWidth > 576 ? [...summaryItems] : [...summaryMobileItems]
  const cartSummaryClassName = windowWidth < 576 ? 'mobile-total' : 'total'

  return (
    <StyledCartSummary>
      <Form form={form} layout="vertical" onFinish={handleBuyProducts}>
        <div className={'cart-summary'}>
          <div className={'cart-summary-title'}>
            {intl.formatMessage({ id: 'cart_summary.summary_title' })}
          </div>
          <div className={'cart-order-summary-wrapper'}>
            {farmProducts.map((farm) => (
              <CartOrderSummary
                key={farm.farm_id}
                items={getFarmSummaryItems(farm)}
                title={farm.farm_name}
              />
            ))}

            <CartOrderSummary
              items={allSummaryItems}
              className={cartSummaryClassName}
            />

            {!action && (
              <>
                <div className={'cart-discount-coupon'}>
                  <Input
                    name={'discount_coupon'}
                    label={intl.formatMessage({
                      id: 'cart_summary.discount_coupon',
                    })}
                  />
                </div>
                <div className={'cart-discount-subscribe'}>
                  <Checkbox
                    name={'news_subscribe'}
                    label={intl.formatMessage({
                      id: 'cart_summary.news_subscribe',
                    })}
                  />
                </div>
              </>
            )}
            <div className={'cart-summary-submit-wrapper'}>
              <div className={'cart-summary-final-price'}>
                <div className={'cart-summary-final-price-title'}>
                  {intl.formatMessage({ id: 'cart_summary.totlal' })}
                </div>
                <div className={'cart-summary-final-price'}>
                  {priceTotal.toFixed(2)} €
                </div>
              </div>
              {!action ? (
                <Button
                  disabled={isAllDeliveriesSelected !== farmProducts.length}
                  type="primary"
                  htmlType="submit"
                  className={'cart-summary-submit-btn'}
                  label={intl.formatMessage({ id: 'cart_summary.buy' })}
                />
              ) : (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={'cart-summary-submit-btn-view'}
                    label={intl.formatMessage({
                      id: 'cart_summary.download_invoice',
                    })}
                  />
                  <p>
                    {intl.formatMessage({
                      id: 'process_purchase.saved_purchase_message',
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {isAllDeliveriesSelected === farmProducts.length &&
          action === undefined && (
            <div className="total-summary">
              <p className="total-summary-text">
                <span>
                  {intl.formatMessage({ id: 'cart_summary.amount_be_paid' })}
                </span>{' '}
                <b>{priceTotal.toFixed(2)} €</b>
              </p>
              <Button
                icon={<Icon name={'arrow-right'} />}
                type="primary"
                htmlType="submit"
                iconPosition="end"
                label={intl.formatMessage({ id: 'cart_summary.pay' })}
              />
            </div>
          )}
      </Form>
    </StyledCartSummary>
  )
}

export default CartSummary

const CartOrderSummary = ({
  items,
  title,
  className = '',
}: {
  items: {
    id: string
    label: string
    value: string
  }[]
  title?: string
  className?: string
}) => (
  <div className={`cart-farm-order-summary ${className}`}>
    {title && <div className="farm-title">{title}</div>}
    <div className={'farm-summary-wrapper'}>
      {items.map((item) => (
        <div className={'cart-summary-container'} key={item.id}>
          <p className={'cart-summary-item'}>{item.label}</p>
          <p className={'cart-summary-item price'}>{item.value}</p>
        </div>
      ))}
    </div>
  </div>
)
