import styled from 'styled-components'

export const StyledMobileHeader = styled.div`
  display: none;
  z-index: 1000;
  width: 100%;

  .filter-open-btn {
    display: flex;
    height: auto;
    padding: 11px 14px !important;
    border-radius: 5px;
  }

  .filter-open-btn:hover {
    background: ${({ theme }) => theme.brand01} !important;
    border-color: ${({ theme }) => theme.colorBorder} !important;
    color: ${({ theme }) => theme.white} !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  }

  @media only screen and (max-width: 414px) {
    display: flex;

    .mobile-header-container {
      .mobile-image-container {
        display: flex;
        width: 100%;
        height: 60px;

        a img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.filterOpen {
        .buy-latvia-food {
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .title-section {
            width: 300px;
            justify-content: center;
            padding: 0 0 0 0;
          }

          .counter-section {
            position: absolute;
            padding: 0 0 0 0;
            flex-direction: row;
            gap: 10px;
            top: 154px;
            background-color: ${({ theme }) => theme.filterSelectBg};
            max-width: 120px;
            border-radius: 10px;
            padding: 2px 5px 2px 5px;
            color: white;

            h3 {
              font-size: 16px;
              b {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .buy-latvia-food {
      display: flex;
      height: 107px;
      max-width: 414px;
      background-color: ${({ theme }) => theme.white};
      justify-content: space-between;

      .title-section {
        height: 110px;
        width: 175px;
        display: flex;
        align-items: center;
        padding: 25px 0px 24px 18px;

        h3 {
          font-family: ${({ theme }) => theme.fontText};
          margin: 0;
          font-weight: 400;
          font-size: 24px;
        }
      }

      .counter-section {
        max-height: 34px;
        max-width: 61px;
        display: flex;
        align-items: end;
        flex-direction: column;
        padding: 47px 21px 26px 0;

        h3 {
          font-family: ${({ theme }) => theme.fontText};
          margin: 0;
          font-weight: 400;
          font-size: 14px;

          b {
            font-size: 18px;
          }
        }
      }
    }

    .search-section {
      width: 100%;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      padding-right: 10px;
      background-color: ${({ theme }) => theme.inputGrey};
    }

    .fixed-search-section {
      position: fixed !important;
      top: 0px !important;
      z-index: 1;
    }

  }
`
