import { StyledMobileMenu } from './style'
import { useIntl } from 'react-intl'
import { Button, Icon } from 'ui'
import { useNavigate } from 'react-router-dom'
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer'
import { useState } from 'react'
import { Menu } from 'antd'

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()

  const items = [
    {
      key: 'main',
      label: intl.formatMessage({ id: 'navigation.main' }),
    },
    {
      key: 'current_events',
      label: intl.formatMessage({ id: 'navigation.current_events' }),
    },
    {
      key: 'contests',
      label: intl.formatMessage({ id: 'navigation.contests' }),
    },
    {
      key: 'recipes',
      label: intl.formatMessage({ id: 'navigation.recipes' }),
    },
    {
      key: 'events',
      label: intl.formatMessage({ id: 'navigation.events' }),
    },
    {
      key: 'contacts',
      label: intl.formatMessage({ id: 'navigation.contacts' }),
    },
  ]

  const openCloseDrawer = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <StyledMobileMenu>
        <div className={'mobile-menu-container'}>
          <div className={'mobile-menu-content'}>
            <div className={'mobile-menu-item'}>
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.profile' })}
                icon={<Icon name={'profile-icon'} />}
              />
            </div>
            <div className={'mobile-menu-item'}>
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.e-shop' })}
                icon={<Icon name="e-shop-icon" />}
              />
            </div>
          </div>
          <div className={'mobile-menu-filter'}>
            <Button
              type="text"
              icon={
                isMenuOpen ? (
                  <Icon
                    name="mobile-filter-close-button-icon"
                    type={'filter-icon'}
                  />
                ) : (
                  <Icon name="mobile-menu-open-icon" type={'filter-icon'} />
                )
              }
              onClick={() => openCloseDrawer()}
            />
          </div>
          <div className={'mobile-menu-content'}>
            <div className={'mobile-menu-item'}>
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.producers' })}
                icon={<Icon name="producers-icon" />}
                onClick={() => navigate(`farms/all`)}
              />
            </div>
            <div className={'mobile-menu-item'}>
              <Button
                type="text"
                label={intl.formatMessage({ id: 'general.basked' })}
                icon={<Icon name="cart-icon" />}
              />
            </div>
          </div>
        </div>
        
        <MobileMenuDrawer
          onClose={openCloseDrawer}
          open={isMenuOpen}
          closable={false}
          className='mobile-menu'
        >

          <Menu
            mode="vertical"
            defaultSelectedKeys={['2']}
            items={items}
          />
        </MobileMenuDrawer>
      </StyledMobileMenu>
    </>
  )
}

export default MobileMenu
