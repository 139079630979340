import { StyledImagePlaceholder } from './style'
import { ImageProps } from '../../../types/BannerData'
import Dragger from 'antd/lib/upload/Dragger'
import { FormInstance } from 'antd'
import { FallbackImage } from '../fallBack/FallBackImage'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import { options } from '../../../config/config'
import { BeforeUpload } from '../../../utils/imageHelpers'
import { useEffect, useState } from 'react'
import { Button } from '../../Button'
import { CloseOutlined } from '@ant-design/icons'

interface ImagePlaceholderProps {
  images: ImageProps[]
  type?:
    | 'banner_1'
    | 'banner_2'
    | 'banner_3'
    | 'banner_4'
    | 'ProductCard'
    | 'CategoryCard'
  form: FormInstance
  handleChange: (info: any) => void
  imagesData: any
  setImagesData: any
  setRemovedImageIds?: any
}

export const ImagePlaceholder = ({
  images,
  type,
  form,
  handleChange,
  imagesData,
  setImagesData,
  setRemovedImageIds,
}: ImagePlaceholderProps) => {
  const [fileList, setFileList] = useState([])
  const [urlToList, setUrlToList] = useState<{ [key: number]: string }>({})
  const intl = useIntl()

  useEffect(() => {
    const maxImages = options.images.maxImages
    const initialFileList: any = Array.from(
      { length: maxImages },
      (_, index) => {
        const image = images[index]
        if (image) {
          return {
            uid: image.id || `-${index}`,
            id: image.id,
            name: image.name || `Image ${index + 1}`,
            status: 'done',
            url: image.url_original,
            thumbUrl: image.url_thumbnail,
            originFileObj: null,
            url_to: image.url_to,
          }
        }
        return null
      }
    )

    setFileList(initialFileList)

    const initialUrlToList = images.reduce((acc: any, image, index) => {
      acc[index] = image.url_to || ''
      return acc
    }, {})
    setUrlToList(initialUrlToList)

    setImagesData(initialFileList.filter((file: any) => file !== null))
  }, [images])

  const handleUpload = (info: any, index: number) => {
    const file = info.file

    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file)
    }

    setFileList((prevFileList) => {
      const newFileList: any = [...prevFileList]
      const existingFile = newFileList[index]

      if (existingFile && existingFile.id && existingFile.id !== file.id) {
        setRemovedImageIds((prev: any) => [...prev, existingFile.id])
      }

      newFileList[index] = {
        ...file,
        id: file.id || file.uid,
        originFileObj: file.originFileObj || file,
        url: file.url || file.preview || '',
      }
      return newFileList
    })

    setImagesData((prevImagesData: any[]) => {
      const newImagesData = [...prevImagesData]
      newImagesData[index] = {
        ...file,
        id: file.id || file.uid,
        url_to: urlToList[index] || '',
        originFileObj: file.originFileObj || file,
        url: file.url || file.preview || '',
      }
      return newImagesData
    })
  }

  const handleUrlToChange = (index: number, value: any) => {
    setUrlToList((prevUrlToList) => ({
      ...prevUrlToList,
      [index]: value,
    }))

    setImagesData((prevImagesData: any) => {
      const newImagesData = [...prevImagesData]
      if (newImagesData[index]) {
        newImagesData[index] = {
          ...newImagesData[index],
          url_to: value,
          id: newImagesData[index].id || '',
        }
      } else {
        newImagesData[index] = { url_to: value }
      }
      return newImagesData
    })
  }

  const handleRemove = (index: number) => {
    const imageToRemove = imagesData[index]
    if (imageToRemove && !imageToRemove.originFileObj) {
      setRemovedImageIds((prev: any) => [...prev, imageToRemove.id])
    }

    setFileList((prevFileList) => {
      const newFileList: any = [...prevFileList]
      newFileList[index] = null
      return newFileList
    })

    setImagesData((prevImagesData: any[]) => {
      const newImagesData = [...prevImagesData]
      newImagesData[index] = null
      return newImagesData
    })

    setUrlToList((prevUrlToList) => {
      const { [index]: _, ...rest } = prevUrlToList
      return rest
    })
  }

  return (
    <StyledImagePlaceholder>
      <div className={'container-banner'}>
        {fileList.map((file: any, index) => (
          <div className={'img-banner'} key={index + 1}>
            <div className={'label'}>{index + 1}</div>
            <div className={'draggable'}>
              <Dragger
                key={index}
                name={`images[${index}]`}
                multiple={false}
                beforeUpload={BeforeUpload}
                fileList={file ? [file] : []}
                onChange={(info) => handleUpload(info, index)}
                showUploadList={{ showRemoveIcon: false }}
              >
                <FallbackImage
                  src={file?.url || file?.preview || ''}
                  preview={false}
                  type={'notFound'}
                />
              </Dragger>
            </div>
            <div className={'remove-wrap'}>
              {file && (
                <Button
                  className={'remove-btn'}
                  icon={<CloseOutlined />}
                  onClick={() => handleRemove(index)}
                  type="text"
                  danger
                />
              )}
            </div>
            <div className={'inputs'}>
              <Input
                name={['images', index, 'url_to']}
                value={file?.url_to || ''}
                placeholder={file?.url_to}
                label={intl.formatMessage({ id: 'banners.url' })}
                onChange={(e) => handleUrlToChange(index, e.target.value)}
              />
            </div>
          </div>
        ))}
      </div>
    </StyledImagePlaceholder>
  )
}
