import styled from 'styled-components'

export const StyledCartWarning = styled.div`
  .cart-warning-container {
    margin-top: 14px;
    font-size: 16px;
    font-weight: 300px;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.cartWarningBackground};
    padding: 18px 18px 18px 18px;
    border-radius: 5px;
    font-family: ${(props) => props.theme.fontText};

    @media only screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }
`
