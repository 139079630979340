import { demoAssets } from "../Demo/demoData";
import { IAssets } from "./interfaces";

export const AssetManager = (() => {
    let mapAssets: IAssets = demoAssets;

    const getAssets = (): IAssets => mapAssets;

    /**
     * set map assets, pass object with icon urls
     */
    const setAssets = (assets: IAssets) => (mapAssets = { ...assets });

    return {
        setAssets,
        getAssets
    };
})();
