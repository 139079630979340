import { Feature, View, Map, MapBrowserEvent } from "ol";
import { OSM, Cluster as ClusterSource } from "ol/source";
import { SimpleGeometry } from "ol/geom";
import { Tile as TileLayer, Vector as VectorLayer, Layer } from "ol/layer";
import { config } from "./config";
import { getClusterSymbol, getSymbol } from "./symbols";
import { defaults as defaultControls } from "ol/control";
import { getMap } from "./MapComponent";
import { extend, Extent, buffer } from "ol/extent";
import { get as getProjection } from "ol/proj";
import { getWidth, getTopLeft } from "ol/extent";
import TileGrid from "ol/tilegrid/TileGrid";
import { IStartPosition } from "./interfaces";
import { getAssets } from "../novadugarsa";

const featureLayerId = config.defaults.featureLayerId;

const getBuffer = (feature: Feature) => buffer(feature.getGeometry().getExtent(), 100);

// Define your tile grid for EPSG:4326
const projection = getProjection("EPSG:4326");
const extent = projection.getExtent();
const tileSize = 256;
const resolutions = Array.from({ length: 22 }, (_, z) => getWidth(extent) / tileSize / Math.pow(2, z));

const tileGrid = new TileGrid({
    extent: extent,
    origin: getTopLeft(extent),
    resolutions: resolutions,
    tileSize: tileSize
});

export const createMap = (target: HTMLDivElement, clusterSourceRef, startPosition: IStartPosition): Map => {
    const osmLayer = new TileLayer({ source: new OSM() });

    const clusterLayer = new VectorLayer({
        properties: { id: featureLayerId },
        source: new ClusterSource({
            distance: 80,
            source: clusterSourceRef
        }),
        style: (clusterFeature) => {
            const clusterFeatures = clusterFeature.get("features");
            return clusterFeatures.length > 1 ? getClusterSymbol(clusterFeatures.length) : getSymbol();
        }
    });
    // @ts-ignore
    window.clusterLayer = clusterLayer;

    const { zoomInLabel, zoomOutLabel } = getZoomButtonIcons();

    return new Map({
        layers: [osmLayer, clusterLayer],
        target,
        view: new View({
            projection: "EPSG:3857",
            center: [startPosition.x, startPosition.y],
            zoom: startPosition.zoom | 7,
            constrainResolution: true
        }),
        controls: defaultControls({
            rotate: false,
            zoomOptions: { className: "zoom-container", zoomInLabel, zoomOutLabel }
        })
    });
};

export const onPointerMove = (evt: MapBrowserEvent<UIEvent>, map: Map, target: HTMLDivElement): void => {
    if (evt.dragging) {
        return;
    }

    const hasFeatureAtPixel = map.hasFeatureAtPixel(evt.pixel, {
        layerFilter: (layer: Layer) => layer.get("id") === featureLayerId
    });

    target.style.cursor = hasFeatureAtPixel ? "pointer" : null;
};

export const getLayerById = (id: string): VectorLayer<Feature> =>
    getMap() &&
    (getMap()
        .getLayers()
        .getArray()
        .find((layer: Layer) => layer.get("id") === id) as VectorLayer<Feature>);

export const forEachFeatureOfLayer = (layerId: string, func: CallableFunction): void => {
    const layer = getLayerById(layerId);
    layer && layer.getSource().forEachFeature((f: Feature) => func(f));
};

export const toggleMapControls = (isOn: boolean): void => {
    const compass = document.getElementById("compass-button");
    compass && (compass.style.display = isOn ? "none" : "block");
    const home = document.getElementById("home-button");
    home && (home.style.display = isOn ? "none" : "block");
};

export const zoomToFeature = (feature: Feature, callback?: (arg0: boolean) => void): void => {
    if (!feature) {
        return;
    }
    const zoomToLevel = config.defaults.zoomToLevel;
    const view = getMap().getView();
    const geometry = feature.getGeometry() as SimpleGeometry;
    const currentZoom = view.getZoom();
    const maxZoom = currentZoom > zoomToLevel ? currentZoom : zoomToLevel;
    document.dispatchEvent(new Event("stopTracking"));
    view.fit(geometry, { duration: 500, maxZoom, padding: [0, 0, 0, (view as any).paddingLeft], callback });
};

export const zoomToExtent = (extent: Extent, callback?: (arg0: boolean) => void) => {
    document.dispatchEvent(new Event("stopTracking"));
    const view = getMap().getView();
    view.fit(extent, { duration: 500, padding: [50, 50, 50, 50 + (view as any).paddingLeft], callback });
};

export const handleClusterClick = (clusterFeature: Feature[], map: Map) => {
    const extent = clusterFeature.reduce<Extent>((result, feature) => {
        return result === null ? getBuffer(feature) : extend(result, getBuffer(feature));
    }, null);
    const view = map.getView();
    view.fit(extent, { padding: [0, 0, 0, (view as any).paddingLeft], duration: 500 });
};

const getZoomButtonIcons = () => {
    const { zoomIn, zoomOut } = getAssets();
    const zoomInLabel = document.createElement("img");
    zoomInLabel.src = zoomIn;
    const zoomOutLabel = document.createElement("img");
    zoomOutLabel.src = zoomOut;

    return { zoomInLabel, zoomOutLabel };
};
