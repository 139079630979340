import DefaultLayout from '../../components/DefaultLayout'
import { Form } from '../../../ui/Form/Form'
import { DefaultThirdHeader } from '../../components/DefaultLayout/DefaultThirdHeader'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { Button, Icon, Spinner } from '../../../ui'
import { Pagination } from 'antd'
import { Card } from '../../../ui/Card'
import { ProductData } from '../../../types/ProductData'
import { useCallback, useEffect, useState } from 'react'
import { ProductsTopFilter } from '../../../ui/Form/filters/ProductsTopFilter'
import { options } from '../../../config/config'
import { StyledAllDiscountProducts } from './style'
import { Filter } from 'types/Filter'
import { FilterOptions } from 'types/FilterOptions'
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer'
import MobileFilter from 'public/components/MobileLayout/MobileFilter/MobileFilter'
import { StyledMobileFilterFooter } from 'public/components/MobileLayout/MobileMenu/MobileFilterFooter/style'
import { useBannerState, useCategoryState } from 'public/context'
import PopularCategories from 'public/components/DefaultLayout/PopularCategories'
import Banner from 'public/components/DefaultLayout/Banner'
import MobileProductCategoriesCarousel from 'public/components/MobileLayout/MobileProductCategoriesCarousel/MobileProductCategoriesCarousel'
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter'
import { useFilterDispatch } from 'public/context/FilterContext'
import { useNavigate } from 'react-router-dom'
import { FallbackImage } from '../../../ui/UploadImage/fallBack/FallBackImage'

export const AllDiscountProducts = () => {
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = localStorage.getItem('discountProductsFilter')
    return savedFilter ? JSON.parse(savedFilter) : {}
  })
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const savedPage = localStorage.getItem('savedDiscountProductsPage')
    return savedPage ? JSON.parse(savedPage) : 1
  })
  const [pageSize, setPageSize] = useState<number>(() => {
    const savedPageSize = localStorage.getItem('savedDiscountProductsSize')
    return savedPageSize ? JSON.parse(savedPageSize) : 20
  })
  const [total, setTotal] = useState<number>(0)
  const [products, setProducts] = useState([])
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({})
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { banners } = useBannerState()
  const { popularCategory } = useCategoryState()
  const navigate = useNavigate()

  const { isLoading, appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/products',
      data: {
        page: currentPage,
        page_size: pageSize,
        options: {
          view: 'discounts',
        },
        ...filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setTotal(response.products.total)
      setProducts(response.products.data)
      setFilterOptions(response.filters)
    },
  })

  const dispatchFilter = useFilterDispatch()

  const openCloseDrawer = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen])

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: true, openCloseDrawer },
    })
  }, [dispatchFilter, openCloseDrawer])

  const handleSubmitFilter = () => {
    localStorage.setItem('discountProductsFilter', JSON.stringify(filter))
    openCloseDrawer()
    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
      options: {
        view: 'discounts',
      },
    })
  }

  useEffect(() => {
    if (isFilterOpen) return

    localStorage.setItem('discountProductsFilter', JSON.stringify(filter))
    localStorage.setItem('savedDiscountProductsPage', currentPage.toString())
    localStorage.setItem('savedDiscountProductsSize', pageSize.toString())

    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
      options: {
        view: 'discounts',
      },
    })
  }, [filter, currentPage, pageSize])

  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter)
    setCurrentPage(1)
  }

  const handleOnClick = (product: ProductData) => {
    navigate(`/product/${product.id}`)
  }

  const intl = useIntl()

  const pageSizeOptions = options.filters.pageSizeOptions

  const breadcrumbs = [
    {
      route: '/',
      name: intl.formatMessage({ id: 'general.start' }),
    },
    {
      route: 'discounted',
      name: intl.formatMessage({ id: 'general.sales' }),
    },
  ]

  const banner_2_data = banners.data.find(
    (banner) => banner.type === 'banner_2'
  )
  const banner_3_data = banners.data.find(
    (banner) => banner.type === 'banner_3'
  )

  const drawer = () => {
    return (
      <MobileMenuDrawer
        title={intl.formatMessage({ id: 'farms.filter' })}
        onClose={openCloseDrawer}
        open={isFilterOpen}
        className="mobile-filter"
        footer={
          <StyledMobileFilterFooter>
            <Button
              label={intl.formatMessage({ id: 'general.select' })}
              icon={<Icon name={'mobile-filter-submit-arrow-icon'} />}
              onClick={handleSubmitFilter}
            />
          </StyledMobileFilterFooter>
        }
      >
        <MobileFilter
          setFilter={setFilter}
          filter={filter}
          filterOptions={filterOptions}
        />
      </MobileMenuDrawer>
    )
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledAllDiscountProducts>
          <DefaultThirdHeader
            label={intl.formatMessage({ id: 'form.discount_page_title' })}
            filters={
              <ProductsTopFilter
                total={total}
                per_page={pageSize}
                setFilter={(newFilter) =>
                  handleFilterChange({ ...filter, ...newFilter })
                }
                page_size_options={pageSizeOptions}
                setPageSize={setPageSize}
                unit={'products'}
              />
            }
            breadcrumb={breadcrumbs}
          />
          <SearchAndFilter rootClassName="laptop-search-section-box" />
          <div className={'products-filters-container'}>
            <Form
              setFilter={handleFilterChange}
              filterOptions={filterOptions}
              filter={filter}
            />
            <Spinner spinning={isLoading}>
              <div className={'products-wrapper'}>
                <div className={'products'}>
                  {products.map((product: ProductData, index) => (
                    <Card
                      key={`crd-${index}`}
                      product={product}
                      imageUrl={product.image}
                      hoverable
                    />
                  ))}
                </div>
                <div className={'pagination'}>
                  <Pagination
                    total={total}
                    showSizeChanger={false}
                    current={currentPage}
                    defaultPageSize={20}
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPageSize(pageSize)
                      setCurrentPage(page)
                    }}
                  />
                </div>
              </div>
            </Spinner>
          </div>
          <MobileProductCategoriesCarousel popularCategory={popularCategory} />
          <PopularCategories categories={popularCategory} />
          {banner_2_data && banner_3_data && (
            <Banner
              banners={[banner_2_data, banner_3_data]}
              className={'desktop-banner'}
            />
          )}
        </StyledAllDiscountProducts>
        {drawer()}
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}
