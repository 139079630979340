import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import TestPage from 'public/pages/TestPage'
import { AllProducts } from '../../pages/AllProducts'
import MapPage from 'public/pages/MapPage'
import ProductPage from '../../pages/ProductPage'
import { Login } from '../../pages/Auth/Login'
import { Register } from '../../pages/Auth/Register'
import { AllDiscountProducts } from '../../pages/AllDiscountProducts'
import { AllFarms } from '../../pages/AllFarms'
import { IconsPage } from '../../pages/IconsPage'
import FarmPage from '../../pages/FarmPage'
import { AllNewProducts } from 'public/pages/AllNewProducts'
import { MyProfilePage } from '../../pages/MyProfilePage'
import { CartPage } from 'public/pages/CartPage'

const Router = () => {
  const renderRoutes = () => {
    return (
      <>
        <Route path="/profile/:tab" element={<MyProfilePage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/products/all" element={<AllProducts />} />
        <Route path="/products/all/new" element={<AllNewProducts />} />
        <Route
          path="/products/all/discounted"
          element={<AllDiscountProducts />}
        />
        <Route path="/farms/all" element={<AllFarms />} />
        <Route path="/farm/:id" element={<FarmPage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="/" element={<TestPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path="/icons" element={<IconsPage />} />
        <Route path="/cart" element={<CartPage />} />
      </>
    )
  }

  return <Routes>{renderRoutes()}</Routes>
}

export default Router
