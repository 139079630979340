import * as React from "react";
import { createContext, FC, ReactNode, useContext } from "react";

export interface IDemoContext {
    onFilterItemToggle: (
        filterName: string,
        value: string | number | boolean,
        event: any,
        onNearbyChange?: CallableFunction
    ) => void;
}

type DemoContextProps = {
    children?: ReactNode;
    props?: IDemoContext;
};

const DemoContext = createContext<IDemoContext>(null);

export const useDemoContext = (): IDemoContext => useContext(DemoContext);

export const DemoContextProvider: FC<DemoContextProps> = (props: React.PropsWithChildren<IDemoContext>) => {
    const onFilterItemToggle = (
        filterName: string,
        value: string | number | boolean,
        event: any,
        onNearbyChange?: CallableFunction
    ) => {
        console.log("onsetFilter");
    };

    const values = {
        onFilterItemToggle
    };

    return <DemoContext.Provider value={values}>{props.children}</DemoContext.Provider>;
};
