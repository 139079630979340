import { StyledIcon } from './style'
import { CSSProperties } from 'react'

export interface IconProps {
  name?: string
  type?: 'small' | 'medium' | 'large' | 'card' | 'filter-icon' | 'avarage'
  svg?: boolean
  style?: CSSProperties
  onClick?: any
}

export const Icon = ({
  name,
  type = 'medium',
  svg = true,
  style,
  onClick,
}: IconProps) => {
  return (
    <StyledIcon type={type} onClick={onClick}>
      <img src={svg ? `/svgs/${name}.svg` : `/${name}`} style={style} />
    </StyledIcon>
  )
}
