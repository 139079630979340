import styled from 'styled-components'
export const ProfileLayout = styled('div')`
  background-color: ${(props) => props.theme.white};
  padding-left: 13%;
  padding-right: 13%;
  min-width: 50%;

  .log-out {
    font-family: Albert Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .ant-tabs-tab-btn {
    font-family: Albert Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`

export const OrderLayout = styled('div')`
  width: 100%;
  min-height: 25em;

  .title {
    font-family: Albert Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .order-download {
    margin-left: 5px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-family: Roboto;
  }

  .view-order {
    font-family: Roboto;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`
