import * as React from "react";
import { FC } from "react";
import { Feature } from "ol";
import { DemoDataInput, DemoPaddingControl } from "./DemoControls";
import { DemoContextProvider } from "./DemoContext";
import { DemoFeatures } from "./DemoFeatures";
import { IFeature, IPadding } from "../Map/interfaces";

export interface IDemoUIProps {
    /**
     * identified object attributes, received on map object click
     */
    identifiedObject: Feature;
    /**
     * callback, when ZoomTo button clicked, to zoom to particular object
     * @param feature is any Feature to zoom to
     */
    onZoomToFeature: (feature: Feature) => void;
    /**
     * callback, to change map center, by adding padding
     */
    onChangePadding?: (padding: IPadding) => void;
    /**
     * toggle map
     */
    onToggleMap?: () => void;
    /**
     * callback, to change map center, by adding padding
     */
    onChangeData?: (data: IFeature[]) => void;
}

export const DemoUI: FC<IDemoUIProps> = (props: IDemoUIProps) => {
    return (
        <DemoContextProvider>
            <DemoPaddingControl
                onChangePadding={props.onChangePadding}
                onToggleMap={props.onToggleMap}
            ></DemoPaddingControl>

            <DemoFeatures identifiedObject={props.identifiedObject} onZoomToFeature={props.onZoomToFeature} />

            <DemoDataInput onChangeData={props.onChangeData} />
        </DemoContextProvider>
    );
};
