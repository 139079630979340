import { ConfigProvider } from 'antd'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import translations from './languages/lv.json'
import { adminToken, token } from './config/token'
import DefaultLayout from './public/components/DefaultLayout'
import AdminDefaultLayout from './admin/components/DefaultLayout'
import { ThemeProvider } from 'styled-components'
import 'leaflet/dist/leaflet.css'
import { adminComponents, publicComponents } from './config/antdComponents'
import { GlobalStyles } from './style'
import { Login } from './public/pages/Auth/Login'
import { Register } from './public/pages/Auth/Register'
import ProtectedRoute from './public/components/DefaultLayout/ProtectedRoutes/ProtectedRoute'
import { AuthProvider } from './hooks/useAuth'

export default function App() {
  const pathname = window.location.pathname

  return (
    <ConfigProvider
      theme={{
        token: pathname.includes('/admin') ? adminToken : token,
        components: pathname.includes('/admin')
          ? adminComponents
          : publicComponents,
      }}
    >
      <ThemeProvider
        theme={pathname.includes('/admin/') ? { ...adminToken } : { ...token }}
      >
        <IntlProvider locale="lv" messages={translations}>
          <GlobalStyles />
          <div id="app-root">
            <BrowserRouter>
              <AuthProvider>
                <Routes>
                  <Route
                    path="/admin/*"
                    element={
                      <ProtectedRoute
                        requiresAdmin
                        children={<AdminDefaultLayout />}
                      ></ProtectedRoute>
                    }
                  />
                  <Route path="/*" element={<DefaultLayout />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                </Routes>
              </AuthProvider>
            </BrowserRouter>
          </div>
        </IntlProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}
